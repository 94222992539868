import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { DashboardSliceState } from "./DashboardSliceState";

export const initialState: DashboardSliceState = {
  displaySubscribedApps: JSON.parse(sessionStorage.getItem("displaySubscribedApps") as string) ?? true,
  displayUnsubscribedApps: JSON.parse(sessionStorage.getItem("displayUnsubscribedApps") as string) ?? true
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    toggleSubscribed: (state) => {
      const toggle = !state.displaySubscribedApps;
      sessionStorage.setItem("displaySubscribedApps", toggle.toString());
      state.displaySubscribedApps = toggle;
    },
    toggleUnsubscribed: (state) => {
      const toggle = !state.displayUnsubscribedApps;
      sessionStorage.setItem("displayUnsubscribedApps", toggle.toString());
      state.displayUnsubscribedApps = toggle;
    }
  }
});

export const selectSubscribedApps = (state: RootState) => state.dashboard.displaySubscribedApps;
export const selectUnsubscribedApps = (state: RootState) => state.dashboard.displayUnsubscribedApps;

export const {
  toggleSubscribed,
  toggleUnsubscribed
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
