import * as Yup from "yup";

import { emailRegex } from "../../../../../utils/validateEmail/validateEmail";

export const businessFormValidation = Yup.object().shape({
  companyName: Yup.string().required("Business Name is required."),
  dba: Yup.string().required("DBA is required."),
  displayName: Yup.string().required("Display Name is required."),
  primaryContactPhoneNumber: Yup.string().length(10, "Phone number must be 10 digits").required("Phone Number is required."),
  primaryContactBusinessContact: Yup.string().required("Business Contact is required."),
  primaryContactEmail: Yup.string().matches(emailRegex, "Contact Email is invalid.")
    .required("Contact Email is required."),
  billingAddress: Yup.string().required("Billing Address is required."),
  billingCity: Yup.string().required("Billing City is required."),
  billingState: Yup.string().required("Billing State is required."),
  billingCountry: Yup.string().required("Billing Country is required."),
  billingPostalCode: Yup.string().required("Billing Zip Code is required.").length(5, "Zip Code must be 5 digits"),
  mailingSameAsBilling: Yup.boolean(),
  mailingAddress: Yup.string().when("mailingSameAsBilling", ([mailingSameAsBilling], schema) => {
    if (!mailingSameAsBilling) {
      return schema.required("Mailing Address is required.");
    }
    return schema;
  }),
  mailingCity: Yup.string().when("mailingSameAsBilling", ([mailingSameAsBilling], schema) => {
    if (!mailingSameAsBilling) {
      return schema.required("Mailing City is required.");
    }
    return schema;
  }),
  mailingState: Yup.string().when("mailingSameAsBilling", ([mailingSameAsBilling], schema) => {
    if (!mailingSameAsBilling) {
      return schema.required("Mailing State is required.");
    }
    return schema;
  }),
  mailingCountry: Yup.string().when("mailingSameAsBilling", ([mailingSameAsBilling], schema) => {
    if (!mailingSameAsBilling) {
      return schema.required("Mailing Country is required.");
    }
    return schema;
  }),
  mailingPostalCode: Yup.string().when("mailingSameAsBilling", ([mailingSameAsBilling], schema) => {
    if (!mailingSameAsBilling) {
      return schema.required("Mailing Zip Code is required.").length(5, "Zip Code must be 5 digits");
    }
    return schema;
  })
});
