import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";

import { Role } from "src/models/Role";
import { deleteRole } from "src/store/thunks/role/delete/deleteRole";
import { getCustomRoles } from "src/store/thunks/roles/getCustomRoles";

import { useAppDispatch } from "../../../store/hooks";

import useStyles from "./DeleteRoleDialog.styles";

interface DeleteRoleDialogProps {
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  role?: Role;
  paginationModel: GridPaginationModel;
  search: string;
}

const DeleteRoleDialog: React.FC<DeleteRoleDialogProps> = ({ open, setOpen, role, paginationModel, search }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const onConfirmDelete = () => {
    dispatch(deleteRole(role)).then(resp => {
      if (!resp.type.includes("rejected")) {
        setOpen(false);
        dispatch(getCustomRoles({
          page: 1,
          pageSize: paginationModel.pageSize,
          search
        }));
      }
    }); ;
  };

  return (
    <Dialog open={open} className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}>
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.dialogTitle} ><Typography variant={"h5"} style={{ fontWeight: 500 }}>Confirm to Remove Role</Typography></DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant={"body2"}>Are you sure you want to remove {role?.name}?</Typography>
        <Typography variant={"body3"} className={classes.italicText}>Users with this role will lose all access associated to it</Typography>
      </DialogContent>
      <DialogActions className={classes.diablogActions}>
        <Button
          onClick={() => setOpen(false)}
          variant={"text"}
          data-testid={"cancel-button"}
          className={classes.cancelButton}
        >
          <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          data-testid={"delete-role-button"}
          className={classes.confirmButton}
          onClick={onConfirmDelete}
        >
          <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>Confirm</Typography>
        </Button>
      </DialogActions>
    </Dialog>);
};

export default DeleteRoleDialog;
