import { ChangeEvent, useState } from "react";

import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";

export const usePagination = (tableId: string) => {
  const [page, setPage] = useState(Number(sessionStorage.getItem(`${tableId}_pageNumber`)) || 0);
  const [rowsPerPage, setRowsPerPage] = useState(Number(localStorage.getItem(`${tableId}_rowPerPage`)) || 25);
  const [search, setSearch] = useState("");
  const [sortOptions, setSortOptions] = useState<GridSortModel>([{
    field: JSON.parse(localStorage.getItem(`${tableId}_sortModel`) || "\"\"")[0]?.field || "",
    sort: JSON.parse(localStorage.getItem(`${tableId}_sortModel`) || "\"\"")[0]?.sort || ""
  }]);
  const [paginationModel, setPaginationModel] = useState({
    page: JSON.parse(localStorage.getItem(`${tableId}_paginationModel`) || "\"\"")?.page || 0,
    pageSize: JSON.parse(localStorage.getItem(`${tableId}_paginationModel`) || "\"\"")?.pageSize || 25
  });

  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage);
    sessionStorage.setItem(`${tableId}_pageNumber`, newPage.toString());
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    localStorage.setItem(`${tableId}_rowPerPage`, newRowsPerPage.toString());
    handleChangePage(0);
  };

  const handleChangePaginationModel = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);
    localStorage.setItem(`${tableId}_paginationModel`, JSON.stringify(paginationModel));
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortOptions(sortModel);
    localStorage.setItem(`${tableId}_sortModel`, JSON.stringify(sortModel));
  };

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    search,
    setSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationModel,
    setPaginationModel,
    handleChangePaginationModel,
    handleSortModelChange,
    sortOptions
  };
};
