import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Role } from "src/models/Role";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getRoles } from "src/store/thunks/roles/getRoles";

import { RolesSliceState } from "./RolesSliceState";

export const initialState: RolesSliceState = {
  loading: false,
  error: "",
  roles: {
    data: [],
    meta: {} as APIListMetaData,
    links: {
      first: null,
      last: null,
      prev: null,
      next: null
    }
  }
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action: PayloadAction<APIListResponse<Role[]>>) => {
        state.roles = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.roles.data = action.payload.data.filter(role => role.is_deleted !== 1);
        state.roles.meta = action.payload.meta;
        state.loading = false;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectRoles = (state: RootState) => state.roles?.roles;
export const selectLoading = (state: RootState) => state.roles.loading;

export const {
  setRoles
} = rolesSlice.actions;

export default rolesSlice.reducer;
