import { createAsyncThunk } from "@reduxjs/toolkit";

import { noAuthInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { ConfirmUserPayload } from "./confirmUserPayload";

export const confirmUser = createAsyncThunk("confirmUser", async(token: string | null, thunkAPI) => {
  const { dispatch, getState, rejectWithValue } = thunkAPI;
  const state = getState() as RootState;
  const confirmUser = state.confirmUser;

  const payload: ConfirmUserPayload = {
    first_name: confirmUser.user?.first_name || "",
    last_name: confirmUser.user?.last_name || "",
    user_name: confirmUser.user?.username || "",
    email: confirmUser.user?.email || "",
    password: confirmUser.password || "",
    password_confirmation: confirmUser.password_confirmation || "",
    registration_token: token || ""
  };

  return axios
    .post("/api/v1/users/finish-registration", payload)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      if (err.response.status === 422 || err.response.status === 500) {
        const errorMessage = err.response.status === 422 ? format422(err.response.data.detail) : err.response.data.detail;

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      }

      const errorMessage = "There was an error confirming the user";

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
