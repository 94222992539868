import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

type meta = {
  page: number,
  pageSize: number,
  search: string
}

export const getOperators = createAsyncThunk(
  "getOperators",
  async(meta: meta, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const pageSize = meta.pageSize;
    const page = meta.page;
    const filterName = meta.search && ("&filter[business_name]=" + encodeURIComponent(`${meta.search}`) + "&orFilter[dba_name]=" + encodeURIComponent(`${meta.search}`) + "&orFilter[display_name]=" + encodeURIComponent(`${meta.search}`));

    return axios.get(`/api/v1/operators?pageSize=${pageSize}&page=${page}${filterName}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Operators";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
