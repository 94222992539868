import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import useAutoLogout from "src/components/hooks/useAutoLogout";
import { useAppDispatch as useDispatch } from "src/store/hooks";
import { selectIsAuthenticated } from "src/store/reducers/auth/authSlice";
import { refreshToken } from "src/store/reducers/auth/refreshToken";
import { getTimeout, setTimeout } from "src/utils/timeout/timeout";

type AuthenticatedRouteProps = {
  children: any
};

const areWeTestingWithJest = (() => process.env.JEST_WORKER_ID !== undefined)();

const startTime = 3300;

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { timer } = useAutoLogout(startTime);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (isAuthenticated) {
        const now = Math.floor(Date.now() / 1000);
        const timeout = getTimeout();
        if (timeout && (now - timeout > 3300) && timer > 0) {
          dispatch(refreshToken());
          setTimeout();
        } else if (!timeout) {
          setTimeout();
        }
      }
    }, 60000);

    return () => {
      clearInterval(myInterval);
    };
  });

  if (isAuthenticated) {
    return children;
  }
  return areWeTestingWithJest ? null : <Navigate to={"/login"} />;
};

export default AuthenticatedRoute;
