import { createAsyncThunk } from "@reduxjs/toolkit";

import { noAuthInstance as axios } from "../../../api/axiosInstance";
import { Store } from "../../Store";

type resetData = {
  email: string;
  token: string;
}

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async(resetData: resetData, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const state = getState() as Store;
    const email = resetData.email;
    const token = resetData.token;
    const password = state.resetPassword.password;

    if (!email || !password || !token) {
      return rejectWithValue("Oops! Please try again. Your email or password is incorrect.");
    }

    const payload = {
      email,
      password,
      token
    };

    return axios.post("/api/v1/auth/reset-password", payload)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        if (error.response.data?.status === 404) {
          return rejectWithValue("Oops! Please try again. Either your email or password are incorrect.");
        }

        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.detail);
        }

        return rejectWithValue("Please try again later, we're experiencing a server error. We're working on it!");
      });
  }
);
