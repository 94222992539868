import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import useStyles from "./RemoveUserDialog.styles";

interface RemoveUserDialogProps {
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  removeUser: Function;
}
const RemoveUserDialog: React.FC<RemoveUserDialogProps> = ({ open, setOpen, removeUser }) => {
  const { classes } = useStyles();

  return (
    <Dialog open={open} className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}>
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.dialogTitle} >Confirm to remove User</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>Are you sure you want to remove user?</Typography>
      </DialogContent>
      <DialogActions className={classes.diablogActions}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => setOpen(false)}
          data-testid={"cancel-button"}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          data-testid={"confirm-button"}
          onClick={() => removeUser()}
          className={classes.confirmButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);
};

export default RemoveUserDialog;
