import React, { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";

import { Application } from "src/models/Application";
import { useAppDispatch } from "src/store/hooks";
import { getPermissions } from "src/store/thunks/permissions/get/getPermissions";

import Section from "./Section/Section";

const RolePermissions : React.FC = () => {
  const [applications, setApplications] = useState<Application[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPermissions()).then(resp => {
      if (!resp.type.includes("rejected")) {
        setApplications(resp.payload);
      }
    });
  }, []);

  return (
    <Grid container spacing={1} ml={1}>
      <Grid item xs={12} mt={3}
        mb={2}>
        <Typography variant={"body1"}>Control what information users may access by application, section, and feature. Begin by expanding the desired application permissions, and toggle what your users should have access to.</Typography>
      </Grid>
      {applications.map((application) => {
        return (
          <Grid item xs={12} key={application.id}>
            <Section application={application} />
          </Grid>);
      })}
    </Grid>
  );
};

export default RolePermissions;
