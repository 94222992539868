import React from "react";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import CustomRouter from "./components/router/CustomRouter";
import reportWebVitals from "./report-web-vitals";
import { rootStore } from "./store/rootStore";
import theme from "./theme";

import "./index.css";

if (
  window.config.reactAppSentryEnvironment &&
  window.config.reactAppSentryDSN &&
  window.config.reactAppSentryEnvironmentWhitelist &&
  window.config.reactAppSentryEnvironmentWhitelist.split(",").includes(
    window.config.reactAppSentryEnvironment
  )
) {
  Sentry.init({
    dsn: window.config.reactAppSentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: window.config.reactAppSentryEnvironment
  });
}

if (window.config.identityFrontendUri === "http://localhost:3001") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

export const muiCache = createCache({
  key: "mui",
  prepend: true
});
const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
  <Provider store={rootStore}>
    <React.StrictMode>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <CustomRouter>
            <App />
          </CustomRouter>
        </ThemeProvider>
      </CacheProvider>
    </React.StrictMode>
  </Provider>
);

// const { dispatch } = rootStore;

// axios.interceptors.response.use(handleResponse, (error) => handleErrorResponse(error, dispatch));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
