import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    padding: "30px"
  },
  dialogPaper: {
    borderRadius: "20px",
    padding: "0 40px"
  },
  dialogTitle: {
    margin: "20px 25px 30px",
    fontSize: "28px",
    padding: 0,
    textAlign: "center",
    color: theme.palette.primary.main
  },
  dialogContent: {
    textAlign: "center",
    fontSize: "16px",
    color: theme.palette.primary.main
  },
  diablogActions: {
    justifyContent: "center"
  },
  cancelButton: {
    fontSize: "0.9rem",
    color: theme.palette.error.main,
    fontWeight: 400,
    border: `1px solid ${theme.gray500Base}`,
    margin: "10px 20px",
    height: "36px",
    background: theme.palette.common.white,
    boxShadow: "none"
  },
  confirmButton: {
    fontSize: "0.9rem",
    color: theme.palette.primary.contrastText,
    background: theme.palette.common.white,
    height: "36px",
    fontWeight: 400,
    margin: "10px 20px",
    boxShadow: "none",
    border: `1px solid ${theme.gray500Base}`
  }
}));

export default useStyles;
