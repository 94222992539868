import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";
import { login } from "src/store/thunks/login/login";

import { LoginState } from "./LoginSliceState";

export const initialState: LoginState = {
  isPasswordShown: false,
  loading: false,
  password: "",
  rememberUser: !!localStorage.getItem("email"),
  email: localStorage.getItem("email") ?? "",
  error: ""
};

function updateLocalStorage(rememberUser: boolean, email: string) {
  if (!rememberUser) {
    localStorage.removeItem("email");
    return;
  }

  localStorage.setItem("email", email);
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    passwordOnchange: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    emailOnchange: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
      updateLocalStorage(state.rememberUser, state.email);
    },
    showPasswordOnchange: (state) => {
      state.isPasswordShown = !state.isPasswordShown;
    },
    rememberUserOnchange: (state) => {
      state.rememberUser = !state.rememberUser;
      updateLocalStorage(state.rememberUser, state.email);
    },
    clearError: (state) => {
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectError = (state: RootState) => state.login.error;
export const selectLoading = (state: RootState) => state.login.loading;
export const selectPassword = (state: RootState) => state.login.password;
export const selectPasswordShown = (state: RootState) => state.login.isPasswordShown;
export const selectRememberUser = (state: RootState) => state.login.rememberUser;
export const selectLoginEmail = (state: RootState) => state.login.email;

export const { passwordOnchange, emailOnchange, showPasswordOnchange, rememberUserOnchange, clearError } =
  loginSlice.actions;

export default loginSlice.reducer;
