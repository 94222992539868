import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  loadingSpinnerContainer: {
    height: "100%"
  },
  loadingSpinner: {
    color: theme.palette.common.white,
    padding: "16px"
  },
  iconAppCard: {
    width: "100px !important",
    height: "100px !important"
  }
}));

export default useStyles;
