import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  date: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

export default useStyles;
