import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { UpdateOperatorPayload } from "./updateOperatorPayload";

export const updateOperator = createAsyncThunk(
  "updateOperator",
  async(id: string | undefined, thunkAPI) => {
    const { dispatch, getState, rejectWithValue } = thunkAPI;
    const state = getState() as RootState;
    const editOperator = state.editOperator;

    const payload: UpdateOperatorPayload = {
      business_name: editOperator.operator?.business_name || "",
      business_contact: editOperator.operator?.business_contact || "",
      business_email_address: editOperator.operator?.business_email_address || "",
      dba_name: editOperator.operator?.dba_name || "",
      display_name: editOperator.operator?.display_name || "",
      billing_street_address: editOperator.operator?.billing_street_address || "",
      billing_street_address_2: editOperator.operator?.billing_street_address_2 || "",
      billing_country: editOperator.operator?.billing_country || "",
      billing_city: editOperator.operator?.billing_city || "",
      billing_region: editOperator.operator?.billing_region || "",
      billing_postal_code: editOperator.operator?.billing_postal_code || "",
      billing_phone: editOperator.operator?.billing_phone || "",
      is_mailing_same_as_billing: editOperator.operator?.is_mailing_same_as_billing || false,
      mailing_street_address: editOperator.operator?.mailing_street_address || "",
      mailing_street_address_2: editOperator.operator?.mailing_street_address_2 || "",
      mailing_country: editOperator.operator?.mailing_country || "",
      mailing_city: editOperator.operator?.mailing_city || "",
      mailing_region: editOperator.operator?.mailing_region || "",
      mailing_postal_code: editOperator.operator?.mailing_postal_code || "",
      mailing_phone: editOperator.operator?.mailing_phone || ""
    };
    return axios.put(`/api/v1/operators/${id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Operator edited successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.messages);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error editing the user";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
