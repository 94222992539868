// Note that this is not currently being used in any meaningful way, so it's getting ignored by coverage.

import React, { ChangeEvent, ReactElement, useState } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { IconArrowBack, IconCircleCheck, IconClick } from "@tabler/icons-react";

import { Link as RouterLink } from "react-router-dom";

import StoreSpaceLogo from "../../assets/storage-360-logo.svg";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../store/hooks";
import { clearError, selectError, selectLoading, usernameOnchange } from "../../store/reducers/forgotPassword/forgotPasswordSlice";
import { forgotPassword } from "../../store/thunks/forgotPassword/forgotPassword";

import useStyles from "./ForgotPasswordForm.styles";

const ForgotPasswordForm: React.FC = (): ReactElement => {
  const error = useSelector(selectError);
  const { classes } = useStyles();
  const theme = useTheme();
  const loading = useSelector(selectLoading);
  const [isSent, setIsSent] = useState(false);
  const dispatch = useDispatch();
  const handleClearError = () => dispatch(clearError());
  const handleEmailOnChange = (e: ChangeEvent<HTMLInputElement>) => dispatch(usernameOnchange(e.target.value));

  const handleClose = () => {
    handleClearError();
  };

  setTimeout(handleClose, 5000);

  const loginErrorDisplay = error
    ? (
      <Box width={"100%"} mt={4}>
        <Alert
          data-testid={"error-message"}
          severity={"error"}
          onClose={handleClose}
        >
          {error}
        </Alert>
      </Box>
      )
    : "";

  const handleForgotPassword = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await dispatch(forgotPassword())
      .then(resp => {
        if (resp.type.includes("fulfilled")) {
          setIsSent(true);
        }
      });
  };

  return (
    <StyledEngineProvider injectFirst>
      <main className={classes.main}>
        <Grid
          className={classes.root}
          container
          item
          justifyContent={"center"}
        >
          <Grid item lg={7}
            container
            justifyContent={"center"}
            alignItems={"center"}
            className={classes.containerImage}
            >
            <Box className={classes.wrappImages}>
              <img
                src={StoreSpaceLogo}
                alt={"Store Space Logo"}
                className={classes.imageLogo}
              />
              <IconClick className={classes.imageClickIcon} strokeWidth={1}/>
            </Box>
          </Grid>
          <Grid item lg={5}className={classes.containerForm}>
            {!isSent
              ? (
                <Grid
                  item
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={classes.outerGrid}
                >
                  <Box className={classes.titlesContainer}>
                    <Typography variant={"h2"} textAlign={"center"} mb={2}>
                      Forgot Password
                    </Typography>
                    <Typography variant={"body2"} textAlign={"center"}>
                      Enter the email registered to your account.<br/>
                      If the email entered matches our <br/>
                      records, you will receive a password reset link.
                    </Typography>
                  </Box>
                  <form
                    className={classes.form}
                    data-testid={"forgot-password-form"}
                    onSubmit={(e) => handleForgotPassword(e)}
                  >
                    <Grid
                      container
                      item
                      direction={"column"}
                      className={classes.containerFormInterior}
                      >
                      <label htmlFor={"forgot-form-email"} ><Typography variant={"subtitle2"}>Email</Typography></label>
                      <TextField
                        id={"forgot-form-email"}
                        data-testid={"forgot-form-email"}
                        aria-describedby={"email-text"}
                        margin={"dense"}
                        variant={"outlined"}
                        autoComplete={"email"}
                        inputProps={{ "data-testid": "email-input" }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleEmailOnChange(e)}
                      />
                      <Button
                        variant={"contained"}
                        size={"large"}
                        type={"submit"}
                        className={classes.forgotButton}
                        data-testid={"forgot-form-button"}
                      >
                        {loading
                          ? <CircularProgress
                              size={"2rem"}
                              data-testid={"forgot-form-button-spinner"}
                              className={classes.spinner}
                          />
                          : <Typography variant={"button"} style={{ color: theme.buttonColor }}>Email Reset Link</Typography>
                        }
                      </Button>
                    </Grid>
                  </form>
                  <Link
                    type={"button"}
                    component={RouterLink}
                    variant={"body2"}
                    to={"/"}
                    className={classes.linkBack}
                  >
                    <IconArrowBack className={classes.backIcon} />
                    <Typography variant={"button"} className={classes.linkBack}>Go Back To Sign In</Typography>
                  </Link>
                  {loginErrorDisplay}
                </Grid>)
              : (<Paper
                  elevation={3}
                  className={classes.forgotPaper}
              >
                <Grid
                  item
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={classes.outerGrid}
                >
                  <IconCircleCheck className={classes.checkCircle} />
                  <Typography variant={"h2"} mb={2}>
                    Login Reset Sent
                  </Typography>
                  <Typography variant={"body2"} align={"center"} mb={3}>
                    Please check your email for the account <br/> verification link.
                  </Typography>
                  <Link
                    type={"button"}
                    component={RouterLink}
                    variant={"body2"}
                    to={"/"}
                    className={classes.linkBack}
                  >
                    <IconArrowBack className={classes.backIcon} />
                    <Typography variant={"button"} className={classes.linkBack}>Go Back To Sign In</Typography>
                  </Link>
                </Grid>
              </Paper>)}
          </Grid>
        </Grid>
      </main>
    </StyledEngineProvider>
  );
};

export default ForgotPasswordForm;
