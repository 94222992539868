import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({

  root: {
    backgroundColor: theme.palette.common.white,
    height: "65px",
    padding: "0 32px 0 32px"
  },
  appBar: {
    backgroundColor: theme.graySecondary50,
    borderRadius: "25px",
    height: "36px",
    paddingRight: "10px",
    paddingLeft: "5px",
    lineHeight: "36px",
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    cursor: "pointer"
  },
  userAvatar: {
    backgroundColor: theme.palette.primary.main,
    width: "32px",
    height: "32px",
    borderRadius: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: ".25rem"
    }
  },
  toolbar: {
    flexDirection: "row",
    flexGrow: 1,
    minHeight: "36px !important",
    padding: "0 !important"
  },
  headerText: {
    color: theme.palette.primary.main,
    marginLeft: "10px"
  },
  image: {
    width: "178px",
    [theme.breakpoints.down("md")]: {
      width: "130px"
    }
  },
  menuUsernameDropItem: {
    padding: "10px 0 10px 0"
  },
  menuUsernameDropItemHead: {
    padding: "10px 0 10px 0",
    display: "block"
  },
  menuUsernameDropIcon: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    width: "24px",
    height: "24px"
  },
  buttonContainer: {
    marginLeft: "auto",
    justifyContent: "flex-end"
  },
  buttonMargin: {
    marginRight: "0.4rem"
  },
  searchMargin: {
    marginRight: "1rem"
  },
  menuItemsGroup: {
    padding: "40px 0 !important"
  },
  backgroundOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.black,
    opacity: "15%",
    zIndex: 999
  },
  menuButton: {
    width: "32px",
    height: "32px",
    background: "inherit",
    marginRight: "7px",
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.gray400Light}`,
    borderRadius: "10px !important",
    cursor: "pointer",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.graySecondary50
    }
  },
  menuButtonIcon: {
    background: "inherit",
    boxShadow: "none",
    width: "18px",
    height: "18px",
    margin: "auto"
  },
  textWithLine: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px"

  },
  imageWithText: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px"

  },
  horizontalLineText: {
    flex: 1,
    marginLeft: "8px",
    textTransform: "uppercase",
    color: theme.palette.common.black

  },
  textWithLineTitle: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    paddingBottom: "5px"
  },
  horizontalLine: {
    flex: 1,
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    marginLeft: "8px"
  },
  menuWrappElement: {
    marginBottom: "50px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.blueGreyColor
    }
  },
  underText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0.2px",
    textAlign: "center",
    display: "block",
    paddingTop: "10px"
  },
  applicationsMenuContent: {
    width: "100%",
    margin: "20px 0 20px 0"
  },
  iconAppMenu: {
    width: "30px"
  },
  iconAppMenuUnder: {
    width: "30px",
    display: "block",
    margin: "0 auto"
  }
}));

export default useStyles;
