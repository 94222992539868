export const format422 = (errors: { [key: string]: string }): string => {
  const formattedErrors = Object.entries(errors).reduce((acc: any, err: [string, string]) => {
    const errorType = `${err[0].substr(0, 1).toUpperCase()}${err[0].substr(1, err[0].length)}`;
    const errorMessage = (Object.values(err[1]).join(", "));
    acc.push(`${errorType}: ${errorMessage}`);
    return acc;
  }, []).join("; ");

  return `The following errors were detected: ${formattedErrors}`;
};
