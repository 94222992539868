import { createAsyncThunk } from "@reduxjs/toolkit";

import { Store } from "src/store/Store";

import { axiosInstance as axios } from "../../../api/axiosInstance";
import { showSnackbar } from "../../reducers/snackbar/snackbarSlice";

type meta = {
  page: number,
  pageSize: number,
  search: string
}

export const getCustomRoles = createAsyncThunk(
  "getCustomRoles",
  async(meta: meta, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const state = getState() as Store;
    const operatorId = state.userInfo.user?.operator_id;
    const pageSize = meta.pageSize;
    const page = meta.page;
    const filterName = meta.search && ("&filter[name]=" + encodeURIComponent(`%${meta.search}%`));

    return axios.get(`/api/v1/operators/${operatorId}/roles?pageSize=${pageSize}&page=${page}&filter[is_default]=0&filter[is_deleted]=0${filterName}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the custom Roles";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
