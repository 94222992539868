import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "545px",
    minHeight: "635px",
    maxHeight: "100%",
    padding: "0 !important",
    backgroundColor: theme.blueGreyColor,
    borderRadius: "10px",
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -35%)",
    [theme.breakpoints.down("md")]: {
      top: "5%",
      left: "5%",
      transform: "translateY(5%)",
      width: "90%"
    }
  },
  title: {
    textAlign: "center",
    fontWeight: 700
  },
  subtitle: {
    textAlign: "center"
  },
  form: {
    width: "100%"
  },
  formInput: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0 !important"
    }
  },
  iconEye: {
    width: "24px",
    height: "24px",
    color: theme.palette.primary.main
  },
  linkSignin: {
    display: "block",
    color: theme.palette.primary.contrastText,
    paddingTop: "15px",
    textTransform: "uppercase",
    textAlign: "center"
  },
  innerGrid: {
    marginTop: "2rem"
  },
  containerImage: {
    width: "100%",
    height: "155px",
    paddingTop: "50px"
  },
  image: {
    [theme.breakpoints.down("md")]: {
      width: "50%"
    },
    width: "344px",
    height: "55px",
    margin: "0 auto auto",
    display: "block"
  },
  imageFinger: {
    width: "116.98px",
    height: "125.4px",
    marginBottom: "50px",
    color: theme.palette.primary.contrastText
  },
  containerForm: {
    width: "100%"
  },
  "@media (max-width: 1279px)": {
    confirmPaper: {
      borderRadius: "10px",
      width: "100%"
    }
  },
  confirmButton: {
    height: "2.5rem",
    textTransform: "uppercase",
    color: theme.palette.common.white,
    marginTop: "16px",
    background: theme.palette.primary.contrastText,
    float: "left"
  },
  confirmCancelButton: {
    height: "2.5rem",
    textTransform: "uppercase",
    marginTop: "16px",
    background: theme.palette.common.white,
    boxShadow: "none",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "left"
    }
  },
  signInButton: {
    height: "2.5rem",
    textTransform: "uppercase",
    color: theme.palette.common.white,
    marginTop: "16px",
    background: theme.palette.primary.contrastText,
    width: "100%"
  },
  getStarted: {
    height: "36px",
    width: "332px",
    textTransform: "uppercase",
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  confirmPaper: {
    borderRadius: "0 0 10px 10px",
    width: "100%",
    minHeight: "480px",
    maxHeight: "100%",
    boxShadow: "none"
  },
  outerGrid: {
    padding: "50px 70px 50px 70px"
  },
  formContainer: {
    [theme.breakpoints.down("lg")]: {
      width: "100%"
    },
    width: "332px"
  },
  spinner: {
    color: theme.palette.common.white
  },
  password_label: {
    marginTop: "5px"
  },
  forgotPaper: {
    borderRadius: "0 10px 10px 0",
    width: "100%",
    boxShadow: "none"
  },
  checkCircle: {
    color: theme.checkCircle,
    width: "85px",
    height: "85px",
    marginBottom: "25px"
  },
  linkBack: {
    display: "block",
    textAlign: "center",
    width: "100%"
  },
  disabled: {
    background: theme.blueGreyColor
  }
}));

export default useStyles;
