import React, { ReactElement, useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { IconEdit, IconSearch } from "@tabler/icons-react";
import debounce from "lodash/debounce";
import moment from "moment";

import { Link as RouterLink } from "react-router-dom";

import { usePagination } from "src/components/hooks/usePagination";
import { User } from "src/models/User";
import { APIListResponse } from "src/models/responses/ListResponse";
import { selectLoading, selectUsers } from "src/store/reducers/users/usersSlice";
import { getUsers } from "src/store/thunks/users/getAll/getUsers";

import { useAppDispatch, useAppSelector, useAppSelector as useSelector } from "../../store/hooks";

import DisableUserDialog from "./DisableUserDialog/DisableUserDialog";
import UpdateRegistrationDialog from "./UpdateRegistrationDialog/UpdateRegistrationDialog";

import useStyles from "./ListUsers.styles";

const ListUsers: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    search,
    setSearch,
    handleChangePaginationModel,
    paginationModel,
    handleSortModelChange,
    sortOptions
  } = usePagination("usersTable");

  const users: APIListResponse<User[]> = useAppSelector(selectUsers) || {};

  const [userToDisable, setUserToDisable] = useState<User>();
  const [openDisableUserDialog, setOpenDisableUserDialog] = useState(false);
  const [openUpdateRegistrationDialog, setOpenUpdateRegistrationDialog] = useState(false);
  const [registrationExpired, setRegistrationExpired] = useState(false);

  const loading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getUsers({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search,
      sortOptions
    }));
  }, [paginationModel, sortOptions]);

  const handleSearch = useCallback(debounce((searchTerm: string) => {
    handleChangePaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    dispatch(getUsers({
      page: 1,
      pageSize: paginationModel.pageSize,
      search: searchTerm,
      sortOptions
    }));
  }, 500), []);

  const handleUpdate = async() => {
    await dispatch(getUsers({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search,
      sortOptions
    }));
  };

  function StatusColumn(props: GridRenderCellParams<any, any>) {
    let showExpired: boolean = false;

    if (props.row.has_to_do_registration === 1 && props.row.sent_registration_at) {
      const now = moment.utc();
      const sentRegistrationAt = moment.utc(props.row.sent_registration_at);
      showExpired = moment.duration(now.diff(sentRegistrationAt)).asMinutes() > 1440;
    }

    return (props.row.has_to_do_registration > 0 && props.row.finished_registration < 1)
      ? <Button
          data-testid={"update-registration-user-link"}
          className={classes.buttonActive}
          variant={"outlined"}
          onClick={() => {
            setRegistrationExpired(showExpired);
            setUserToDisable(props.row);
            setOpenUpdateRegistrationDialog(true);
          }}
    >
        {props.row.cancelled_registration === 1 ? "Cancelled" : showExpired ? "Expired" : "Pending" }
      </Button>
      : (
        <Button
          data-testid={"deactivate-user-link"}
          className={classes.buttonActive}
          variant={"outlined"}
          onClick={() => {
            setUserToDisable(props.row);
            setOpenDisableUserDialog(true);
          }}
        >
          {props.row.is_active > 0 ? "Active" : "Inactive" }
        </Button>
        );
  };

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First name",
      flex: 0.8,
      filterable: false,
      sortable: true
    },
    {
      field: "last_name",
      headerName: "Last name",
      flex: 0.8,
      filterable: false,
      sortable: true
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1.2,
      filterable: false,
      sortable: true
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 0.8,
      filterable: false,
      sortable: true
    },
    {
      field: "role",
      headerName: "Role",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        const roles = params.row.roles?.map((role: any) => {
          return { name: role.name, color: role.color };
        });

        const filteredRoles = roles?.filter((value: any, index: number, self: any) =>
          index === self.findIndex((t: any) => (
            t.color === value.color && t.name === value.name
          ))
        );

        return <Box sx={{ display: "flex", flexWrap: "wrap", gap: "5px", whiteSpace: "nowrap" }}>
          {filteredRoles?.map((rolen: any) => (
            <div
              key={rolen.name}
              style={{
                textAlign: "center",
                background: `${rolen.color}`,
                color: "#FFFFFF",
                padding: "7px",
                borderRadius: "100px",
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Roboto",
                letterSpacing: "0.2px"
              }}
        >
              {rolen.name}
            </div>
          ))}
        </Box>;
      }
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      renderCell: StatusColumn,
      valueGetter: ({ row }) => {
        if (row.has_to_do_registration === 1 && row.sent_registration_at) {
          const now = moment.utc();
          const sentRegistrationAt = moment.utc(row.sent_registration_at);
          return moment.duration(now.diff(sentRegistrationAt)).asMinutes() > 1440 && "Expired";
        }

        if (row.has_to_do_registration > 0 && row.finished_registration < 1) {
          if (row.cancelled_registration) {
            return "Cancelled";
          } else {
            return "Pending";
          }
        } else {
          if (row.is_active) {
            return "Active";
          } else {
            return "Inactive";
          }
        }
      },
      flex: 0.7
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => <Link
        type={"button"}
        component={RouterLink}
        variant={"body4"}
        to={`/users/edit-user/${params.id}`}
        data-testid={"edit-user-link"}
        className={classes.buttonEdit}
    >
        <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>Edit User</Typography>} arrow>
          <IconEdit style={{ width: "16px", height: "16px" }} />
        </Tooltip>
      </Link>
    }
  ];

  return (
    <Grid
      container
      item
      lg={12}
      mt={5}
      mb={5}
        >
      <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", boxShadow: "none" }}>
        <Grid container item xs={12}
          style={{ padding: "0 25px 25px" }}>
          <Box className={classes.boxContent}>
            <Typography variant={"h6"} className={classes.titleTable}>User Index</Typography>
            <Link ml={3}
              type={"button"}
              component={RouterLink}
              variant={"body2"}
              to={"/users/add-user"}
              data-testid={"add-user-link"}
              className={classes.linkAdd}
                  >
              <Button
                variant={"contained"}
                size={"large"}
                className={classes.addButtonTable}
                data-testid={"add-user-button"}
                  >
                <Typography variant={"button"} style={{ color: theme.buttonColor }}> + Add New User</Typography>
              </Button>
            </Link>
            <TextField
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
                handleSearch(event.target.value);
              }}
              placeholder={"Search"}
              className={classes.filterInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconSearch className={classes.filterInputIcon} />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rowCount={users?.meta?.total || 0}
              rows={users.data || []}
              columns={columns}
              disableRowSelectionOnClick
              paginationMode={"server"}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page }
                },
                sorting: {
                  sortModel: sortOptions
                }
              }}
              onPaginationModelChange={handleChangePaginationModel}
              sortingMode={"server"}
              onSortModelChange={handleSortModelChange}
              pageSizeOptions={[5, 10, 25, 50]}
              loading={loading}
              />
          </Grid>

        </Grid>
      </Paper>
      <DisableUserDialog
        open={openDisableUserDialog}
        setOpen={setOpenDisableUserDialog}
        user={userToDisable}
        handleUpdate={handleUpdate}
        />
      <UpdateRegistrationDialog search={search} page={paginationModel.page} pageSize={paginationModel.pageSize}
        open={openUpdateRegistrationDialog} setOpen={setOpenUpdateRegistrationDialog} user={userToDisable}
        sortOptions={sortOptions}
        registrationExpired={registrationExpired}/>
    </Grid>
  );
};

export default ListUsers;
