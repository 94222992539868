import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { validateToken } from "src/store//thunks/user/confirm/validateToken";
import { RootState } from "src/store/rootStore";
import { confirmUser } from "src/store/thunks/user/confirm/confirmUser";

import { ConfirmUserSliceState } from "./ConfirmUserSliceState";

export const initialState: ConfirmUserSliceState = {
  user: {
    id: 1,
    operator_id: 1,
    first_name: "",
    last_name: "",
    username: "",
    phone: "",
    email: "",
    email_verified_at: "",
    has_to_do_registration: "",
    finished_registration: "",
    title: "",
    cancelled_registration: 0
  },
  isPasswordShown: false,
  password: "",
  password_confirmation: "",
  registration_token: "",
  error: "",
  loading: false,
  step: 1
};

export const confirmUserSlice = createSlice({
  name: "confirmUser",
  initialState,
  reducers: {
    passwordOnchange: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    passwordConfirmationOnchange: (state, action: PayloadAction<string>) => {
      state.password_confirmation = action.payload;
    },
    emailOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.email = action.payload;
    },
    firstNameOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.first_name = action.payload;
    },
    lastNameOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.last_name = action.payload;
    },
    usernameOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.username = action.payload;
    },
    registrationTokenOnchange: (state, action: PayloadAction<string>) => {
      state.registration_token = action.payload;
    },
    showPasswordOnchange: (state) => {
      state.isPasswordShown = !state.isPasswordShown;
    },
    stepOnchange: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    clearError: (state) => {
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(confirmUser.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      .addCase(validateToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectError = (state: RootState) => state.confirmUser?.error;
export const selectLoading = (state: RootState) => state.confirmUser?.loading;
export const selectUserFirstName = (state: RootState) => state.confirmUser?.user?.first_name;
export const selectUserLastName = (state: RootState) => state.confirmUser?.user?.last_name;
export const selectUserUsername = (state: RootState) => state.confirmUser?.user?.username;
export const selectUserEmail = (state: RootState) => state.confirmUser?.user?.email;
export const selectPassword = (state: RootState) => state.confirmUser?.password;
export const selectPasswordConfirmation = (state: RootState) => state.confirmUser?.password_confirmation;
export const selectPasswordShown = (state: RootState) => state.confirmUser?.isPasswordShown;
export const selectRegistrationToken = (state: RootState) => state.confirmUser?.registration_token;
export const selectStep = (state: RootState) => state.confirmUser?.step;
export const selectCancelledRegistration = (state: RootState) => state.confirmUser?.user?.cancelled_registration;

export const {
  passwordOnchange,
  passwordConfirmationOnchange,
  firstNameOnchange,
  lastNameOnchange,
  usernameOnchange,
  emailOnchange,
  showPasswordOnchange,
  clearError,
  registrationTokenOnchange,
  stepOnchange
} = confirmUserSlice.actions;

export default confirmUserSlice.reducer;
