import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  containerForm: {
    width: "72%"
  },
  containerFormMidle: {
    width: "58%"
  },
  arrowBack: {
    marginRight: theme.spacing(1)
  },
  spinner: {
    color: theme.palette.common.white
  },
  noArrow: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    },
    "input[type=number]": {
      MozAppearance: "textfield"
    }
  }
}));

export default useStyles;
