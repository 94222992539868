import React, { ChangeEvent, useState } from "react";

import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { IconCopy } from "@tabler/icons-react";
import moment from "moment";

import MaskedInput from "src/components/ui/MaskedInput/MaskedInput";
import { User } from "src/models/User";
import { useAppDispatch } from "src/store/hooks";
import { emailOnchange, firstNameOnchange, lastNameOnchange, phoneOnchange, setLoading } from "src/store/reducers/editUser/editUserSlice";
import { showSnackbar } from "src/store/reducers/snackbar/snackbarSlice";
import { getUser } from "src/store/thunks/user/get/getUser";
import { regenerateRegistrationLink } from "src/store/thunks/user/registration/regenerateRegistrationLink";

import DisableUserDialog from "../../ListUsers/DisableUserDialog/DisableUserDialog";
import ResetPasswordDialog from "../ResetPasswordDialog/ResetPasswordDialog";

import useStyles from "./UserInformation.styles";

interface UserInformationProps {
  handleSubmit: Function;
  values: any;
  handleUpdate: Function;
  touched: any;
  errors: any;
  editUser?: User;
  userRoles?: number[];
}

const UserInformation: React.FC<UserInformationProps> = ({ handleSubmit, values, handleUpdate, touched, errors, editUser, userRoles }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [disableUserOpen, setDisableUserOpen] = useState(false);
  const [registrationLink, setRegistrationLink] = useState(null);

  const handleFirstNameOnchange = (
    fieldName: string,
    fieldValue: string
  ) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(firstNameOnchange(fieldValue));
  };

  const handleLastNameOnchange = (fieldName: string, fieldValue: string) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(lastNameOnchange(fieldValue));
  };

  const handleEmailOnchange = (fieldName: string, fieldValue: string) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(emailOnchange(fieldValue));
  };

  const handlePhoneOnchange = (fieldName: string, fieldValue: string) => {
    handleUpdate(fieldName, fieldValue);
    dispatch(phoneOnchange(fieldValue));
  };

  const handleRegenerateRegistrationLink = () => {
    dispatch(regenerateRegistrationLink(editUser?.id)).then((resp) => {
      if (resp.type.includes("fulfilled")) {
        setRegistrationLink(resp.payload.registration_link);
      }
    });
  };

  const handleCopyClipboard = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.currentTarget.style.color = `royalblue`;
    registrationLink && navigator.clipboard.writeText(registrationLink);

    dispatch(showSnackbar({
      message: "Copied!",
      variant: "info"
    }));
  };

  const handleUpdateUser = () => {
    dispatch(setLoading(true));
    dispatch(getUser()).then((resp) => {
      if (resp.type.includes("fulfilled")) {
        dispatch(setLoading(false));
      }
    });
  };

  const hasRegenerateRegistrationLink = () => {
    const isAdminOrOwner = (userRoles || []).some((role: Number) => [1, 11, 13, 15, 23, 25, 27].includes(Number(role)));

    const hasToDoRegistration = editUser && +editUser.has_to_do_registration === 1;

    const sentRegistrationAt = editUser && moment.utc(editUser.sent_registration_at);
    const hasExpired = sentRegistrationAt && moment.duration(moment.utc().diff(sentRegistrationAt)).asMinutes() > 1440;

    return isAdminOrOwner && hasToDoRegistration && !hasExpired;
  };

  return (
    <form
      data-testid={"edit-user-form"}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}>
      <Paper elevation={4} className={classes.paper}>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            <Typography variant={"h6"} >
              User Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {errors.editUserAssignments && <Typography className={classes.assignmentError}>{errors.editUserAssignments}</Typography>}
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-first-name"}>
              <Typography variant={"subtitle2"}>First Name</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-first-name"}
              data-testid={"edit-user-first-name"}
              name={"editUserFirstName"}
              value={values.editUserFirstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFirstNameOnchange("editUserFirstName", e.target.value)
                  }
              error={
                    touched &&
                    touched.editUserFirstName &&
                    errors &&
                    Boolean(errors.editUserFirstName)
                  }
              helperText={
                    touched &&
                    touched.editUserFirstName &&
                    errors &&
                    errors.editUserFirstName
                  }
                />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-last-name"}>
              <Typography variant={"subtitle2"}>Last Name</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-last-name"}
              data-testid={"edit-user-last-name"}
              inputProps={{ "data-testid": "edit-user-last-name" }}
              name={"editUserLastName"}
              value={values.editUserLastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleLastNameOnchange("editUserLastName", e.target.value)
                  }
              error={touched && touched.editUserLastName && errors && Boolean(errors.editUserLastName)}
              helperText={
                    touched &&
                    touched.editUserLastName &&
                    errors &&
                    errors.editUserLastName
                  }
              />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-username"}>
              <Typography variant={"subtitle2"}>Username</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-username"}
              data-testid={"edit-user-username"}
              inputProps={{ "data-testid": "edit-user-username" }}
              disabled={true}
              name={"editUserUsername"}
              value={values.editUserUsername}
              />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-email"}>
              <Typography variant={"subtitle2"}>Email</Typography>
            </InputLabel>
            <TextField
              fullWidth
              id={"edit-user-email"}
              data-testid={"edit-user-email"}
              inputProps={{ "data-testid": "edit-user-email" }}
              name={"editUserEmail"}
              value={values.editUserEmail}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleEmailOnchange("editUserEmail", e.target.value)
              }
              error={touched && touched.editUserEmail && errors && Boolean(errors.editUserEmail)}
              helperText={
                    touched &&
                    touched.editUserEmail &&
                    errors &&
                    errors.editUserEmail
                  }
              />
          </Grid>
          <Grid item xs={12} mb={2}>
            <InputLabel htmlFor={"edit-user-phone"}>
              <Typography variant={"subtitle2"}>Phone Number</Typography>
            </InputLabel>
            <MaskedInput
              fullWidth
              id={"edit-user-phone"}
              data-testid={"edit-user-phone"}
              name={"editUserPhone"}
              type={"tel"}
              value={values.editUserPhone}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handlePhoneOnchange("editUserPhone", e.target.value)
                  }
              error={
                    touched &&
                    touched.editUserPhone &&
                    errors &&
                    Boolean(errors.editUserPhone)
                  }
              helperText={
                    touched &&
                    touched.editUserPhone &&
                    errors &&
                    errors.editUserPhone
                  }
              />
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer} >
            <Button variant={"outlined"} className={classes.actionButtons} onClick={() => setForgotPasswordOpen(true)}>
              <Typography>Reset Password</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>When manually resetting the password, the user will receive an email with a link to reset their password.</Typography>
          </Grid>
          {hasRegenerateRegistrationLink() && (<><Grid item xs={12} className={classes.buttonContainer} >
            <Button variant={"outlined"} className={classes.actionButtons} onClick={() => handleRegenerateRegistrationLink()}>
              <Typography>Generate Registration Link</Typography>
            </Button>
          </Grid></>)}
          {hasRegenerateRegistrationLink() && (registrationLink
            ? (<Grid item xs={12} position={"relative"}>
              <TextField
                fullWidth
                id={"registration-link"}
                data-testid={"registration-link"}
                value={registrationLink}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconCopy onClick={(e) => handleCopyClipboard(e)} className={classes.copyClipboard} />
                    </InputAdornment>
                  )
                }} />
            </Grid>)
            : (<Grid item xs={12}><Typography>Click on the button to generate a new registration link. This link will need to be manually sent to the user.</Typography></Grid>))}
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button variant={"outlined"} className={classes.actionButtons} onClick={() => setDisableUserOpen(true)}>
              <Typography>{editUser?.is_active ? "Deactivate User" : "Activate User"} </Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>By deactivating a user, they will lose access to the platform. After deactivation the user will be deleted if not reinstated within 30 days</Typography>
          </Grid>
        </Grid>
      </Paper>
      <ResetPasswordDialog open={forgotPasswordOpen} setOpen={setForgotPasswordOpen} />
      <DisableUserDialog open={disableUserOpen} setOpen={setDisableUserOpen} user={editUser}
        handleUpdate={handleUpdateUser} />
    </form>);
};

export default UserInformation;
