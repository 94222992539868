import React, { ReactElement, useEffect } from "react";

import { Alert, Fab, Grid, Snackbar, useTheme } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";

import NavBar from "../../components/navigation/NavBar/NavBar";
import NavDrawer from "../../components/navigation/NavDrawer/NavDrawer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { hideDrawer, selectShowDrawer, toggleDrawer } from "../../store/reducers/navigation/navigationSlice";
import {
  hideSnackbar,
  selectSnackbarMessage,
  selectSnackbarVariant
} from "../../store/reducers/snackbar/snackbarSlice";

import useStyles from "./AppLayout.styles";

interface AppLayoutProps {
  children?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = (props: AppLayoutProps): ReactElement => {
  const dispatch = useAppDispatch();
  const handleToggleDrawer = () => dispatch(toggleDrawer());
  const isDrawerShown = useAppSelector(selectShowDrawer);
  const snackbarMessage = useAppSelector(selectSnackbarMessage);
  const snackbarVariant = useAppSelector(selectSnackbarVariant);
  const { classes } = useStyles({ isDrawerShown });
  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const theme = useTheme();
  const tabletWidth = theme.breakpoints.values.md;
  const mobileWidth = theme.breakpoints.values.sm;

  const handleResize = () => {
    if (windowWidth <= tabletWidth && windowWidth > mobileWidth) {
      dispatch(hideDrawer());
    }
  };

  const handleCloseSnackbar = () => dispatch(hideSnackbar());

  useEffect(() => {
    handleResize();
  }, [window.innerWidth]);

  const navDrawer = windowWidth > mobileWidth
    ? (
      <Grid
        item
        className={classes.navDrawer}
      >
        <NavDrawer />
      </Grid>
      )
    : null;

  return (
    <StyledEngineProvider injectFirst>
      <NavBar />
      <Grid
        item
        container
        direction={"row"}
        className={classes.root}
      >
        {navDrawer}
        <Grid
          item
          container
          direction={"column"}
          className={classes.contentContainer}
        >
          <main className={classes.mainContainer}>
            <Fab
              color={"primary"}
              size={"small"}
              aria-label={"toggle navigation drawer"}
              data-testid={"toggle-navigation-drawer-button"}
              className={classes.closeDrawerButton}
              onClick={() => handleToggleDrawer()}
      >
              {isDrawerShown
                ? <IconChevronsLeft
                    className={classes.chevron}
                    fontSize={"small"}
                    data-testid={"toggle-left-arrow"}
                />
                : <IconChevronsRight
                    className={classes.chevron}
                    fontSize={"small"}
                    data-testid={"toggle-right-arrow"}
                />
            }
            </Fab>
            {props.children}
          </main>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!snackbarMessage}
        autoHideDuration={5000}
        onClose={() => handleCloseSnackbar()}
        data-testid={"snackbar-outer"}
      >
        <Alert
          severity={snackbarVariant}
          onClose={() => handleCloseSnackbar()}
          data-testid={"snackbar-inner"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledEngineProvider>
  );
};

export default AppLayout;
