import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const getRolesUsers = createAsyncThunk(
  "getRolesUsers",
  async(id: string | undefined, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    return axios.get(`/api/v1/roles/${id}/users`)
      .then(resp => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error fetching the current role users!";
        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
