import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
    width: "50%",
    [theme.breakpoints.down("xl")]: {
      width: "65%"
    },
    [theme.breakpoints.down("lg")]: {
      width: "85%"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  saveButton: {
    height: "40px",
    textTransform: "uppercase",
    marginTop: "16px",
    marginLeft: "40px",
    background: theme.palette.primary.contrastText,
    float: "right"
  },
  cancelButton: {
    height: "40px",
    padding: "0 16px 0 16px",
    lineHeight: "40px",
    textTransform: "uppercase",
    marginTop: "16px",
    textDecoration: "none",
    boxShadow: "none",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "left"
    }
  },
  spinner: {
    marginLeft: "30%",
    marginTop: "10%"
  },
  linkBack: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    margin: "20px 0",
    display: "inline-flex",
    alignItems: "center"
  },
  backArrow: {
    width: "10px"
  },
  actionButtons: {
    display: "inline-flex",
    float: "right"
  }

}));

export default useStyles;
