import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { UpdateUserPayload } from "./updateUserPayload";

export const updateUser = createAsyncThunk(
  "updateUser",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const editUser = state.editUser;
    const id = editUser.selectedUserId;

    const payload: UpdateUserPayload = {
      first_name: editUser.user?.first_name || "",
      last_name: editUser.user?.last_name || "",
      username: editUser.user?.username || "",
      phone: editUser.user?.phone || "",
      email: editUser.user?.email || "",
      title: "CEO",
      assignments: editUser.user?.assignments || []
    };

    return axios.put(`/api/v1/users/${id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "User edited successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.messages);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error editing the user";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
