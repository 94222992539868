import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const deactivateOperator = createAsyncThunk(
  "deactivateOperator",
  async(operatorId: number, { dispatch, rejectWithValue }) => {
    try {
      const resp = await axios.post(`/api/v1/operators/${operatorId}/deactivate`);
      dispatch(showSnackbar({
        message: "Operator deactivated successfully",
        variant: "success"
      }));

      return resp.data;
    } catch {
      const errorMessage = `There was an error trying to deactivate the Operator ${operatorId}`;

      dispatch(showSnackbar({
        message: errorMessage,
        variant: "error"
      }));
      return rejectWithValue(errorMessage);
    }
  }
);
