import * as Yup from "yup";

import { emailRegex } from "src/utils/validateEmail/validateEmail";

export const newUserFormValidation = Yup.object().shape({
  newUserFirstName: Yup.string().required("First Name is required."),
  newUserLastName: Yup.string().required("Last Name is required."),
  newUserEmail: Yup.string().matches(emailRegex, "Email is invalid.").required("Email is required."),
  newUserPhone: Yup.string().length(10, "Phone Number must be 10 digits").required("Phone Number is required.")
});
