
import React, { ReactElement } from "react";

import { Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import { IconArrowBack, IconCircleCheck } from "@tabler/icons-react";

import { Link as RouterLink } from "react-router-dom";

import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { AppBreadcrumb } from "src/models/app-breadcrumb";

import { useAppSelector } from "../../store/hooks";
import { selectPrimaryContactEmail } from "../../store/reducers/businessInformation/businessInformationSlice";

import useStyles from "./OperatorsSuccess.styles";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Operators"
  },
  {
    name: "Onboard Operators",
    bold: true
  }
];

const OperatorsSuccess: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const contactEmail = useAppSelector(selectPrimaryContactEmail);
  return (
    <Grid
      data-testid={"add-user-operator-view"}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.root}
    >
      <AppLayout>
        <PageHeader
          title={"Operator"}
          breadcrumbs={breadcrumbs}
        />
        <Paper
          elevation={3}
          className={classes.operatorPaper}
        >
          <Grid
            item
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            className={classes.outerGrid}
          >
            <IconCircleCheck className={classes.checkCircle} />
            <Typography variant={"h2"}>
              Operator Added
            </Typography>
            <Typography variant={"body2"} className={classes.titleText}>
              A new Operator has been successfully created.<br />
              An email containing a link for first time account<br />
              set-up has been sent to {contactEmail} to<br />
              finishing initial account creation.
            </Typography>
            <Grid spacing={1} container mt={5}>
              <Grid item sm={12} md={6}>
                <Link
                  type={"button"}
                  component={RouterLink}
                  variant={"body2"}
                  to={"/operators/onboard-operator"}
                  className={classes.linkBack}
                >
                  <IconArrowBack className={classes.undoOutlined} /><Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>Return To Onboard</Typography>
                </Link>
              </Grid>
              <Grid item sm={12} md={6}>
                <Link
                  type={"button"}
                  component={RouterLink}
                  variant={"body2"}
                  to={"/operators/operators-list"}
                  className={classes.linkBack2}
                >
                  <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>Go to operator list</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </AppLayout>
    </Grid>
  );
};

export default OperatorsSuccess;
