import React from "react";

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { DataGrid, GridCallbackDetails, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { IconEdit, IconSearch, IconTrash } from "@tabler/icons-react";
import moment from "moment";

import { Link as RouterLink } from "react-router-dom";

import { Role } from "src/models/Role";

import useStyles from "./RolesTable.styles";

interface RolesTableProps {
    roles: Role[],
    tableName: string,
    total: number,
    paginationModel: GridPaginationModel,
    handleChangePaginationModel: (model: GridPaginationModel, details: GridCallbackDetails<any>) => void,
    search?: string,
    setSearch?: Function,
    handleSearch?: Function,
    setOpenAddRoleDialog: Function,
    setRoleToDisable: Function,
    setOpenDisableRoleDialog: Function,
    setRoleToDelete: Function,
    setOpenDeleteRoleDialog: Function,
    loading: boolean,
    setDisableTable: Function
}

const RolesTable: React.FC<RolesTableProps> = ({ roles, tableName, total, paginationModel, search, handleChangePaginationModel, setSearch, handleSearch, setOpenAddRoleDialog, setRoleToDisable, setOpenDisableRoleDialog, setRoleToDelete, setOpenDeleteRoleDialog, loading, setDisableTable }) => {
  const theme = useTheme();
  const { classes } = useStyles();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Role name",
      flex: 0.8,
      filterable: false
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      filterable: false
    },
    {
      field: "color",
      headerName: "Color",
      flex: 0.5,
      filterable: false,
      renderCell: (params) => <Box
        sx={{
          width: 20,
          height: 20,
          backgroundColor: params.row.color,
          borderRadius: 50
        }}
      />
    },
    {
      field: "created_at",
      headerName: "Created On",
      flex: 1,
      filterable: false,
      valueGetter: ({ row }) =>
        moment(row.created_at).format("MM/DD/YYYY")
    },
    {
      field: "enabled",
      headerName: "Enabled",
      flex: 0.5,
      filterable: false,
      renderCell: (params) => <Switch checked={Boolean(params.row.is_active)} onChange={() => {
        setRoleToDisable(params.row);
        setDisableTable(tableName);
        setOpenDisableRoleDialog(true);
      }} />
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      filterable: false,
      renderCell: (params) => <>
        <IconButton>
          <Link
            type={"button"}
            component={RouterLink}
            to={`/roles/edit-role/${params.row.id}`}
            data-testid={"edit-role-link"}
          >
            <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>Edit Role</Typography>} arrow>
              <IconEdit className={classes.actionsButton} />
            </Tooltip>
          </Link>
        </IconButton>
        {tableName === "customRolesTable" &&
          <IconButton onClick={() => { setRoleToDelete(params.row); setOpenDeleteRoleDialog(true); }}>
            <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>Delete Role</Typography>} arrow>
              <IconTrash className={classes.actionsButton} />
            </Tooltip>
          </IconButton>
        }
      </>
    }
  ];

  return (
    <Grid
      container
      item
      lg={12}
      mt={5}
      mb={5}
    >
      <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", boxShadow: "none" }}>
        <Grid container item xs={12}
          style={{ padding: "0 25px 25px" }}>
          <Box className={classes.boxContent}>
            <Typography variant={"h6"} className={classes.titleTable}>{tableName === "customRolesTable" ? "Custom Roles" : "Default Roles" }</Typography>
            {tableName === "customRolesTable" &&
            <>
              <Button
                variant={"contained"}
                size={"large"}
                className={classes.addButtonTable}
                data-testid={"add-role-button"}
                onClick={() => setOpenAddRoleDialog(true)}
                >
                <Typography variant={"button"} style={{ color: theme.buttonColor }}> + New Role</Typography>
              </Button>
              <TextField
                value={search}
                onChange={(event) => {
                  setSearch && setSearch(event.target.value);
                  handleSearch && handleSearch(event.target.value);
                }}
                placeholder={"Search"}
                id={"search-roles"}
                className={classes.filterInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      <IconSearch className={classes.filterInputIcon} />
                    </InputAdornment>
                  )
                }}
              />
            </>
          }
          </Box>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              rowCount={total || 0}
              rows={roles || []}
              columns={columns}
              disableRowSelectionOnClick
              paginationMode={"server"}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page }
                }
              }}
              onPaginationModelChange={handleChangePaginationModel}
              pageSizeOptions={[5, 10, 25, 50]}
              loading={loading}
          />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RolesTable;
