import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {},
  stepButtonsGroup: {
    float: "right"
  },
  cancelButton: {
    marginRight: "5px"
  },
  arrowBack: {
    marginRight: theme.spacing(1)
  },
  nextButtonContainer: {
    justifySelf: "flex-end"
  },
  nextButton: {
    padding: "10px 20px",
    width: "79px",
    height: "36px",
    background: theme.palette.primary.contrastText,
    borderRadius: "4px"
  },
  spinner: {
    color: theme.palette.common.white
  }
}));

export default useStyles;
