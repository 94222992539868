import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  boxContent: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "35px 0 35px"
  },
  titleTable: {
    display: "inline-block"
  },
  flagIcon: {
    color: theme.palette.primary.main,
    marginBottom: "-3px",
    width: "16px",
    height: "16px",
    cursor: "pointer"
  },
  flagIconTxt: {
    cursor: "pointer",
    textTransform: "uppercase",
    textAlign: "left",
    marginLeft: "4px"
  },
  currentSession: {
    padding: "8px 6px 8px 6px",
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: "8px",
    color: theme.palette.common.white,
    fontSize: "12px",
    letterSpacing: "0.2px",
    fontFamily: "Roboto",
    fontWeight: 400,
    display: "block",
    width: "120px"
  },
  previousSession: {
    padding: "8px 6px 8px 6px",
    backgroundColor: theme.yellow400Light,
    borderRadius: "8px",
    color: theme.palette.common.white,
    fontSize: "12px",
    letterSpacing: "0.2px",
    fontFamily: "Roboto",
    fontWeight: 400,
    display: "block",
    width: "120px"
  },
  sessionIcon: {
    marginBottom: "-5px",
    width: "18px",
    height: "18px",
    paddingRight: "5px"
  },
  tableCellReport: {
    width: "80px !important",
    display: "block"
  }
}));

export default useStyles;
