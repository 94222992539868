import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { noAuthInstance as axios } from "../../../api/axiosInstance";

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const forgotPasswordUsername = state.forgotPassword.username;
    const editUsername = state.editUser?.user?.username;

    if (!forgotPasswordUsername && !editUsername) {
      return rejectWithValue("Oops!  Please try again; you must input your Username or Email Address.");
    }

    const payload = {
      username: forgotPasswordUsername || editUsername
    };

    return axios.post("/api/v1/auth/forgot-password", payload)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        if (error.response.data?.status === 401) {
          return rejectWithValue("Oops! Please try again. Either your username is incorrect.");
        }

        if (error.response && error.response.data && error.response.data?.status === 404) {
          return rejectWithValue("Oops!  Please try again; your Username or Email Address is incorrect.");
        }

        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.detail);
        }

        return rejectWithValue("Please try again later, we're experiencing a server error. We're working on it!");
      });
  }
);
