import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {},
  appChip: {
    display: "block",
    background: "none"
  },
  infoHeader: {
    fontWeight: 700
  },
  wrappSubtitle: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.common.white,
    padding: "20px, 43px",
    marginBottom: "2rem",
    cursor: "pointer",
    height: "60px"
  },
  subtitlePen: {
    color: theme.palette.common.white
  },
  title: {
    marginBottom: "15px",
    borderBottom: `1px solid ${theme.gray500Base}`,
    paddingBottom: "15px"
  },
  subtitle: {
    color: theme.palette.common.white,
    marginLeft: "43px",
    fontWeight: 700
  },
  dates: {
    fontWeight: "normal"
  },
  menuIcon: {
    color: theme.palette.common.white,
    position: "absolute",
    right: "140px"
  },
  containerToggleContent: {
    width: "70%"
  },
  wrappStepperButtons: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "right",
    marginTop: "40px"
  },
  backButton: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    border: `1px solid ${theme.gray500Base}`,
    padding: "10px 20px",
    width: "45px",
    height: "16px",
    background: theme.palette.common.white,
    borderRadius: "4px"

  },
  cancel: {
    marginRight: "5px",
    marginTop: "35px"
  }
}));

export default useStyles;
