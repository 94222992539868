import React, { ReactElement } from "react";

import {
  Route,
  Routes
} from "react-router-dom";

import AuthenticatedRoute from "src/components/routes/AuthenticatedRoute/AuthenticatedRoute";
import AddUser from "src/pages/AddUser/AddUser";
import ConfirmationForm from "src/pages/ConfirmationForm/ConfirmationForm";
import Dashboard from "src/pages/Dashboard/Dashboard";
import EditUser from "src/pages/EditUser/EditUser";
import ForgotPasswordForm from "src/pages/ForgotPasswordForm/ForgotPasswordForm";
import ListOperators from "src/pages/ListOperators/ListOperators";
import ListUsersRoles from "src/pages/ListUsersRoles/ListUsersRoles";
import LoginForm from "src/pages/LoginForm/LoginForm";
import OnboardOperator from "src/pages/OnboardOperator/OnboardOperator";
import OperatorsSuccess from "src/pages/OperatorsSuccess/OperatorsSuccess";
import Redirect404 from "src/pages/Redirect404/Redirect404";
import Redirect500 from "src/pages/Redirect500/Redirect500";
import ResetPasswordForm from "src/pages/ResetPasswordForm/ResetPasswordForm";
import Test from "src/pages/TestView";

import EditOperator from "./pages/EditOperator/EditOperator";
import EditRole from "./pages/EditRole/EditRole";
import ProfileSettingsRecentActivity from "./pages/ProfileSettingsRecentActivity/ProfileSettingsRecentActivity";
import UserAuditLog from "./pages/UserAuditLog/UserAuditLog";

const App: React.FC = (): ReactElement => {
  const routes = (
    <Routes>
      <Route
        index
        path={"/"}
        element={
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/test"}
        element={
          <AuthenticatedRoute>
            <Test />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/operators/onboard-operator"}
        element={
          <AuthenticatedRoute>
            <OnboardOperator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/operators/edit-operator/:id"}
        element={
          <AuthenticatedRoute>
            <EditOperator />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/operators/operators-list"}
        element={
          <AuthenticatedRoute>
            <ListOperators />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/operators/operators-success"}
        element={
          <AuthenticatedRoute>
            <OperatorsSuccess />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/users-roles"}
        element={
          <AuthenticatedRoute>
            <ListUsersRoles />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/users/user-audit-log"}
        element={
          <AuthenticatedRoute>
            <UserAuditLog />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/profile-settings"}
        element={
          <AuthenticatedRoute>
            <ProfileSettingsRecentActivity />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/users/add-user"}
        element={
          <AuthenticatedRoute>
            <AddUser />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/users/edit-user/:id"}
        element={
          <AuthenticatedRoute>
            <EditUser />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/roles/edit-role/:id"}
        element={
          <AuthenticatedRoute>
            <EditRole />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={"/login"}
        element={<LoginForm />}
      />
      <Route
        path={"/forgot-password"}
        element={<ForgotPasswordForm />}
      />
      <Route
        path={"/reset-password"}
        element={<ResetPasswordForm />}
      />
      <Route
        path={"/confirmation"}
        element={<ConfirmationForm />}
      />
      <Route
        path={"/404"}
        element={<Redirect404 />}
      />
      <Route
        path={"/500"}
        element={<Redirect500 />}
      />
      <Route
        path={"*"}
        element={<Redirect404 />}
      />
    </Routes>
  );

  return routes;
};

export default App;
