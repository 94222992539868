import React, { ReactElement, useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Link,
  Tab,
  Tabs,
  Typography, useTheme
} from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";

import { useFormik } from "formik";

import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { UserAssignments } from "src/models/UserAssignments";
import { AppBreadcrumb } from "src/models/app-breadcrumb";
import { useAppDispatch } from "src/store/hooks";
import {
  selectLoading,
  selectSelectedUserId,
  selectUser,
  selectUserAssignments,
  selectUserEmail,
  selectUserFirstName,
  selectUserLastName,
  selectUserPhone,
  selectUserUsername,
  setLoading,
  setSelectedUserId,
  setUserAssignments,
  setUserLoaded

} from "src/store/reducers/editUser/editUserSlice";
import { getUser } from "src/store/thunks/user/get/getUser";
import { getUserInfo } from "src/store/thunks/user/getInfo/getUserInfo";
import { updateUser } from "src/store/thunks/user/update/updateUser";

import UserInformation from "./UserInformation/UserInformation";
import UserRoles from "./UserRoles/UserRoles";
import { editUserFormValidation } from "./editUserFormValidation";

import useStyles from "./EditUser.styles";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Users"
  },
  {
    name: "Edit User",
    bold: true
  }
];

const EditUser: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const editUserFirstName = useSelector(selectUserFirstName);
  const editUserLastName = useSelector(selectUserLastName);
  const editUserUsername = useSelector(selectUserUsername);
  const editUserEmail = useSelector(selectUserEmail);
  const editUserPhone = useSelector(selectUserPhone);
  const editUserAssignments = useSelector(selectUserAssignments);
  const editUser = useSelector(selectUser);
  const selectedUserId = useSelector(selectSelectedUserId);

  const [tab, setTab] = useState(0);

  const [userRoles, setUserRoles] = useState([]);

  const loading = useSelector(selectLoading);

  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(setLoading(true));
    dispatch(updateUser()).then(() => {
      navigate("/users-roles");
    });
  };
  const formik = useFormik({
    initialValues: {
      editUserFirstName,
      editUserLastName,
      editUserUsername,
      editUserEmail,
      editUserPhone,
      editUserAssignments
    },
    enableReinitialize: true,
    validationSchema: editUserFormValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      handleSubmit();
    }
  });

  const { errors, touched, values } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean | number | number[]): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    const userId = pathname.split("/").pop();

    if (userId) {
      dispatch(setSelectedUserId(parseInt(userId)));
    }
  }, []);

  useEffect(() => {
    if (selectedUserId && !loading) {
      dispatch(getUser()).then((resp) => {
        if (resp.type.includes("fulfilled")) {
          dispatch(setUserLoaded(true));
        }
      });
    }
  }, [selectedUserId]);

  useEffect(() => {
    dispatch(getUserInfo()).then((resp) => {
      if (resp.type.includes("fulfilled")) {
        const roles = resp.payload?.assignments.map((assignment: { roles: Number[]; }) => assignment.roles) || [];
        setUserRoles(roles);
      }
    });
  }, []);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const handleAssignmentsChange = (assginments: UserAssignments[]) => {
    dispatch(setUserAssignments(assginments));
  };

  const handleAddAssignment = (
    fieldValue: UserAssignments
  ) => {
    handleAssignmentsChange([...editUserAssignments, fieldValue]);
  };

  return (
    <AppLayout>
      <Box className={classes.root}>
        <PageHeader title={"Edit User"} breadcrumbs={breadcrumbs} />
        <Link
          type={"button"}
          component={RouterLink}
          variant={"body2"}
          to={"/users-roles"}
          className={classes.linkBack}
        >
          <IconArrowLeft className={classes.backArrow} />
          <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText, textTransform: "none" }}>
            Go Back to Users
          </Typography>
        </Link>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label={<Typography variant={"h6"} style={{ textTransform: "none" }}>User Information</Typography>} />
          <Tab label={<Typography variant={"h6"} style={{ textTransform: "none" }}>Roles</Typography>} />
        </Tabs>
        <Typography mt={2} mb={2}>Here you can edit any user information. Changes will take effect when the save button is pressed.</Typography>
        {loading
          ? (
            <CircularProgress
              size={"8rem"}
              data-testid={"save-button-spinner"}
              className={classes.spinner}
            />
            )
          : (
            <>

              {tab === 0 && <UserInformation handleSubmit={formik.handleSubmit} values={values} handleUpdate={updateForm}
                touched={touched} errors={errors} editUser={editUser}
                userRoles={userRoles} />}
              {tab === 1 && <UserRoles errors={errors} handleAddAssignment={handleAddAssignment} editUserAssignments={editUserAssignments}
                handleAssignmentsChange={handleAssignmentsChange}/>}
              <Box className={classes.actionButtons} >
                <Link
                  type={"button"}
                  component={RouterLink}
                  variant={"body2"}
                  to={"/users-roles"}
                  className={classes.cancelButton}
                  data-testid={"cancel-button"}>
                  <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
                </Link>
                <Button
                  variant={"contained"}
                  size={"large"}
                  onClick={() => formik.handleSubmit()}
                  className={classes.saveButton}
                  data-testid={"save-button"}
                >
                  <Typography variant={"button"} style={{ color: theme.buttonColor }}>Save</Typography>
                </Button>
              </Box>
            </>
            )}
      </Box>
    </AppLayout>
  );
};

export default EditUser;
