import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { Store } from "../../../Store";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const getPermissions = createAsyncThunk(
  "getPermissions",
  (_, { dispatch, rejectWithValue, getState }) => {
    const state = getState() as Store;
    const operatorId = state.userInfo.user?.operator_id;
    return axios.get(`/api/v1/operators/${operatorId}/permissions`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Role Permissions";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
