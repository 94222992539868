import * as Yup from "yup";

import { emailRegex } from "src/utils/validateEmail/validateEmail";

export const editOperatorFormValidation = Yup.object().shape({
  editBusinessName: Yup.string().required("First Name is required."),
  editDbaName: Yup.string().required("DBA is required."),
  editDisplayName: Yup.string().required("Display Name is required."),
  editBusinessContact: Yup.string().required("Business Contact is required."),
  editBusinessEmail: Yup.string().matches(emailRegex, "Email is invalid.").required("Email is required."),
  editBillingAddress: Yup.string().required("Billing Address 1 is required."),
  editBillingAddress2: Yup.string().required("Billing Address 2 is required."),
  editBillingCity: Yup.string().required("Billing City is required."),
  editBillingState: Yup.string().required("Billing State is required.")
});
