import React, { useState } from "react";

import { Button, FormControlLabel, Grid, IconButton, Link, Switch, Typography, useTheme } from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { Application } from "src/models/Application";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectEditRole, setEditRolePermissions } from "src/store/reducers/editRole/editRoleSlice";
import { updateRole } from "src/store/thunks/role/update/updateRole";

import useStyles from "./Section.styles";

interface SectionProps {
    application: Application,
}

const Section: React.FC<SectionProps> = ({ application }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectEditRole);
  const [permissions, setPermissions] = useState<string[]>(role.permissions || []);
  const navigate = useNavigate();

  const appPermissions: string[] = [];
  application?.sections?.forEach((section) => {
    return section.features.forEach((feature) => {
      return feature.permissions.forEach((permission) => appPermissions.push(permission.ident));
    });
  });

  const [applicationAccessChecked, setApplicationAccessChecked] = useState(appPermissions.some(per => permissions.includes(per)));

  const handleUpdatePermissions = (checked: boolean, ident: string) => {
    const updatedPermissions = [...permissions];
    if (checked) {
      updatedPermissions.push(ident);
    } else {
      const index = updatedPermissions.findIndex((perm) => perm === ident);
      updatedPermissions.splice(index, 1);
    }
    setPermissions(updatedPermissions);
  };

  const handleUpdateFeature = (checked: boolean, featurePermissions: string[]) => {
    const updatedPermissions = [...permissions];
    if (checked) {
      featurePermissions.forEach((featurePerm: string) => {
        updatedPermissions.push(featurePerm);
      });
    } else {
      featurePermissions.forEach((featurePerm: string) => {
        const index = updatedPermissions.findIndex((perm) => perm === featurePerm);
        updatedPermissions.splice(index, 1);
      });
    }
    setPermissions(updatedPermissions);
  };

  const handleUpdateAppAccess = (checked: boolean, appPermissions: string[]) => {
    const updatedPermissions = [...permissions];
    if (checked) {
      setApplicationAccessChecked(true);
    } else {
      appPermissions.forEach((featurePerm: string) => {
        const index = updatedPermissions.findIndex((perm) => perm === featurePerm);
        index > -1 && updatedPermissions.splice(index, 1);
      });
      setApplicationAccessChecked(false);
    }
    setPermissions(updatedPermissions);
  };

  const handleSave = () => {
    Promise.resolve(dispatch(setEditRolePermissions(permissions))).then(() => {
      dispatch(updateRole());
      navigate("/users-roles?show=Roles");
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant={"subtitle1"} style={{ fontWeight: 500 }}>{application.name}</Typography>
        {open
          ? (
            <IconButton onClick={() => setOpen(false)}>
              <IconChevronUp data-testid={"menu-more-icon"} />
            </IconButton>
            )
          : (
            <IconButton onClick={() => setOpen(true)}>
              <IconChevronDown data-testid={"menu-less-icon"} />
            </IconButton>
            )}
      </Grid>
      <Grid item xs={12}>
        {open && (
        <Grid container className={classes.content}>
          <Grid item xs={12} className={classes.appAccess}>
            <Typography variant={"h6"}>Application Access</Typography>
            <FormControlLabel
              label={applicationAccessChecked ? <Typography variant={"subtitle2"}>Enabled</Typography> : <Typography variant={"subtitle2"}>Disabled</Typography>}
              labelPlacement={"start"}
              checked={applicationAccessChecked}
              control={<Switch onChange={(e) => handleUpdateAppAccess(e.target.checked, appPermissions)}/>}
            />
          </Grid>
          <Grid item xs={12}>
            {application.sections?.map(section => {
              return (
                <Grid container key={section.id} className={classes.section} >
                  <Grid item xs={12} className={classes.sectionTitle}><Typography variant={"h6"}>{section.name}</Typography></Grid>
                  {section?.features?.map((feature, index) => {
                    const featurePermissions = feature.permissions.map(perm => perm.ident);
                    const featureChecked = featurePermissions.some(per => permissions.includes(per));
                    return (
                      <Grid container key={feature.id} spacing={2}
                        className={classes.features}>
                        <Grid item xs={6} className={classes.feature}>
                          <Typography variant={"h7"}>{`${index + 1}. ${feature.name}`}</Typography>
                          <FormControlLabel
                            checked={featureChecked}
                            label={featureChecked ? <Typography variant={"subtitle2"}>Enabled</Typography> : <Typography variant={"subtitle2"}>Disabled</Typography>}
                            labelPlacement={"start"}
                            disabled={!applicationAccessChecked}
                            control={<Switch onChange={(e) => handleUpdateFeature(e.target.checked, featurePermissions)}/>}
                        />
                        </Grid>
                        <Grid item xs={6} >{feature.permissions.length > 1 && feature.permissions.map((permission, idx) => {
                          const permissionsChecked = permissions.indexOf(permission.ident) > -1;
                          return (<div key={permission.id} className={classes.permission}><Typography variant={"h7"}>{`${index + 1}.${idx + 1} ${permission.name}`}</Typography>
                            <FormControlLabel
                              label={permissionsChecked ? <Typography variant={"subtitle2"}>Enabled</Typography> : <Typography variant={"subtitle2"}>Disabled</Typography>}
                              labelPlacement={"start"}
                              disabled={!applicationAccessChecked}
                              checked={permissionsChecked}
                              control={<Switch onChange={(e) => handleUpdatePermissions(e.target.checked, permission.ident)}/>}
                        /></div>);
                        })}</Grid>
                      </Grid>
                    );
                  })}
                </Grid>);
            })}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={"contained"}
              size={"large"}
              data-testid={"reset-button"}
              className={classes.resetButton}
            >
              <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Reset Defaults</Typography>
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.wrappStepperButtons}>
            <Link
              type={"button"}
              component={RouterLink}
              variant={"body2"}
              to={"/users-roles?show=Roles"}
              >
              <Button
                className={classes.cancelButton}
                variant={"text"}
                data-testid={"cancel-button"}
            >
                <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
              </Button>
            </Link>
            <Button
              variant={"contained"}
              size={"large"}
              data-testid={"save-button"}
              className={classes.saveButton}
              onClick={() => handleSave()}
            >
              <Typography variant={"button"} style={{ color: theme.buttonColor }}>Save</Typography>
            </Button>
          </Grid>
        </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Section;
