
import React, { ReactElement } from "react";

import {
  Button,
  FormControlLabel,
  FormGroup, FormHelperText, Grid,
  Switch,
  Typography,
  useTheme
} from "@mui/material";

import { useFormik } from "formik";

import {
  selectCurrentStep,
  setCurrentStep
} from "src/store/reducers/onboardOperator/onboardOperatorSlice";

import { Application } from "../../../../../models/Application";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  selectApplications,
  selectLoading, selectOneAppSelected, toggleAppEnabled
} from "../../../../../store/reducers/applications/applicationsSlice";
import StepLayout from "../StepLayout/StepLayout";
import StepperButtons from "../StepLayout/StepperButtons/StepperButtons";

import { enabledAppsValidation } from "./enabledAppsValidation";

import useStyles from "./EnabledApps.styles";

const EnabledApps: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const applications = useAppSelector(selectApplications);
  const currentStep = useAppSelector(selectCurrentStep);
  const oneAppSelected = useAppSelector(selectOneAppSelected);

  const formik = useFormik({
    initialValues: {},
    validationSchema: enabledAppsValidation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: () => {
      if (!oneAppSelected) {
        return;
      }

      dispatch(setCurrentStep(currentStep + 1));
    }
  });

  const { submitCount } = formik;

  const updateForm = (
    fieldName: string,
    fieldValue?: string | boolean | number
  ): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleToggleAppAccess = (fieldName: string, appId: number, accessStatus: boolean) => {
    updateForm(fieldName, !accessStatus);
    dispatch(toggleAppEnabled(appId));
  };

  const loading = useAppSelector(selectLoading);

  const backwards = () => dispatch(setCurrentStep(currentStep - 1));
  const backwardsDouble = () => dispatch(setCurrentStep(currentStep - 2));

  return (
    <StepLayout>
      <form
        className={classes.formContainer}
        data-testid={"enabled-apps-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
      >
        <Grid container direction={"column"}>
          <Typography variant={"h6"} className={classes.subtitle}>
            Toggle each application the operator has access to below
          </Typography>
          <Grid className={classes.listCheckboxes}>
            <FormGroup className={classes.appicationList}>
              {applications.map((app: Application) => (
                <div key={app.id} className={classes.applicationListItem}>
                  <FormControlLabel
                    className={classes.formControlLabelRow}
                    label={<Typography variant={"subtitle2"}>{app.name}</Typography>}
                    labelPlacement={"start"}
                    control={
                      <Switch
                        name={app.name}
                        data-testid={`toggle-app-access-switch-${app.id}`}
                        className={classes.switch}
                        checked={app.enabled}
                        onChange={() => handleToggleAppAccess(app.name, app.id, app.enabled)}
                      />
                    }
                  />
                  {/*{*/}
                  {/*  touched &&*/}
                  {/*  touched[app.name] &&*/}
                  {/*  errors &&*/}
                  {/*  errors[app.name] &&*/}
                  {/*  Boolean(errors[app.name]) &&*/}
                  {/*  <FormHelperText error>{errors[app.name]}</FormHelperText>*/}
                  {/*}*/}
                </div>
              ))}
            </FormGroup>
            {!!submitCount && !oneAppSelected &&
              <FormHelperText error>You must select at least one application.</FormHelperText>
            }

          </Grid>
        </Grid>
        <Grid className={classes.wrappStepperButtons}>
          <Button
            className={classes.cancel}
            onClick={() => backwardsDouble()}
            variant={"text"}
            data-testid={"step-cancel-button"}
          >
            <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
          </Button>
          <StepperButtons
            loading={loading}
            backActionText={
              <div className={classes.backButton}>
                <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>
                  Back
                </Typography>
              </div>
            }
            forwardActionText={"Next"}
            backAction={backwards}
          />
        </Grid>
      </form>
    </StepLayout>
  );
};

export default EnabledApps;
