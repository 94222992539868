import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";
import { login } from "src/store/thunks/login/login";
import { logout } from "src/store/thunks/logout/logout";
import { setTimeout } from "src/utils/timeout/timeout";

import { AuthState } from "./AuthSliceState";
import { refreshToken } from "./refreshToken";

export const initialState: AuthState = {
  bearerToken: ((localStorage.getItem("bearerToken") || sessionStorage.getItem("bearerToken")) as string) ?? "",
  isAuthenticated: !!(localStorage.getItem("bearerToken") || sessionStorage.getItem("bearerToken")) as boolean,
  email: (localStorage.getItem("email") as string) ?? ""
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAuthenticated = true;
        state.bearerToken = action.payload.data.token;
        sessionStorage.setItem("isAuthenticated", "true");
        setTimeout();
        if (initialState.email.length) {
          localStorage.setItem("bearerToken", `${action.payload.data.token}`);
        } else {
          sessionStorage.setItem("bearerToken", `${action.payload.data.token}`);
        }
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.bearerToken = "";
        sessionStorage.setItem("isAuthenticated", "false");
        localStorage.removeItem("bearerToken");
        sessionStorage.removeItem("bearerToken");
      })
      .addCase(refreshToken.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAuthenticated = true;
        state.bearerToken = action.payload.data.token;
        sessionStorage.setItem("isAuthenticated", "true");
        setTimeout();
        if (initialState.email.length) {
          localStorage.setItem("bearerToken", `${action.payload.data.token}`);
        } else {
          sessionStorage.setItem("bearerToken", `${action.payload.data.token}`);
        }
      });
  }
});

export const selectIsAuthenticated = (state: RootState) => state.authentication.isAuthenticated;
export const selectBearerToken = (state: RootState) => state.authentication.bearerToken;
export const selectEmail = (state: RootState) => state.authentication.email;

export default authSlice.reducer;
