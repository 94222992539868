import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";

import { Role } from "src/models/Role";
import { updateRoleState } from "src/store/thunks/role/update/updateRoleState";
import { getCustomRoles } from "src/store/thunks/roles/getCustomRoles";
import { getDefaultRoles } from "src/store/thunks/roles/getDefaultRoles";

import { useAppDispatch } from "../../../store/hooks";

import useStyles from "./DisableRoleDialog.styles";

interface DisableRoleDialogProps {
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  role?: Role;
  paginationModel: GridPaginationModel;
  search: string;
  disableTable: string;
}
const DisableRoleDialog: React.FC<DisableRoleDialogProps> = ({ open, setOpen, role, paginationModel, search, disableTable }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const onConfirmDisable = () => {
    dispatch(updateRoleState(role)).then(resp => {
      if (!resp.type.includes("rejected")) {
        disableTable === "defaultRolesTable"
          ? dispatch(getDefaultRoles({
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            search
          }))
          : dispatch(getCustomRoles({
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            search
          }));
      }
      setOpen(false);
    }); ;
  };

  return (
    <Dialog open={open} className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}>
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.dialogTitle}>Confirm to {role?.is_active ? "Disable" : "Enable"} Role</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.normalText}>Are you sure you want to {role?.is_active ? "disable" : "enable"} {role?.name}?</Typography>
        {role?.is_active ? <Typography className={classes.italicText}>Users with this role will lose all access associated to it</Typography> : <></>}
      </DialogContent>
      <DialogActions className={classes.diablogActions}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => setOpen(false)}
          data-testid={"cancel-button"}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          data-testid={"confirm-button"}
          onClick={onConfirmDisable}
          className={classes.confirmButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>);
};

export default DisableRoleDialog;
