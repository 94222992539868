import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  formInput: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0 !important"
    }
  },
  dialog: {
    padding: "30px"
  },
  dialogPaper: {
    borderRadius: "20px"
  },
  dialogTitle: {
    display: "inline-flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.gray500Base}`,
    margin: "20px 25px 5px",
    padding: 0
  },
  wrappStepperButtons: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "right",
    marginTop: "40px"
  },
  createRoleButton: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    background: theme.palette.primary.contrastText,
    height: "36px",
    margin: "10px 20px"
  },
  cancelButton: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    padding: "0"
  },
  textInput: {
    "& .MuiInputBase-root": {
      height: "45px"
    }
  }
}));

export default useStyles;
