import React, { ReactElement, useState } from "react";

import { AppBar, Avatar, Divider, Grid, Link, ListItemIcon, Menu, MenuItem, MenuProps, Toolbar, Typography, alpha, styled, useTheme } from "@mui/material";
import { IconActivity, IconGridDots, IconLogout } from "@tabler/icons-react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import StoreSpaceLogo from "src/assets/storage-360-logo.svg";
import CustomImage from "src/components/ui/CustomImage/CustomImage";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectApplications } from "src/store/reducers/applications/applicationsSlice";
import { selectUserName, selectUserOperatorId } from "src/store/reducers/userInfo/userInfoSlice";
import { logout } from "src/store/thunks/logout/logout";

import useStyles from "./NavBar.styles";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "10px",
    marginTop: theme.spacing(5),
    width: "480px",
    height: "500px",
    right: "225px",
    padding: "44px 40px 65px 40px",
    top: "27px !important",
    left: "initial !important",
    color:
      theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

const NavBar: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const theme = useTheme();

  const [isBackgroundVisible, setIsBackgroundVisible] = useState(false);

  const dispatch = useAppDispatch();

  const userOperatorId = useAppSelector(selectUserOperatorId);

  const handleLogout = async() => {
    await dispatch(logout());
    navigate("/login");
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsBackgroundVisible(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setIsBackgroundVisible(false);
    setAnchorEl(null);
  };

  const userName = useAppSelector(selectUserName);
  const applications = useAppSelector(selectApplications);

  const getInitials = function(name: string) {
    const parts = name.split(" ");
    let initials = "";
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials;
  };

  const initialsName = getInitials(userName);

  // username dropdown script

  const [anchorElUsernameDrop, setAnchorElUsernameDrop] = React.useState<null | HTMLElement>(null);
  const openUsernameDrop = Boolean(anchorElUsernameDrop);
  const handleClickUsernameDrop = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUsernameDrop(event.currentTarget);
  };
  const handleCloseUsernameDrop = () => {
    setAnchorElUsernameDrop(null);
  };

  return (
    <>
      {isBackgroundVisible && <div className={classes.backgroundOverlay}></div>}
      <AppBar
        color={"primary"}
        position={"relative"}
        elevation={0}
        className={classes.root}
        data-testid={"navigation-bar"}
      >

        <Toolbar className={classes.toolbar}>
          <Grid item
            justifyContent={"left"}
            alignItems={"left"}>
            <img
              src={StoreSpaceLogo}
              alt={"Store Space Logo"}
              className={classes.image}
            />
          </Grid>
          <Grid
            item
            container
            direction={"row"}
            alignItems={"center"}
            className={classes.buttonContainer}
          >
            <Grid
              data-testid={"open-apps-menu-button"}
              aria-label={"open apps"}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}

              className={classes.menuButton}
            >
              <IconGridDots className={classes.menuButtonIcon} />
            </Grid>
            <StyledMenu
              id={"applications-menu-drop"}
              MenuListProps={{
                "aria-labelledby": "open-apps-menu-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className={classes.menuItemsGroup}
            >
              <div className={classes.textWithLine}>
                <Typography variant={"body1"} className={classes.textWithLineTitle}>
                  Currently Viewing
                </Typography>
                <span className={classes.horizontalLine}/>
              </div>
              <Grid
                item
                container
                className={classes.applicationsMenuContent}
              >

                {applications?.map((application) => {
                  const availableOperators = application?.operators?.map(
                    operator => operator.id
                  );
                  if (!availableOperators?.includes(userOperatorId)) {
                    return null;
                  }
                  return (
                    <Grid item xs={4} key={application.id}
                      className={classes.menuWrappElement}>
                      <div className={classes.textWithLine}>
                        <CustomImage imageAlt={application.name} imageName={application.ident} className={classes.iconAppMenu}/>
                        <Typography variant={"body3"} className={classes.horizontalLineText}>{application.name}</Typography>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
              <div className={classes.textWithLine}>
                <Typography variant={"body1"} className={classes.textWithLineTitle}>
                  S360 Apps
                </Typography>
                <span className={classes.horizontalLine}/>
              </div>
              <Grid
                item
                container
                className={classes.applicationsMenuContent}
              >
                {applications?.map((application) => {
                  const availableOperators = application?.operators?.map(
                    operator => operator.id
                  );
                  if (availableOperators?.includes(userOperatorId)) {
                    return null;
                  }
                  return (
                    <Grid item xs={4} key={application.id}
                      className={classes.menuWrappElement}>
                      <div className={classes.textWithLine}>
                        <CustomImage imageAlt={application.name} imageName={application.ident} className={classes.iconAppMenu}/>
                        <Typography variant={"body3"} className={classes.horizontalLineText}>{application.name}</Typography>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </StyledMenu>

            <Grid
              item
              className={classes.appBar}
              onClick={handleClickUsernameDrop}
            >

              <Toolbar className={classes.toolbar}>
                <Avatar className={classes.userAvatar}><Typography variant={"subtitle2"} style={{ color: theme.palette.common.white }}>{initialsName}</Typography></Avatar>
                <Typography variant={"body1"} className={classes.headerText} data-testid={"header-text"}>{userName}</Typography>
              </Toolbar>
            </Grid>
            <Menu
              anchorEl={anchorElUsernameDrop}
              id={"account-menu"}
              open={openUsernameDrop}
              onClose={handleCloseUsernameDrop}
              onClick={handleCloseUsernameDrop}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "none",
                  mt: 1.7,
                  boxShadow: "none",
                  width: "233px !important",
                  borderRadius: "10px",
                  pl: 2,
                  pr: 2,
                  color: theme.palette.primary.main,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  }
                }
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem className={classes.menuUsernameDropItemHead}>
                <Typography className={classes.textWithLineTitle}><span style={{ fontWeight: "bold" }}>Welcome, </span>{userName}</Typography>
                <Typography variant={"body2"} style={{ color: theme.gray600Dark }}>Administrator</Typography>
              </MenuItem>
              <Divider />
              {/* <Link
                type={"button"}
                component={RouterLink}
                variant={"body2"}
                to={"/profile-settings"}
                style={{ textDecoration: "none" }}>
                <MenuItem className={classes.menuUsernameDropItem}>
                  <ListItemIcon>
                    <IconSettings className={classes.menuUsernameDropIcon} />
                  </ListItemIcon>
                  <Typography variant={"body1"}>Profile Settings</Typography>
                </MenuItem>
              </Link> */}
              <Link
                type={"button"}
                component={RouterLink}
                variant={"body2"}
                to={"/profile-settings"}
                style={{ textDecoration: "none" }}>
                <MenuItem className={classes.menuUsernameDropItem}>
                  <ListItemIcon>
                    <IconActivity className={classes.menuUsernameDropIcon} />
                  </ListItemIcon>
                  <Typography variant={"body1"}>Recent Activity</Typography>
                </MenuItem>
              </Link>
              <MenuItem className={classes.menuUsernameDropItem} onClick={() => handleLogout()}>
                <ListItemIcon >
                  <IconLogout className={classes.menuUsernameDropIcon} />
                </ListItemIcon>
                <Typography variant={"body1"}>Logout</Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </>

  );
};

export default NavBar;
