import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Operators } from "src/models/Operators";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { deactivateOperator } from "src/store/thunks/operator/deactivate/deactivateOperator";
import { getOperators } from "src/store/thunks/operators/getAll/getOperators";

import { OperatorsAccessSliceState } from "./OperatorsSliceState";

export const initialState: OperatorsAccessSliceState = {
  loading: false,
  error: "",
  operators: {
    data: [],
    meta: {} as APIListMetaData,
    links: {
      first: null,
      last: null,
      prev: null,
      next: null
    }
  }
};

export const operatorsSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {
    setOperators: (state, action: PayloadAction<Operators[]>) => {
      state.operators.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOperators.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOperators.fulfilled, (state, action: PayloadAction<APIListResponse<Operators[]>>) => {
        state.operators = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.operators.data = action.payload.data;
        state.operators.meta = action.payload.meta;
        state.operators.links = action.payload.links;
        state.loading = false;
      })
      .addCase(getOperators.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      .addCase(deactivateOperator.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(deactivateOperator.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deactivateOperator.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectOperators = (state: RootState) => state.operators.operators;
export const selectLoading = (state: RootState) => state.operators.loading;
export const selectBusinessInformation = (state: RootState) => state.operators;

export const {
  setOperators
} = operatorsSlice.actions;

export default operatorsSlice.reducer;
