import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const removeUserFromRole = createAsyncThunk(
  "removeUserFromRole",
  async(userId: number, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const state = getState() as RootState;
    return axios.delete(`/api/v1/roles/${state.editRole.role.id}/users/${userId}`)
      .then(() => {
        dispatch(showSnackbar({
          message: "User removed successfully!",
          variant: "success"
        }));
      })
      .catch(() => {
        const errorMessage = "There was an error removing the user!";
        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
