import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { HttpProblem } from "src/models/http-problem";
import { logout as logoutThunk } from "src/store/thunks/logout/logout";
import clearSession from "src/utils/clearSession/clearSession";

import { LogoutSliceState } from "./LogoutSliceState";

export const initialState: LogoutSliceState = {
  error: sessionStorage.getItem("logoutError") ?? ""
};

export const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutError: (state, action: PayloadAction<HttpProblem>) => {
      state.error = action.payload.title;
      sessionStorage.setItem("logoutError", action.payload.title);
    },
    clearError: (state) => {
      state.error = "";
      sessionStorage.removeItem("logoutError");
    },
    logout: () => {
      clearSession();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logoutThunk.fulfilled, (state, action) => {
      if (action.payload === 200) {
        clearSession();
      }

      sessionStorage.setItem("logoutError", "There was an error logging you out");
    });
  }
});

export const {
  logout,
  logoutError,
  clearError
} = logoutSlice.actions;

export default logoutSlice.reducer;
