import React, { useEffect, useState } from "react";

import {
  Link,
  Tab,
  Tabs,
  Typography,
  useTheme
} from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";

import { Link as RouterLink, useParams } from "react-router-dom";

import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { AppBreadcrumb } from "src/models/app-breadcrumb";
import { useAppDispatch } from "src/store/hooks";
import { getRole } from "src/store/thunks/role/get/getRole";

import RoleInformation from "./RoleInformation/RoleInformation";
import RolePermissions from "./RolePermissions/RolePermissions";

import useStyles from "./EditRole.styles";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Roles"
  },
  {
    name: "Edit Role",
    bold: true
  }
];

const EditRole: React.FC = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const dispatch = useAppDispatch();

  const params = useParams();

  useEffect(() => {
    dispatch(getRole(params.id));
  }, []);

  function handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
    setTab(newValue);
  }
  return (
    <AppLayout>
      <PageHeader title={"Edit Role"} breadcrumbs={breadcrumbs} />
      <Link
        type={"button"}
        component={RouterLink}
        variant={"body2"}
        to={"/users-roles?show=Roles"}
        className={classes.linkBack}>
        <IconArrowLeft className={classes.backArrow} /> <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText, textTransform: "none" }}>Go Back to Manage Users & Roles</Typography>
      </Link>
      <>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label={<Typography variant={"h6"} style={{ textTransform: "none" }}>Role Information</Typography>} />
          <Tab label={<Typography variant={"h6"} style={{ textTransform: "none" }}>Permissions & App Access</Typography>} />
        </Tabs>
        {tab === 0 && <RoleInformation />}
        {tab === 1 && <RolePermissions />}
      </>
    </AppLayout>
  );
};

export default EditRole;
