import React, { useCallback, useEffect, useState } from "react";

import debounce from "lodash/debounce";

import { usePagination } from "src/components/hooks/usePagination";
import { Role } from "src/models/Role";
import { selectCustomRoles, selectLoading } from "src/store/reducers/customRoles/customRolesSlice";
import { selectDefaultRoles, selectLoading as selectDefaultRolesLoading } from "src/store/reducers/defaultRoles/defaultRolesSlice";
import { getCustomRoles } from "src/store/thunks/roles/getCustomRoles";
import { getDefaultRoles } from "src/store/thunks/roles/getDefaultRoles";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import AddRoleDialog from "./AddRoleDialog/AddRoleDialog";
import DeleteRoleDialog from "./DeleteRoleDialog/DeleteRoleDialog";
import DisableRoleDialog from "./DisableRoleDialog/DisableRoleDialog";
import RolesTable from "./RolesTable/RolesTable";

const ListRoles: React.FC = () => {
  const dispatch = useAppDispatch();

  const customRoles = useAppSelector(selectCustomRoles);
  const defaultRoles = useAppSelector(selectDefaultRoles);

  const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false);
  const [openDeleteRoleDialog, setOpenDeleteRoleDialog] = useState(false);
  const [openDisableRoleDialog, setOpenDisableRoleDialog] = useState(false);
  const [roleToDisable, setRoleToDisable] = useState<Role>();
  const [roleToDelete, setRoleToDelete] = useState<Role>();
  const [disableTable, setDisableTable] = useState("");

  const customRolesLoading = useAppSelector(selectLoading);

  const defaultRolesLoading = useAppSelector(selectDefaultRolesLoading);

  const {
    handleChangePaginationModel,
    paginationModel
  } = usePagination("defaultRolesTable");

  const {
    search,
    setSearch,
    handleChangePaginationModel: customHandleChangePaginationModel,
    paginationModel: customPaginationModel
  } = usePagination("customRolesTable");

  useEffect(() => {
    dispatch(getDefaultRoles({
      page: 1,
      pageSize: paginationModel.pageSize,
      search
    }));
  }, [paginationModel]);

  useEffect(() => {
    dispatch(getCustomRoles({
      page: customPaginationModel.page + 1,
      pageSize: customPaginationModel.pageSize,
      search
    }));
  }, [customPaginationModel]);

  const handleSearch = useCallback(debounce((searchTerm: string) => {
    customHandleChangePaginationModel({ page: 0, pageSize: customPaginationModel.pageSize });
    dispatch(getCustomRoles({
      page: 1,
      pageSize: customPaginationModel.pageSize,
      search: searchTerm
    }));
  }, 500), []);

  return (
    <>
      <RolesTable roles={defaultRoles?.data} tableName={"defaultRolesTable"} total={defaultRoles.meta?.total}
        setOpenAddRoleDialog={setOpenAddRoleDialog}
        setOpenDeleteRoleDialog={setOpenDeleteRoleDialog}
        setOpenDisableRoleDialog={setOpenDisableRoleDialog}
        setRoleToDelete={setRoleToDelete}
        setRoleToDisable={setRoleToDisable}
        loading={defaultRolesLoading}
        paginationModel={paginationModel}
        handleChangePaginationModel={handleChangePaginationModel}
        setDisableTable={setDisableTable}
      />
      <RolesTable roles={customRoles?.data} tableName={"customRolesTable"} total={customRoles.meta?.total}
        search={search}
        setSearch={setSearch}
        handleSearch={handleSearch}
        setOpenAddRoleDialog={setOpenAddRoleDialog}
        setOpenDeleteRoleDialog={setOpenDeleteRoleDialog}
        setOpenDisableRoleDialog={setOpenDisableRoleDialog}
        setRoleToDelete={setRoleToDelete}
        setRoleToDisable={setRoleToDisable}
        loading={customRolesLoading}
        paginationModel={customPaginationModel}
        handleChangePaginationModel={customHandleChangePaginationModel}
        setDisableTable={setDisableTable}
      />
      <AddRoleDialog open={openAddRoleDialog} setOpen={setOpenAddRoleDialog} paginationModel={customPaginationModel}
        search={search}/>
      <DeleteRoleDialog open={openDeleteRoleDialog} setOpen={setOpenDeleteRoleDialog} role={roleToDelete}
        paginationModel={customPaginationModel} search={search}/>
      <DisableRoleDialog open={openDisableRoleDialog} setOpen={setOpenDisableRoleDialog} role={roleToDisable}
        paginationModel={customPaginationModel} search={search} disableTable={disableTable}/>
    </>
  );
};

export default ListRoles;
