import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  boxContent: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "35px 0 20px 0"
  },
  titleTable: {
    display: "inline-block"
  },
  linkAdd: {
    textAlign: "center",
    lineHeight: "40px"
  },
  spinner: {
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center"
  },
  addButtonTable: {
    height: "2.5rem",
    textTransform: "uppercase",
    background: theme.palette.primary.contrastText
  },
  actionButton: {
    color: theme.palette.primary.main,
    width: "16px",
    height: "16px"
  },
  buttonDeactive: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    width: "35px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.25px"
  },
  dialogCard: {
    width: "450px !important",
    padding: "30px",
    height: "224px"
  },
  dialogActions: {
    width: "300px",
    backgroundColor: theme.palette.common.white,
    margin: "0 auto",
    justifyContent: "center"
  },
  dialogCancelBtn: {
    width: "120px",
    border: `1px solid ${theme.gray500Base}`,
    textTransform: "uppercase",
    padding: "15px",
    marginRight: "25px"
  },
  dialogConfirmBtn: {
    width: "120px",
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.gray500Base}`,
    textTransform: "uppercase",
    padding: "15px"
  },
  filterInput: {
    float: "right",
    width: "220px",
    height: "50px"
  },
  filterInputIcon: {
    color: theme.palette.primary.main,
    width: "24px"
  }
}));

export default useStyles;
