import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { User } from "src/models/User";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getUsers } from "src/store/thunks/users/getAll/getUsers";

import { UsersAccessSliceState } from "./UsersSliceState";

export const initialState: UsersAccessSliceState = {
  loading: false,
  error: "",
  users: {
    data: [],
    meta: {} as APIListMetaData,
    links: {
      first: null,
      last: null,
      prev: null,
      next: null
    }
  }
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<APIListResponse<User[]>>) => {
        state.users = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.users.data = action.payload.data;
        state.users.meta = action.payload.meta;
        state.users.links = action.payload.links;
        state.loading = false;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectUsers = (state: RootState) => state.users.users;

export const selectLoading = (state: RootState) => state.users.loading;
export const selectBusinessInformation = (state: RootState) => state.users;

export const {
  setUsers
} = usersSlice.actions;

export default usersSlice.reducer;
