import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserAudit } from "src/models/UserAudit";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getUserAudit } from "src/store/thunks/userAudit/getAll/getUserAudit";

import { UserAuditSliceState } from "./UserAuditSliceState";

export const initialState: UserAuditSliceState = {
  loading: false,
  error: "",
  userAudit: {
    data: [],
    meta: {} as APIListMetaData,
    links: {
      first: null,
      last: null,
      prev: null,
      next: null
    }
  }
};

export const userAuditSlice = createSlice({
  name: "userAudit",
  initialState,
  reducers: {
    setUserAudit: (state, action: PayloadAction<UserAudit[]>) => {
      state.userAudit.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAudit.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAudit.fulfilled, (state, action: PayloadAction<APIListResponse<UserAudit[]>>) => {
        state.userAudit = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.userAudit.data = action.payload.data;
        state.userAudit.meta = action.payload.meta;
        state.userAudit.links = action.payload.links;
        state.loading = false;
      })
      .addCase(getUserAudit.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectUserAudit = (state: RootState) => state.userAudit.userAudit;
export const selectLoading = (state: RootState) => state.userAudit.loading;
export const selectBusinessInformation = (state: RootState) => state.userAudit;

export const {
  setUserAudit
} = userAuditSlice.actions;

export default userAuditSlice.reducer;
