import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { CreateOperatorPayload } from "./CreateOperatorPayload";

export const createOperator = createAsyncThunk(
  "createOperator",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const businessInformation = state.businessInformation;
    const payload: CreateOperatorPayload = {

      business_name: businessInformation.companyName,
      business_contact: businessInformation.primaryContactBusinessContact,
      business_email_address: businessInformation.primaryContactEmail,
      dba_name: businessInformation.dba,
      display_name: businessInformation.displayName,
      billing_street_address: businessInformation.billingAddress.address,
      billing_street_address_2: businessInformation.billingAddress.addressTwo,
      billing_country: businessInformation.billingAddress.country,
      billing_city: businessInformation.billingAddress.city,
      billing_region: businessInformation.billingAddress.state,
      billing_postal_code: businessInformation.billingAddress.postalCode,
      billing_phone: businessInformation.primaryContactPhoneNumber,
      is_mailing_same_as_billing: businessInformation.mailingSameAsBilling,
      mailing_street_address: businessInformation.mailingAddress.address,
      mailing_street_address_2: businessInformation.mailingAddress.addressTwo,
      mailing_country: businessInformation.mailingAddress.country,
      mailing_city: businessInformation.mailingAddress.city,
      mailing_region: businessInformation.mailingAddress.state,
      mailing_postal_code: businessInformation.mailingAddress.postalCode,
      mailing_phone: businessInformation.primaryContactPhoneNumber
    };

    return axios.post("/api/v1/operators", payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Operator created successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.messages);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error creating the operator";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
