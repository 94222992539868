import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  boxContent: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "35px 0 35px"
  },
  titleTable: {
    display: "inline-block",
    marginTop: "15px"
  },
  linkAdd: {
    color: theme.palette.common.white,
    textAlign: "center",
    lineHeight: "40px"
  },
  addButtonTable: {
    height: "36px",
    textTransform: "uppercase",
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText
  },
  buttonActive: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    width: "70px",
    height: "20px",
    lineHeight: "20px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.25px"
  },
  buttonEdit: {
    color: theme.palette.primary.main,
    alignItems: "center"
  },
  filterInput: {
    float: "right",
    width: "220px",
    height: "50px"
  },
  filterInputIcon: {
    color: theme.palette.primary.main,
    width: "24px"
  }
}));

export default useStyles;
