
import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";

import { useAppDispatch } from "src/store/hooks";
import { showSnackbar } from "src/store/reducers/snackbar/snackbarSlice";
import { forgotPassword } from "src/store/thunks/forgotPassword/forgotPassword";

import useStyles from "./ResetPasswordDialog.styles";

interface ResetPasswordDialogProps {
    open: boolean;
    setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const handleResetPassword = async() => {
    await dispatch(forgotPassword())
      .then(resp => {
        if (resp.type.includes("fulfilled")) {
          dispatch(showSnackbar({
            message: "Reset password email sent successfully!",
            variant: "success"
          }));
        } else if (resp.payload === "Quota exceeded (3 requests/day)!aa") {
          dispatch(showSnackbar({
            message: "Reset password failed! Maximum 3 requests/day!",
            variant: "error"
          }));
        } else {
          dispatch(showSnackbar({
            message: resp.payload,
            variant: "error"
          }));
        }
        setOpen(false);
      });
  };

  return (
    <Dialog open={open} className={classes.dialog} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.dialogTitle} variant={"h5"}
        style={{ fontWeight: 500 }}>Reset Password</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant={"body2"}>Are you sure you want to reset this user’s password?</Typography>
      </DialogContent>
      <DialogActions className={classes.diablogActions}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => setOpen(false)}
          data-testid={"cancel-button"}
          className={classes.cancelButton}
            >
          <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          data-testid={"confirm-button"}
          onClick={() => handleResetPassword()}
          className={classes.confirmButton}
        >
          <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>Confirm</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
