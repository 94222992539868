/* istanbul ignore file */
import React, { ReactElement } from "react";

import AppLayout from "../layouts/AppLayout/AppLayout";

const TestView: React.FC = (): ReactElement => {
  return (
    <AppLayout>
      <h1>This is a test</h1>
    </AppLayout>
  );
};

export default TestView;
