import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  main: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("lg")]: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  root: {
    [theme.breakpoints.down("lg")]: {
      top: "0 !important",
      transform: "translateY(10%)",
      backgroundColor: theme.palette.background.default
    },
    [theme.breakpoints.down("md")]: {
      transform: "translateY(10%)"
    },
    maxWidth: "1045px",
    padding: "0",
    backgroundColor: `${theme.logoBackground}66`, // 66 represents transparency for hex
    borderRadius: "10px",
    minHeight: "450px"
  },
  containerFormInterior: {
    display: "flex",
    maxWidth: "332px",
    margin: "0 auto"
  },
  title: {
    textAlign: "center",
    marginBottom: "10px",
    color: theme.palette.primary.main

  },
  UndoOutlined: {
    paddingRight: "0.8rem"
  },
  linkBack: {
    textAlign: "center",
    textTransform: "none",
    color: theme.palette.primary.contrastText,
    marginTop: "10px",
    textDecoration: "none",
    display: "inline-flex"
  },
  form: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "95%"
    }
  },
  backIcon: {
    width: "16px",
    marginRight: "10px",
    paddingTop: "5px",
    color: theme.palette.primary.contrastText
  },
  checkCircle: {
    color: theme.checkCircle,
    width: "85px",
    height: "85px",
    marginBottom: "25px"
  },
  innerGrid: {
    marginTop: "2rem"
  },
  wrappImages: {
    display: "block",
    width: "100%",
    height: "100%"
  },
  imageLogo: {
    [theme.breakpoints.down("lg")]: {
      width: "486px",
      margin: "0 auto auto"
    },
    [theme.breakpoints.down("md")]: {
      width: "360px",
      marginTop: 0
    },
    [theme.breakpoints.down("sm")]: {
      width: "280px"
    },
    width: "386px",
    margin: "147px auto auto",
    display: "block"
  },
  imageClickIcon: {
    [theme.breakpoints.down("lg")]: {
      width: "110px",
      margin: "82px auto 82px auto"
    },
    [theme.breakpoints.down("md")]: {
      width: "100px",
      margin: "62px auto 62px auto"
    },
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      margin: "32px auto 32px auto"
    },
    color: theme.clickIconColor,
    width: "100px",
    height: "100px",
    margin: "62px auto auto",
    display: "block"
  },
  imageCoffeeIcon: {
    width: "18px",
    marginLeft: "15px"
  },
  forgotButton: {
    padding: "10px 20px",
    width: "100%",
    height: "36px",
    background: theme.palette.primary.contrastText,
    borderRadius: "4px",
    alignItems: "center",
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.buttonColor,
    marginTop: "30px"
  },
  forgotPaper: {
    borderRadius: "0 10px 10px 0",
    width: "100%",
    boxShadow: "none"
  },
  containerForm: {
    [theme.breakpoints.down("lg")]: {
      borderRadius: "10px",
      width: "70%"
    },
    [theme.breakpoints.down("md")]: {
      width: "86%"
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0"
    },
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: "0 10px 10px 0"
  },
  "@media (max-width: 1279px)": {
    forgotPaper: {
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0
      },
      borderRadius: "10px",
      width: "100%"
    },
    root: {
      width: "100%",
      height: "100%"
    },
    space: {
      [theme.breakpoints.down("lg")]: {
        minWidth: "100%",
        left: 0
      },
      position: "absolute",
      top: "0",
      left: "10%",
      transform: "initial",
      minWidth: "80%",
      borderRadius: "0"
    }
  },
  "@media (max-width: 768px)": {
    space: {
      position: "absolute",
      top: "0",
      left: "0",
      transform: "initial",
      borderRadius: "0"
    }
  },
  outerGrid: {
    padding: "2rem",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 4rem 100px 4rem"
    }
  },
  spinner: {
    color: theme.palette.common.white
  },
  field: {
    "&:-webkit-autofill, &-webkit-autofill:hover": {
      WebkitBoxShadow: "0 0 0 30px white inset !important"
    }
  }
}));

export default useStyles;
