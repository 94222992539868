
import React, { ReactElement } from "react";

import { Card, Grid, Typography } from "@mui/material";

import useStyles from "./AppCard.styles";

interface AppCardProps {
  name: string,
  icon: ReactElement,
  tablet?: boolean,
  desktop?: boolean
  link: string
}

const AppCard: React.FC<AppCardProps> = ({ name, icon, link }: AppCardProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={1}
      className={classes.root}
    >
      <Card
        variant={"outlined"}
        className={classes.card}
      >
        <a
          target={"_blank"}
          rel={"noreferrer"}
          href={link}
          className={classes.link}
        >
          <Grid
            item
            container
            direction={"column"}
            justifyContent={"start"}
          >
            <Grid
              item
              container
              className={classes.iconContainer}
            >
              <Grid
                item
                className={classes.emptyGrid}
                xs={3}
              />
              <Grid
                item
                container
                justifyContent={"center"}
                xs={6}
              >
                <span className={classes.appIcon}>{icon}</span>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              className={classes.linkText}
            >
              <Typography variant={"subtitle2"}>{name}</Typography>
            </Grid>
          </Grid>
        </a>
      </Card>
    </Grid>
  );
};

export default AppCard;
