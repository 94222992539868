import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

interface RegistrationData {
  id?: number,
  cancelledRegistration?: number,
  registrationExpired: boolean
}

export const updateUserRegistrationState = createAsyncThunk(
  "updateUserRegistrationState",
  async(data: RegistrationData, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    return axios.post(`/api/v1/users/${data?.id}/update-registration-state`, {
      cancelled: data.registrationExpired ? 0 : Number(!data?.cancelledRegistration)
    })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "User updated successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error when updating the user";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
