
import React, { ChangeEvent, Fragment, ReactElement } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";

import { useFormik } from "formik";

import MaskedInput from "src/components/ui/MaskedInput/MaskedInput";
import {
  copyBillingToMailing,
  selectLoading,
  selectMailingSameAsBilling,
  selectOperatorBillingAddress, selectOperatorBillingAddressTwo,
  selectOperatorBillingCity,
  selectOperatorBillingCountry,
  selectOperatorBillingPostalCode,
  selectOperatorBillingState,
  selectOperatorDBA,
  selectOperatorDisplayName,
  selectOperatorMailingAddress, selectOperatorMailingAddressTwo,
  selectOperatorMailingCity,
  selectOperatorMailingCountry,
  selectOperatorMailingPostalCode,
  selectOperatorMailingState,
  selectOperatorName,
  selectOperatorPhoneNumber,
  selectPrimaryContactBusinessContact,
  selectPrimaryContactEmail,
  setBillingAddress,
  setBillingAddressTwo,
  setBillingCity,
  setBillingCountry,
  setBillingPostalCode,
  setBillingState,
  setBusinessPhoneNumber,
  setDBA,
  setDisplayName,
  setMailingAddress,
  setMailingAddressTwo,
  setMailingCity,
  setMailingCountry,
  setMailingPostalCode,
  setMailingSameAsBilling,
  setMailingState,
  setOperatorName,
  setPrimaryContactBusinessContact,
  setPrimaryContactEmail
} from "src/store/reducers/businessInformation/businessInformationSlice";
import {
  selectCurrentStep,
  setCurrentStep
} from "src/store/reducers/onboardOperator/onboardOperatorSlice";

import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import states from "../../../../../utils/usStates";
import StepLayout from "../StepLayout/StepLayout";
import StepperButtons from "../StepLayout/StepperButtons/StepperButtons";

import { businessFormValidation } from "./businessFormValidation";

import useStyles from "./BusinessInformation.styles";

interface BusinessInformationProps {}

const BusinessInformation: React.FC<BusinessInformationProps> = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);
  const loading = useAppSelector(selectLoading);
  const mailingSameAsBilling = useAppSelector(selectMailingSameAsBilling);
  const companyName = useAppSelector(selectOperatorName);
  const dba = useAppSelector(selectOperatorDBA);
  const displayName = useAppSelector(selectOperatorDisplayName);
  const primaryContactPhoneNumber = useAppSelector(selectOperatorPhoneNumber);
  const primaryContactEmail = useAppSelector(selectPrimaryContactEmail);
  const primaryContactBusinessContact = useAppSelector(selectPrimaryContactBusinessContact);
  const billingAddress = useAppSelector(selectOperatorBillingAddress);
  const billingAddressTwo = useAppSelector(selectOperatorBillingAddressTwo);
  const billingCity = useAppSelector(selectOperatorBillingCity);
  const billingState = useAppSelector(selectOperatorBillingState);
  const billingCountry = useAppSelector(selectOperatorBillingCountry);
  const mailingCountry = useAppSelector(selectOperatorMailingCountry);
  const billingPostalCode = useAppSelector(selectOperatorBillingPostalCode);
  const mailingAddress = useAppSelector(selectOperatorMailingAddress);
  const mailingAddressTwo = useAppSelector(selectOperatorMailingAddressTwo);
  const mailingCity = useAppSelector(selectOperatorMailingCity);
  const mailingState = useAppSelector(selectOperatorMailingState);
  const mailingPostalCode = useAppSelector(selectOperatorMailingPostalCode);

  const formik = useFormik({
    initialValues: {
      companyName,
      dba,
      displayName,
      primaryContactPhoneNumber,
      primaryContactEmail,
      primaryContactBusinessContact,
      billingAddress,
      billingAddressTwo,
      billingCity,
      billingState,
      billingCountry,
      mailingCountry,
      billingPostalCode,
      mailingAddress,
      mailingAddressTwo,
      mailingCity,
      mailingState,
      mailingPostalCode,
      mailingSameAsBilling
    },
    validationSchema: businessFormValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      if (mailingSameAsBilling) {
        dispatch(copyBillingToMailing());
      }

      dispatch(setCurrentStep(3));
    }
  });

  const { errors, touched, values } = formik;

  const handleStepBack = () => dispatch(setCurrentStep(currentStep - 1));

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleOperatorNameOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setOperatorName(fieldValue));
  };

  const handlePrimaryContactEmailOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPrimaryContactEmail(fieldValue));
  };

  const handlePrimaryContactBusinessContactOnChange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setPrimaryContactBusinessContact(fieldValue));
  };

  const handleDBAOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setDBA(fieldValue));
  };

  const handleDisplayNameOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setDisplayName(fieldValue));
  };

  const handleBillingAddressOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setBillingAddress(fieldValue));
  };

  const handleBillingAddressTwoOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setBillingAddressTwo(fieldValue));
  };

  const handleBillingCityOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setBillingCity(fieldValue));
  };

  const handleBillingStateOnchange = (fieldName: string, fieldValue: string | any) => {
    updateForm(fieldName, fieldValue ?? "");
    dispatch(setBillingState(fieldValue ?? ""));
  };

  const handleBillingCountryOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setBillingCountry(fieldValue));
  };

  const handleMailingCountryOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setMailingCountry(fieldValue));
  };

  const handleBillingPostalCodeOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setBillingPostalCode(fieldValue));
  };

  const handlePhoneNumberOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setBusinessPhoneNumber(fieldValue));
  };

  const handleCheckbox = (fieldName: string, fieldValue: boolean) => {
    updateForm(fieldName, fieldValue);
    dispatch(setMailingSameAsBilling(fieldValue));
  };

  const handleMailingAddressOnchange = (fieldName: string, fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setMailingAddress(fieldValue));
  };

  const handleMailingAddressTwoOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setMailingAddressTwo(fieldValue));
  };

  const handleMailingCityOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setMailingCity(fieldValue));
  };

  const handleMailingStateOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setMailingState(fieldValue));
  };

  const handleMailingPostalCodeOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(setMailingPostalCode(fieldValue));
  };

  return (
    <StepLayout>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        data-testid={"business-information-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
      >
        <Box margin={1} className={classes.containerForm}>
          <Grid
            pr={1}
            pb={1}
            item
            md={12}
            >
            <Grid container justifyContent={"space-between"}>
              <Grid
                pr={1}
                pb={1}
                item
                md={4}
            >
                <InputLabel htmlFor={"business-name"}>
                  <Typography variant={"subtitle2"}>Operator Name</Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  id={"business-name"}
                  inputProps={{ "data-testid": "business-name" }}
                  name={"companyName"}
                  value={values.companyName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleOperatorNameOnchange("companyName", e.target.value)
                }
                  error={
                  touched && touched.companyName && errors && Boolean(errors.companyName)
                }
                  helperText={touched && touched.companyName && errors && errors.companyName}
              />
              </Grid>
              <Grid pl={1} pb={1} item
                md={4}>
                <InputLabel htmlFor={"business-dba"}>
                  <Typography variant={"subtitle2"}>DBA</Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  id={"business-dba"}
                  inputProps={{ "data-testid": "business-dba" }}
                  name={"dba"}
                  value={values.dba}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleDBAOnchange("dba", e.target.value)
                }
                  error={touched && touched.dba && errors && Boolean(errors.dba)}
                  helperText={touched && touched.dba && errors && errors.dba}
              />
              </Grid>
              <Grid pl={1} pb={1} item
                md={4}>
                <InputLabel htmlFor={"business-display-name"}>
                  <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>This is the preferred business name your employees will see when they log in</Typography>} arrow>
                    <Typography variant={"subtitle2"}>
                      Display Name
                    </Typography>
                  </Tooltip>
                </InputLabel>
                <TextField
                  fullWidth
                  id={"business-display-name"}
                  inputProps={{ "data-testid": "business-display-name" }}
                  name={"displayName"}
                  value={values.displayName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleDisplayNameOnchange("displayName", e.target.value)
                }
                  error={touched && touched.displayName && errors && Boolean(errors.displayName)}
                  helperText={touched && touched.displayName && errors && errors.displayName}
              />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            pr={1}
            pb={1}
            item
            md={12}
            >
            <Grid container justifyContent={"space-between"}>
              <Grid pb={1} item md={6}>
                <InputLabel htmlFor={"business-billing-address"}>
                  <Typography variant={"subtitle2"}>Billing Address 1</Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  id={"business-billing-address"}
                  inputProps={{ "data-testid": "business-billing-address" }}
                  name={"billingAddress"}
                  value={values.billingAddress}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleBillingAddressOnchange("billingAddress", e.target.value)
                }
                  error={
                  touched &&
                  touched.billingAddress &&
                  errors &&
                  Boolean(errors.billingAddress)
                }
                  helperText={
                  touched &&
                  touched.billingAddress &&
                  errors &&
                  errors.billingAddress
                }
              />
              </Grid>
              <Grid pl={2} item
                md={3}>
                <InputLabel htmlFor={"business-billing-city"}><Typography variant={"subtitle2"}>City</Typography></InputLabel>
                <TextField
                  fullWidth
                  id={"business-billing-city"}
                  inputProps={{ "data-testid": "business-billing-city" }}
                  name={"billingCity"}
                  value={values.billingCity}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleBillingCityOnchange("billingCity", e.target.value)
                  }
                  error={
                    touched &&
                    touched.billingCity &&
                    errors &&
                    Boolean(errors.billingCity)
                  }
                  helperText={
                    touched &&
                    touched.billingCity &&
                    errors &&
                    errors.billingCity
                  }
                />
              </Grid>
              <Grid pl={2} item md={3}>
                <InputLabel htmlFor={"business-billing-state"}><Typography variant={"subtitle2"}>State</Typography></InputLabel>
                <FormControl fullWidth variant={"outlined"}>
                  <Select
                    id={"business-billing-state"}
                    inputProps={{ "data-testid": "business-billing-state" }}
                    name={"billingState"}
                    native
                    value={values.billingState}
                    onChange={(e) => handleBillingStateOnchange("billingState", e.target.value)}
                    error={
                      touched &&
                      touched.billingState &&
                      errors &&
                      Boolean(errors.billingState)
                    }
                  >
                    <option value={""} disabled>Select a State...</option>
                    {states.map((state) => (
                      <option key={state.text} value={state.value}>
                        {state.value}
                      </option>
                    ))}
                  </Select>
                  {touched &&
                    touched.billingState &&
                    errors &&
                    errors.billingState &&
                    Boolean(errors.billingState) && (
                      <FormHelperText error>
                        {errors.billingState}
                      </FormHelperText>
                  )}
                </FormControl>
              </Grid>

            </Grid>
          </Grid>
          <Grid
            pr={1}
            pb={1}
            item
            md={12}
              >
            <Grid container justifyContent={"space-between"}>
              <Grid item pb={1} md={6}>
                <InputLabel htmlFor={"business-billing-address-two"}>
                  <Typography variant={"subtitle2"}>Billing Address 2</Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  id={"business-billing-address-two"}
                  data-testid={"business-billing-address-two"}
                  name={"billingAddressTwo"}
                  value={values.billingAddressTwo}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleBillingAddressTwoOnchange("billingAddressTwo", e.target.value)
                  }
                />
              </Grid>
              <Grid pl={2} pb={1} item
                md={3}>
                <InputLabel htmlFor={"business-billing-postal-code"}><Typography variant={"subtitle2"}>Zip/postal code</Typography></InputLabel>
                <TextField
                  className={classes.noArrow}
                  fullWidth
                  id={"business-billing-postal-code"}
                  inputProps={{ "data-testid": "business-billing-postal-code" }}
                  name={"billingPostalCode"}
                  type={"number"}
                  value={values.billingPostalCode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleBillingPostalCodeOnchange("billingPostalCode", e.target.value)
                    }
                  error={
                      touched &&
                      touched.billingPostalCode &&
                      errors &&
                      Boolean(errors.billingPostalCode)
                    }
                  helperText={
                      touched &&
                      touched.billingPostalCode &&
                      errors &&
                      errors.billingPostalCode
                    }
                  />
              </Grid>
              <Grid pl={2} item md={3}>
                <InputLabel htmlFor={"business-billing-country"}><Typography variant={"subtitle2"}>Billing Country</Typography></InputLabel>
                <FormControl fullWidth variant={"outlined"}>
                  <Select
                    id={"business-billing-country"}
                    inputProps={{ "data-testid": "business-billing-country" }}
                    name={"billingCountry"}
                    native
                    value={values.billingCountry}
                    error={
                      touched &&
                      touched.billingCountry &&
                      errors &&
                      Boolean(errors.billingCountry)
                    }
                    onChange={(e) => handleBillingCountryOnchange("billingCountry", e.target.value) }
                  >
                    <option value={""} disabled>Select a Country...</option>
                    <option key={"United States"} value={"United States"}>United States</option>
                    <option key={"Brazil"} value={"Brazil"}>Brazil</option>
                    <option key={"Turkey"} value={"Turkey"}>Turkey</option>
                    <option key={"Spain"} value={"Spain"}>Spain</option>
                  </Select>
                  {touched &&
                    touched.billingCountry &&
                    errors &&
                    errors.billingCountry &&
                    Boolean(errors.billingCountry) && (
                      <FormHelperText error>
                        {errors.billingCountry}
                      </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item pb={1} md={12}>
                <FormControlLabel
                  label={<Typography variant={"subtitle2"}>Mailing address the same as billing address</Typography>}
                  control={
                    <Checkbox
                      checked={values.mailingSameAsBilling}
                      name={"mailingSameAsBilling"}
                      color={"primary"}
                      data-testid={"checkbox-input"}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleCheckbox("mailingSameAsBilling", e.target.checked)
                      }
                    />
                  }
                />
              </Grid>
              {!mailingSameAsBilling
                ? (
                  <Fragment>
                    <Grid item pb={1} md={12}>
                      <InputLabel htmlFor={"business-mailing-address"}>
                        <Typography variant={"subtitle2"}>Mailing Address</Typography>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={"business-mailing-address"}
                        inputProps={{ "data-testid": "business-mailing-address" }}
                        name={"mailingAddress"}
                        value={values.mailingAddress}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleMailingAddressOnchange("mailingAddress", e.target.value)
                        }
                        error={
                          touched &&
                          touched.mailingAddress &&
                          errors &&
                          Boolean(errors.mailingAddress)
                        }
                        helperText={
                          touched &&
                          touched.mailingAddress &&
                          errors &&
                          errors.mailingAddress
                        }
                      />
                    </Grid>
                    <Grid pb={1} item md={12}>
                      <InputLabel htmlFor={"business-mailing-address-two"}>
                        <Typography variant={"subtitle2"}>Mailing Address Two</Typography>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={"business-mailing-address-two"}
                        inputProps={{ "data-testid": "business-mailing-address-two" }}
                        name={"mailingAddressTwo"}
                        value={values.mailingAddressTwo}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleMailingAddressTwoOnchange("mailingAddressTwo", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid container justifyContent={"space-between"}>
                      <Grid pb={1} pr={1} item
                        md={8}>
                        <InputLabel htmlFor={"business-mailing-city"}>
                          <Typography variant={"subtitle2"}>Mailing City</Typography>
                        </InputLabel>
                        <TextField
                          fullWidth
                          id={"business-mailing-city"}
                          inputProps={{ "data-testid": "business-mailing-city" }}
                          name={"mailingCity"}
                          value={values.mailingCity}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleMailingCityOnchange("mailingCity", e.target.value)
                          }
                          error={
                            touched &&
                            touched.mailingCity &&
                            errors &&
                            Boolean(errors.mailingCity)
                          }
                          helperText={
                            touched &&
                            touched.mailingCity &&
                            errors &&
                            errors.mailingCity
                          }
                        />
                      </Grid>
                      <Grid pb={1} pl={1} item
                        md={4}>
                        <InputLabel htmlFor={"business-mailing-state"}>
                          <Typography variant={"subtitle2"}>Mailing State</Typography>
                        </InputLabel>
                        <Select
                          fullWidth
                          id={"business-mailing-state"}
                          inputProps={{ "data-testid": "business-mailing-state" }}
                          name={"mailingState"}
                          value={values.mailingState}
                          variant={"outlined"}
                          onChange={(e) => handleMailingStateOnchange("mailingState", e.target.value) }
                          error={
                            touched &&
                            touched.mailingState &&
                            errors &&
                            Boolean(errors.mailingState)
                          }
                        >
                          <MenuItem value={""} disabled>
                            <span>Select a State...</span>
                          </MenuItem>
                          {states.map((state) => (
                            <MenuItem key={state.text} value={state.value}>
                              {state.value}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched &&
                          touched.mailingState &&
                          errors &&
                          errors.mailingAddress &&
                          Boolean(errors.mailingState) && (
                            <FormHelperText error>
                              {errors.mailingState}
                            </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                    <Grid pb={1} item md={6}>
                      <InputLabel htmlFor={"business-mailing-country"}><Typography variant={"subtitle2"}>Mailing Country</Typography></InputLabel>
                      <FormControl fullWidth variant={"outlined"}>
                        <Select
                          id={"business-mailing-country"}
                          inputProps={{ "data-testid": "business-mailing-country" }}
                          name={"mailingCountry"}
                          native
                          value={values.mailingCountry}
                          onChange={(e) => handleMailingCountryOnchange("mailingCountry", e.target.value) }

                          error={
                            touched &&
                            touched.mailingCountry &&
                            errors &&
                            Boolean(errors.mailingCountry)
                          }
                    >
                          <option value={""} disabled>Select a Country...</option>
                          <option key={"United States"} value={"United States"}>United States</option>
                          <option key={"Brazil"} value={"Brazil"}>Brazil</option>
                          <option key={"Turkey"} value={"Turkey"}>Turkey</option>
                          <option key={"Spain"} value={"Spain"}>Spain</option>
                        </Select>
                        {touched &&
                        touched.mailingCountry &&
                        errors &&
                        errors.mailingCountry &&
                        Boolean(errors.mailingCountry) && (
                          <FormHelperText error>
                            {errors.mailingCountry}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid pb={1} item md={12}>
                      <InputLabel htmlFor={"business-mailing-postal-code"}>
                        <Typography variant={"subtitle2"}>Mailing Zip</Typography>
                      </InputLabel>
                      <TextField
                        className={classes.noArrow}
                        fullWidth
                        id={"business-mailing-postal-code"}
                        inputProps={{ "data-testid": "business-mailing-postal-code" }}
                        name={"mailingPostalCode"}
                        type={"number"}
                        value={values.mailingPostalCode}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleMailingPostalCodeOnchange("mailingPostalCode", e.target.value)
                        }
                        error={
                          touched &&
                          touched.mailingPostalCode &&
                          errors &&
                          Boolean(errors.mailingPostalCode)
                        }
                        helperText={
                          touched &&
                          touched.mailingPostalCode &&
                          errors &&
                          errors.mailingPostalCode
                        }
                      />
                    </Grid>
                  </Fragment>
                  )
                : null}
            </Grid>
            <Grid container justifyContent={"space-between"} className={classes.containerFormMidle}>
              <Fragment>
                <Grid pb={1} pr={1} item
                  md={6}>
                  <InputLabel htmlFor={"primary-contact-business-contact"}><Typography variant={"subtitle2"}>Business Contact</Typography></InputLabel>
                  <TextField
                    fullWidth
                    id={"primary-contact-business-contact"}
                    inputProps={{ "data-testid": "primary-contact-business-contact" }}
                    name={"primaryContactBusinessContact"}
                    value={values.primaryContactBusinessContact}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePrimaryContactBusinessContactOnChange("primaryContactBusinessContact", e.target.value)
                  }
                    error={
                    touched &&
                    touched.primaryContactBusinessContact &&
                    errors &&
                    Boolean(errors.primaryContactBusinessContact)
                  }
                    helperText={
                    touched &&
                    touched.primaryContactBusinessContact &&
                    errors &&
                    errors.primaryContactBusinessContact
                  }
                />
                </Grid>
                <Grid pb={1} pl={1} item
                  md={6}
                  pr={1}>
                  <InputLabel htmlFor={"primary-contact-email"}>
                    <Typography variant={"subtitle2"}>Email Address</Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={"primary-contact-email"}
                    inputProps={{ "data-testid": "primary-contact-email" }}
                    name={"primaryContactEmail"}
                    value={values.primaryContactEmail}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePrimaryContactEmailOnChange("primaryContactEmail", e.target.value)
                    }
                    error={
                    touched &&
                    touched.primaryContactEmail &&
                    errors &&
                    Boolean(errors.primaryContactEmail)
                    }
                    helperText={
                    touched &&
                    touched.primaryContactEmail &&
                    errors &&
                    errors.primaryContactEmail
                    }
                  />
                </Grid>
                <Grid pb={1} item
                  md={8}>
                  <InputLabel htmlFor={"business-billing-phone"}><Typography variant={"subtitle2"}>Phone</Typography></InputLabel>
                  <MaskedInput
                    fullWidth
                    id={"business-billing-phone"}
                    dataTestId={"business-billing-phone"}
                    name={"primaryContactPhoneNumber"}
                    type={"tel"}
                    value={values.primaryContactPhoneNumber}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePhoneNumberOnchange("primaryContactPhoneNumber", e.target.value)
                  }
                    error={
                    touched &&
                    touched.primaryContactPhoneNumber &&
                    errors &&
                    Boolean(errors.primaryContactPhoneNumber)
                  }
                    helperText={
                    touched &&
                    touched.primaryContactPhoneNumber &&
                    errors &&
                    errors.primaryContactPhoneNumber
                  }
                  />
                </Grid>
              </Fragment>
            </Grid>

          </Grid>

        </Box>
        <StepperButtons
          loading={loading}
          forwardActionText={"Next"}
          backActionText={"Cancel"}
          backAction={handleStepBack}
          />
      </form>
    </StepLayout>
  );
};

export default BusinessInformation;
