import React, { ReactElement } from "react";

import { Grid, Paper } from "@mui/material";

import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { AppBreadcrumb } from "src/models/app-breadcrumb";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentStep } from "src/store/reducers/onboardOperator/onboardOperatorSlice";
import { selectUserOperatorName } from "src/store/reducers/userInfo/userInfoSlice";

import OnboardInformation from "./OnboardInformation/OnboardInformation";
import { OnboardOperatorForm } from "./OnboardOperatorForm/OnboardOperatorForm";

import useStyles from "./OnboardOperator.styles";

interface OnboardOperatorProps {}

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Operators"
  },
  {
    name: "Onboard Operators",
    bold: true
  }
];

export const displayText = (currentStep: number): { title: string, advisoryText: string } => {
  let title: string;
  let advisoryText: string;

  switch (currentStep) {
    case 1:
      title = "";
      advisoryText = "";
      break;
    case 2:
      title = "Business Information";
      advisoryText = "First we’ll need your business information, listed below filled out to create an operator " +
        "profile for your business. Please add the information listed below to the form:";
      break;
    case 3:
      title = "Application Access";
      advisoryText = "Toggle which applications this operator has access to based on their subscription.";
      break;
    case 4:
      title = "Operator Review";
      advisoryText = "Next we’ll need the admin information, listed below filled out to create an admin user for the " +
        "new operator. Please add the administrator information listed below to the form:";
      break;
    case 5:
      title = "Operator Review";
      advisoryText = "Review the information before processing a new operator account.";
      break;
    default:
      title = "";
      advisoryText = "";
  }

  return { title, advisoryText };
};

const OnboardOperator: React.FC<OnboardOperatorProps> = (): ReactElement => {
  const { classes } = useStyles();
  const currentStep = useAppSelector(selectCurrentStep);
  const userOperatorName = useAppSelector(selectUserOperatorName);

  return (
    <Grid
      data-testid={"onboard-operator-view"}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.root}
    >
      <AppLayout>
        <PageHeader
          title={`${userOperatorName} Portal`}
          breadcrumbs={breadcrumbs}
        />
        <Grid
          container
          item
          xs={12}
          style={{ marginBottom: "25px", marginTop: "25px" }}
        >
          {displayText(currentStep).title
            ? (
              <Paper elevation={4} style={{ width: "100%" }} className={classes.onboardDetailsForm}>
                <OnboardInformation
                  title={displayText(currentStep).title}
                  advisoryText={displayText(currentStep).advisoryText}
                />
              </Paper>)
            : ""}
        </Grid>
        <Grid
          container
          item
          xs={12}
        >
          <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", boxShadow: "none" }}>
            <Grid container item xs={12}>
              <OnboardOperatorForm />
            </Grid>
          </Paper>
        </Grid>
      </AppLayout>
    </Grid>
  );
};

export default OnboardOperator;
