import React from "react";

import { Grid } from "@mui/material";

import { useAppSelector } from "src/store/hooks";
import { selectCurrentStep } from "src/store/reducers/onboardOperator/onboardOperatorSlice";

import BusinessInformation from "./steps/BusinessInformation/BusinessInformation";
import EnabledApps from "./steps/EnabledApps/EnabledApps";
import GetStarted from "./steps/GetStarted/GetStarted";
import OnboardSummary from "./steps/OnboardSummary/OnboardSummary";

export function generateStep(currentStep: number) {
  switch (currentStep) {
    case 1:
      return <GetStarted />;
    case 2:
      return <BusinessInformation />;
    case 3:
      return <EnabledApps />;
    case 4:
      return <OnboardSummary />;
    default:
      return <GetStarted />;
  }
}

export const OnboardOperatorForm: React.FC = () => {
  const currentStep = useAppSelector(selectCurrentStep);

  return (
    <Grid item xs={12}>
      {generateStep(currentStep)}
    </Grid>
  );
};

export default OnboardOperatorForm;
