import { createAsyncThunk } from "@reduxjs/toolkit";

import { showSnackbar } from "src/store/reducers/snackbar/snackbarSlice";
import { RootState } from "src/store/rootStore";

import { axiosInstance as axios } from "../../../../api/axiosInstance";

type meta = {
  page: number,
  pageSize: number,
  search: string
}

export const getFacilities = createAsyncThunk(
  "getFacilities",
  async(meta: meta, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const state = getState() as RootState;
    const id = state.editUser.user?.operator_id;
    const pageSize = meta.pageSize;
    const page = meta.page;
    const filterName = meta.search && ("&filter[name]=" + encodeURIComponent(`${meta.search}`));

    return axios.get(`/api/v1/facilities?pageSize=${pageSize}&page=${page}&filter[operator_id]=${id}${filterName}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Facilities";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
