import React, { ChangeEvent } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputLabel, TextField, Typography, useTheme } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import { IconX } from "@tabler/icons-react";

import { useFormik } from "formik";

import { HexColorPicker } from "react-colorful";

import { selectRoleColor, selectRoleName, setRoleColor, setRoleName } from "src/store/reducers/newRole/newRoleSlice";
import { createRole } from "src/store/thunks/role/create/createRole";
import { getCustomRoles } from "src/store/thunks/roles/getCustomRoles";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { newRoleFormValidation } from "./newRoleValidation";

import useStyles from "./AddRoleDialog.styles";

interface AddRoleDialogProps {
    open: boolean;
    setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    search: string;
    paginationModel: GridPaginationModel;
}

const AddRoleDialog: React.FC<AddRoleDialogProps> = ({ open, setOpen, paginationModel, search }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const newRoleName = useAppSelector(selectRoleName);
  const newRoleColor = useAppSelector(selectRoleColor);
  const theme = useTheme();

  const handleSubmit = () => {
    dispatch(createRole())
      .then(resp => {
        if (!resp.type.includes("rejected")) {
          setOpen(false);
          dispatch(setRoleName(""));
          dispatch(setRoleColor(""));
          dispatch(getCustomRoles({
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            search
          }));
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      newRoleName,
      newRoleColor
    },
    validationSchema: newRoleFormValidation,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmit();
    }
  });

  const onClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const updateForm = (
    fieldName: string,
    fieldValue?: string | boolean
  ): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleNameChange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setRoleName(fieldValue));
  };

  const handleColorChange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setRoleColor(fieldValue));
  };

  const { errors, touched, values } = formik;

  return (
    <Dialog
      open={open} maxWidth={"md"}
      className={classes.dialog}
      classes={{ paper: classes.dialogPaper }}
      >
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.dialogTitle}>
        <Typography variant={"h2"} style={{ fontWeight: 500 }}>Add new role</Typography>
        <IconButton
          aria-label={"close"}
          onClick={onClose}
          size={"large"}
            >
          <IconX strokeWidth={4} style={{ color: theme.palette.primary.main }} />
        </IconButton>
      </DialogTitle>
      <form
        data-testid={"add-user-form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          formik.handleSubmit();
        }}
      >
        <DialogContent>
          <Grid container justifyContent={"space-between"}>
            <Grid
              className={classes.formInput}
              pr={2}
              pb={1}
              item
              md={6}
            >
              <InputLabel htmlFor={"new-role-name"}
              >
                <Typography variant={"subtitle2"}>Role Name</Typography>
              </InputLabel>
              <TextField
                className={classes.textInput}
                fullWidth
                id={"new-role-name"}
                data-testid={"new-role-name"}
                name={"newRoleName"}
                value={values.newRoleName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleNameChange("newRoleName", e.target.value)
              }
                error={
                touched &&
                touched.newRoleName &&
                errors &&
                Boolean(errors.newRoleName)
              }
                helperText={
                touched &&
                touched.newRoleName &&
                errors &&
                errors.newRoleName
              }
              />
            </Grid>
            <Grid
              className={classes.formInput}
              pr={2}
              pb={1}
              item
              md={6}
            >

              <InputLabel htmlFor={"new-role-color"}
              >
                <Typography variant={"subtitle2"}>Role Color</Typography>
              </InputLabel>
              <TextField
                className={classes.textInput}
                fullWidth
                id={"new-role-color"}
                data-testid={"new-role-color"}
                name={"newRoleColor"}
                value={values.newRoleColor}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleColorChange("newRoleColor", e.target.value)
                }
                error={
                  touched &&
                  touched.newRoleColor &&
                  errors &&
                  Boolean(errors.newRoleColor)
                }
                helperText={
                  touched &&
                  touched.newRoleColor &&
                  errors &&
                  errors.newRoleColor
                }
              />
              <HexColorPicker
                color={values.newRoleColor}
                onChange={(color: string) => handleColorChange("newRoleColor", color === "#NaNNaNNaN" ? theme.palette.common.white : color)}
                />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid className={classes.wrappStepperButtons}>
            <Button
              className={classes.cancelButton}
              onClick={onClose}
              variant={"text"}
              data-testid={"cancel-button"}
            >
              <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
            </Button>
            <Button
              variant={"contained"}
              size={"large"}
              type={"submit"}
              data-testid={"create-role-button"}
              className={classes.createRoleButton}
            >
              <Typography variant={"button"} style={{ color: theme.buttonColor }}>Create Role</Typography>
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddRoleDialog;
