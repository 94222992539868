import React, { ChangeEvent, ReactElement, useEffect } from "react";

import { Button, CircularProgress, Grid, InputLabel, Link, Paper, TextField, Typography, useTheme } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";

import { useFormik } from "formik";

import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import MaskedInput from "src/components/ui/MaskedInput/MaskedInput";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { UserAssignments } from "src/models/UserAssignments";
import { AppBreadcrumb } from "src/models/app-breadcrumb";
import { useAppDispatch } from "src/store/hooks";
import { createNewUserSlice, selectUserAssignments, selectUserEmail, selectUserFirstName, selectUserLastName, selectUserLoading, selectUserPhone, setUserAssignments, setUserEmail, setUserFirstName, setUserLastName, setUserPhone } from "src/store/reducers/newUser/newUserSlice";
import { createUser } from "src/store/thunks/user/create/createUser";

import ManageAssignments from "./ManageAssignments/ManageAssignments";
import { newUserFormValidation } from "./newUserFormValidation";

import useStyles from "./AddUser.styles";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Users"
  },
  {
    name: "Add User",
    bold: true
  }
];

const AddUser: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const subtitleText = `Create a user by inputing the details below and create role assignments. You can select any amount of facilities to the role you’ve assigned the user to access. `;

  const loading = useSelector(selectUserLoading);
  const newUserFirstName = useSelector(selectUserFirstName);
  const newUserLastName = useSelector(selectUserLastName);
  const newUserEmail = useSelector(selectUserEmail);
  const newUserPhone = useSelector(selectUserPhone);
  const newUserAssignments = useSelector(selectUserAssignments);

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(setUserFirstName(""));
      dispatch(setUserLastName(""));
      dispatch(setUserEmail(""));
      dispatch(setUserPhone(""));
      dispatch(setUserAssignments([]));
    };
  }, []);

  const handleSubmit = () => {
    dispatch(createUser())
      .then(resp => {
        if (!resp.type.includes("rejected")) {
          navigate("/users-roles");
          dispatch(createNewUserSlice());
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      newUserFirstName,
      newUserLastName,
      newUserEmail,
      newUserPhone,
      newUserAssignments
    },
    validationSchema: newUserFormValidation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      handleSubmit();
    }
  });

  const { errors, touched, values } = formik;

  const updateForm = (
    fieldName: string,
    fieldValue?: string | boolean | Array<UserAssignments>
  ): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleNewFirstNameOnchange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setUserFirstName(fieldValue));
  };

  const handleNewLastNameOnchange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setUserLastName(fieldValue));
  };

  const handleEmailOnchange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setUserEmail(fieldValue));
  };

  const handlePhoneOnchange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setUserPhone(fieldValue));
  };

  const handleAssignmentsChange = (assginments: UserAssignments[]) => {
    dispatch(setUserAssignments(assginments));
  };

  const handleAddAssignment = (
    fieldValue: UserAssignments
  ) => {
    handleAssignmentsChange([...newUserAssignments, fieldValue]);
  };

  return (
    <Grid
      data-testid={"new-user-operator-view"}
      item
      container
      className={classes.root}
    >
      <AppLayout>
        <PageHeader
          title={"Add User"}
          breadcrumbs={breadcrumbs}
        />

        <Link
          type={"button"}
          component={RouterLink}
          variant={"body2"}
          to={"/users-roles"}
          className={classes.linkBack}>
          {" "}
          <IconArrowLeft className={classes.backArrow} /> <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText, textTransform: "none" }}>Go Back to Manage Users & Roles</Typography>
        </Link>
        <Typography variant={"body1"} mb={2}>{subtitleText}</Typography>

        <form
          data-testid={"new-user-form"}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            formik.handleSubmit();
          }}
          >
          <Paper elevation={4} className={classes.wrappPaper}>
            <Grid item xs={12}>
              <Grid
                container
                item
                alignItems={"center"}
                className={classes.wrappSubtitle}>
                <Typography variant={"h6"}>
                  User Details
                </Typography>
              </Grid>
              <Grid className={classes.containerToggleContent}>
                <Grid item className={classes.userDetailsField}>
                  <InputLabel htmlFor={"new-user-first-name"}>
                    <Typography variant={"subtitle2"}>First Name</Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={"new-user-first-name"}
                    data-testid={"new-user-first-name"}
                    name={"newUserFirstName"}
                    value={values.newUserFirstName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleNewFirstNameOnchange("newUserFirstName", e.target.value)
                        }
                    error={
                            touched &&
                            touched.newUserFirstName &&
                            errors &&
                            Boolean(errors.newUserFirstName)
                        }
                    helperText={
                            touched &&
                            touched.newUserFirstName &&
                            errors &&
                            errors.newUserFirstName
                        }
                      />
                </Grid>
                <Grid item className={classes.userDetailsField}>
                  <InputLabel htmlFor={"new-user-last-name"}>
                    <Typography variant={"subtitle2"}>Last Name</Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={"new-user-last-name"}
                    data-testid={"new-user-last-name"}
                    name={"newUserLastName"}
                    value={values.newUserLastName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleNewLastNameOnchange("newUserLastName", e.target.value)
                        }
                    error={
                            touched &&
                            touched.newUserLastName &&
                            errors &&
                            Boolean(errors.newUserLastName)
                        }
                    helperText={
                            touched &&
                            touched.newUserLastName &&
                            errors &&
                            errors.newUserLastName
                        }
                      />
                </Grid>
                <Grid item className={classes.userDetailsField}>
                  <InputLabel htmlFor={"new-user-email"}>
                    <Typography variant={"subtitle2"}>Email</Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={"new-user-email"}
                    data-testid={"new-user-email"}
                    name={"newUserEmail"}
                    value={values.newUserEmail}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleEmailOnchange("newUserEmail", e.target.value)
                        }
                    error={
                            touched &&
                            touched.newUserEmail &&
                            errors &&
                            Boolean(errors.newUserEmail)
                        }
                    helperText={
                            touched &&
                            touched.newUserEmail &&
                            errors &&
                            errors.newUserEmail
                        }
                      />
                </Grid>
                <Grid item className={classes.userDetailsField}>
                  <InputLabel htmlFor={"new-user-phone"}>
                    <Typography variant={"subtitle2"}>Phone Number</Typography>
                  </InputLabel>
                  <MaskedInput
                    fullWidth
                    id={"new-user-phone"}
                    data-testid={"new-user-phone"}
                    name={"newUserPhone"}
                    type={"tel"}
                    value={values.newUserPhone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handlePhoneOnchange("newUserPhone", e.target.value)
                        }
                    error={
                          touched &&
                          touched.newUserPhone &&
                          errors &&
                          Boolean(errors.newUserPhone)
                        }
                    helperText={
                          touched &&
                          touched.newUserPhone &&
                          errors &&
                          errors.newUserPhone
                        }
                      />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={4} className={classes.wrappPaperDown}>
            <Grid
              item
              container
              alignItems={"center"}
              className={classes.wrappSubtitle}>
              <Typography variant={"h6"}>
                Role Assignments
              </Typography>
              <Button
                variant={"contained"}
                data-testid={"new-user-add-assignment-button"}
                className={classes.addAssignmentButton}
                onClick={() => handleAddAssignment({ roles: [], facilities: [] })}
                      >
                <Typography variant={"button"} style={{ color: theme.buttonColor }}> + Add Assignment</Typography>
              </Button>
            </Grid>
            <Grid className={classes.containerToggleContent}>
              {newUserAssignments.map((assignment, index) => (
                <ManageAssignments
                  key={`assignment ${index}`}
                  userAssignments={newUserAssignments}
                  handleAssignmentsChange={handleAssignmentsChange}
                  idx={index}
                  assignment={assignment}
                />
              ))}
            </Grid>
          </Paper>
          <Grid container mt={3} mb={3}
            className={classes.formInputGroup}>
            <Grid className={classes.formInput} pb={1}
              item>
              <Link
                type={"button"}
                component={RouterLink}
                variant={"body2"}
                to={"/users-roles"}
                className={classes.confirmCancelButton}
                data-testid={"confirm-cancel-button"}>
                {" "}
                <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
              </Link>
            </Grid>
            <Grid className={classes.formInput} pb={1}
              item>
              <Button
                variant={"contained"}
                size={"large"}
                type={"submit"}
                data-testid={"new-user-button"}
                className={classes.confirmButton}
              >
                {loading
                  ? <CircularProgress
                      size={"2rem"}
                      data-testid={"login-button-spinner"}
                      className={classes.spinner}/>
                  : <Typography variant={"button"} style={{ color: theme.buttonColor }}>Save</Typography>
                }
              </Button>
            </Grid>
          </Grid>
        </form>
      </AppLayout>
    </Grid>
  );
};

export default AddUser;
