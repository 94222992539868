import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  boxContent: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "35px 0 35px"
  },
  titleTable: {
    display: "inline-block",
    marginTop: "15px"
  },
  filterInput: {
    float: "right",
    width: "220px",
    height: "50px"
  },
  filterInputIcon: {
    color: theme.palette.primary.main
  }
}));

export default useStyles;
