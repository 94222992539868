import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";

import { User } from "src/models/User";
import { updateUserRegistrationState } from "src/store/thunks/user/update/updateUserRegistrationState";
import { getUsers } from "src/store/thunks/users/getAll/getUsers";

import { useAppDispatch } from "../../../store/hooks";

import useStyles from "./UpdateRegistrationDialog.styles";

interface UpdateRegistrationDialogProps {
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  user?: User
  page: number;
  pageSize: number;
  search: string;
  registrationExpired: boolean;
  sortOptions: GridSortModel
}

const UpdateRegistrationDialog: React.FC<UpdateRegistrationDialogProps> = ({ open, setOpen, user, page, pageSize, search, registrationExpired, sortOptions }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const onConfirmUpdateRegistration = async() => {
    const resp = await dispatch(updateUserRegistrationState({
      id: user?.id,
      cancelledRegistration: user?.cancelled_registration,
      registrationExpired
    }));
    if (!resp.type.includes("rejected")) {
      await dispatch(getUsers({
        page: page + 1,
        pageSize,
        search,
        sortOptions
      }));
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} className={classes.dialog} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.dialogTitle}>Confirm to {user?.cancelled_registration || registrationExpired ? "Resend Registration" : "Cancel Registration"}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.normalText}>Are you sure you want to {user?.cancelled_registration || registrationExpired ? "resend registration for" : "cancel registration for"} {user?.first_name}?</Typography>
      </DialogContent>
      <DialogActions className={classes.diablogActions}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => setOpen(false)}
          data-testid={"cancel-button"}
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          data-testid={"confirm-button"}
          onClick={onConfirmUpdateRegistration}
          className={classes.confirmButton}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateRegistrationDialog;
