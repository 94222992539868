import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { User } from "src/models/User";
import { UserAssignments } from "src/models/UserAssignments";
import { RootState } from "src/store/rootStore";
import { createUser } from "src/store/thunks/user/create/createUser";

import { NewUserSliceState } from "./NewUserSliceState";

export const initialState: NewUserSliceState = {
  user: {
    id: 1,
    operator_id: 1,
    first_name: "",
    last_name: "",
    username: "",
    phone: "",
    email: "",
    title: "",
    is_active: 1,
    finished_registration: 1,
    has_to_do_registration: 0,
    email_verified_at: "",
    created_at: "",
    updated_at: "",
    assignments: [],
    cancelled_registration: 0

  },
  summaryTabRoleAssignments: false,
  newUserError: "",
  newUserLoading: false
};

export const newUserSlice = createSlice({
  name: "newUser",
  initialState,
  reducers: {
    createNewUserSlice: (state: NewUserSliceState) => {
      Object.assign(state, initialState);
    },
    setUserFirstName: (state, action: PayloadAction<string>) => {
      state!.user!.first_name = action.payload;
    },
    setUserLastName: (state, action: PayloadAction<string>) => {
      state!.user!.last_name = action.payload;
    },
    setUserPhone: (state, action: PayloadAction<string>) => {
      state!.user!.phone = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state!.user!.email = action.payload;
    },
    setSummaryTabRoleAssignments: (state, action: PayloadAction<boolean>) => {
      state.summaryTabRoleAssignments = action.payload;
    },
    setUserOperatorId: (state, action: PayloadAction<number>) => {
      state!.user!.operator_id = action.payload;
    },
    setUserAssignments: (state, action: PayloadAction<Array<UserAssignments>>) => {
      state!.user!.assignments = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.newUserLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.newUserLoading = false;
        state.user = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.newUserLoading = false;
        state.newUserError = action.payload as string;
      });
  }
});

export const selectUser = (state: RootState) => state.newUser;
export const selectUserOperatorId = (state: RootState) => state.newUser?.user?.operator_id ?? "";
export const selectUserFirstName = (state: RootState) => state.newUser?.user?.first_name ?? "";
export const selectUserLastName = (state: RootState) => state.newUser?.user?.last_name ?? "";
export const selectUserPhone = (state: RootState) => state.newUser?.user?.phone ?? "";
export const selectUserEmail = (state: RootState) => state.newUser?.user?.email ?? "";
export const selectUserAssignments = (state: RootState) => state.newUser?.user?.assignments ?? [];
export const selectSummaryTabRoleAssignments = (state: RootState) => state.newUser.summaryTabRoleAssignments ?? false;
export const selectUserError = (state: RootState) => state.newUser?.newUserError ?? "";
export const selectUserLoading = (state: RootState) => state.newUser?.newUserLoading ?? false;

export const { createNewUserSlice, setUserFirstName, setUserLastName, setUserPhone, setUserEmail, setSummaryTabRoleAssignments, setUserAssignments } =
  newUserSlice.actions;

export default newUserSlice.reducer;
