import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { axiosInstance as axios } from "../../../api/axiosInstance";

export const logout = createAsyncThunk("auth/logout", async(_, { rejectWithValue }) =>
  await axios.get("/api/v1/auth/logout")
    .then(resp => resp.data)
    .catch((err: AxiosError) => {
      return rejectWithValue({
        type: err.name || "unknown_error",
        title: err.message || "Please try again later, we're experiencing a server error. We're working on it!",
        detail: err.config || null
      });
    })
);
