import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Operator } from "src/models/Operator";
import { User } from "src/models/User";
import { UserType } from "src/models/UserType";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getOperatorUserInfo } from "src/store/thunks/operator/get/getOperatorUserInfo";
import { getUserInfo } from "src/store/thunks/user/getInfo/getUserInfo";
import { getUserTypes } from "src/store/thunks/user/getTypes/getTypes";

import { UserInfoSliceState } from "./UserInfoSliceState";

export const initialState: UserInfoSliceState = {
  user: JSON?.parse(sessionStorage.getItem("user") as string || "\"\"") ?? null,
  userInfoError: "",
  userInfoLoading: false
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    resetUserInfoSlice: (state: UserInfoSliceState) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("user");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.userInfoLoading = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action: PayloadAction<User>) => {
        state.userInfoLoading = false;
        const clonedOperator = state.user?.operator;
        const clonedUserType = state.user?.user_type;
        state.user = action.payload;
        state.user.operator = clonedOperator;
        state.user.user_type = clonedUserType;
        sessionStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.userInfoLoading = false;
        state.userInfoError = action.payload as string;
      })
      .addCase(getUserTypes.pending, (state) => {
        state.userInfoLoading = true;
      })
      .addCase(getUserTypes.fulfilled, (state, action: PayloadAction<APIListResponse<UserType[]>>) => {
        const userType = action.payload.data.filter(type => type.id === state.user?.user_type_id);
        state!.user!.user_type = userType[0].ident;
        sessionStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(getUserTypes.rejected, (state, action) => {
        state.userInfoLoading = false;
        state.userInfoError = action.payload as string;
      })
      .addCase(getOperatorUserInfo.pending, (state) => {
        state.userInfoLoading = true;
      })
      .addCase(getOperatorUserInfo.fulfilled, (state, action: PayloadAction<Operator>) => {
        state!.user!.operator = action.payload;
        sessionStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(getOperatorUserInfo.rejected, (state, action) => {
        state.userInfoLoading = false;
        state.userInfoError = action.payload as string;
      });
  }
});

export const selectUser = (state: RootState) => state.userInfo.user;
export const selectUserName = (state: RootState) => state.userInfo.user
  ? `${state.userInfo.user.first_name} ${state.userInfo.user.last_name}`
  : "";
export const selectUserEmail = (state: RootState) => state.userInfo.user?.email ?? "";
export const selectUserTitle = (state: RootState) => state.userInfo.user?.title ?? "";
export const selectUserOperatorId = (state: RootState) => state.userInfo.user?.operator_id ?? 0;
export const selectUserInfoError = (state: RootState) => state.userInfo.userInfoError;
export const selectUserInfoLoading = (state: RootState) => state.userInfo.userInfoLoading;
export const selectUserType = (state: RootState) => state.userInfo.user?.user_type ?? "";
export const selectUserOperatorName = (state: RootState) => state.userInfo.user?.operator?.business_name ?? "";
export const selectUserOperatorDisplayName = (state: RootState) => state.userInfo.user?.operator?.display_name ?? "";

export const { resetUserInfoSlice } = userInfoSlice.actions;

export default userInfoSlice.reducer;
