import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { NavigationSliceState } from "./NavigationSliceState";

export const initialState: NavigationSliceState = {
  dropdownOpen: sessionStorage.getItem("dropdownOpen") ?? "",
  isDrawerShown: JSON.parse(sessionStorage.getItem("isDrawerShown") as string) ?? true
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggleOpenDropdown: (state, action: PayloadAction<string>) => {
      if (action.payload === state.dropdownOpen) {
        action.payload = "";
      }
      state.dropdownOpen = action.payload;
      sessionStorage.setItem("dropdownOpen", action.payload);
    },
    toggleDrawer: (state) => {
      const toggle = !state.isDrawerShown;

      state.isDrawerShown = toggle;
      sessionStorage.setItem("isDrawerShown", toggle.toString());
    },
    hideDrawer: (state) => {
      sessionStorage.setItem("isDrawerShown", "false");
      state.isDrawerShown = false;
    }
  }
});

export const selectDropdownOpen = (state: RootState) => state.navigation.dropdownOpen;
export const selectShowDrawer = (state: RootState) => state.navigation.isDrawerShown;

export const {
  hideDrawer,
  toggleDrawer,
  toggleOpenDropdown
} = navigationSlice.actions;

export default navigationSlice.reducer;
