
import React, { ChangeEvent, InputHTMLAttributes, ReactElement, useEffect } from "react";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid, IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { IconClick, IconEye, IconEyeOff } from "@tabler/icons-react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { setTimeout as setAppTimeout } from "src/utils/timeout/timeout";

import StoreSpaceLogo from "../../assets/storage-360-logo.svg";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../store/hooks";
import {
  clearError,
  emailOnchange,
  passwordOnchange,
  rememberUserOnchange,
  selectError,
  selectLoading,
  selectLoginEmail,
  selectPasswordShown,
  selectRememberUser,
  showPasswordOnchange
} from "../../store/reducers/login/loginSlice";
import { login } from "../../store/thunks/login/login";

import useStyles from "./LoginForm.styles";

const LoginForm: React.FC = (): ReactElement => {
  const error = useSelector(selectError);
  const isPasswordShown = useSelector(selectPasswordShown);
  const loading = useSelector(selectLoading);
  const rememberUser = useSelector(selectRememberUser);
  const email = useSelector(selectLoginEmail);

  const theme = useTheme();

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const previouslyLogin = JSON.parse(localStorage.getItem("previouslyLogIn") as string);

  const handleClearError = () => dispatch(clearError());
  const handleRememberUserOnChange = () => dispatch(rememberUserOnchange());
  const handleShowPasswordOnchange = () => dispatch(showPasswordOnchange());
  const handleEmailOnchange = (e: ChangeEvent<HTMLInputElement>) => dispatch(emailOnchange(e.target.value));
  const handlePasswordOnchange = (e: ChangeEvent<HTMLInputElement>) => dispatch(passwordOnchange(e.target.value));
  const handleLogin = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await dispatch(login())
      .then(resp => {
        if (resp.type.includes("fulfilled")) {
          localStorage.setItem("previouslyLogIn", JSON.stringify(true));
          navigate("/");
          setAppTimeout();
        }
      });
  };

  const handleClose = () => {
    handleClearError();
  };

  setTimeout(handleClose, 5000);

  const loginErrorDisplay = error
    ? (
      <Box width={"75%"} mt={4}>
        <Alert
          data-testid={"error-message"}
          severity={"error"}
          onClose={handleClose}
        >
          {error}
        </Alert>
      </Box>
      )
    : "";

  const toggleVisibilityButton = (
    <InputAdornment position={"end"}>
      <IconButton
        aria-label={"toggle password"}
        data-testid={"toggle-password-button"}
        onClick={() => handleShowPasswordOnchange()}
      >
        {!isPasswordShown
          ? <IconEye style={{ color: theme.palette.primary.main }} data-testid={"on-icon"}/>
          : <IconEyeOff style={{ color: theme.palette.primary.main }} data-testid={"off-icon"}/>
        }
      </IconButton>
    </InputAdornment>
  );

  /* istanbul ignore next */
  useEffect(() => {
    localStorage.removeItem("bearerToken");
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <main className={classes.main}>
        <Grid
          className={classes.root}
          container
          item
          justifyContent={"center"}
          >
          <Grid item lg={7}
            container
            justifyContent={"center"}
            alignItems={"center"}
            className={classes.containerImage}
          >
            <Box className={classes.wrappImages}>
              <img
                src={StoreSpaceLogo}
                alt={"Store Space Logo"}
                className={classes.imageLogo}
              />
              <IconClick className={classes.imageClickIcon} strokeWidth={1}/>
            </Box>
          </Grid>
          <Grid item lg={5}
            className={classes.containerForm}>
            <Grid
              item
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.outerGrid}
                >
              <Typography variant={"h2"} mb={"10px"}>
                {previouslyLogin ? "Welcome Back!" : "Welcome to Storage 360!"}
              </Typography>
              <Typography variant={"body2"} mb={"50px"}>
                Log in by using your email and password.
              </Typography>
              <form
                className={classes.form}
                data-testid={"login-form"}
                onSubmit={(e) => handleLogin(e)}
                >
                <Grid
                  container
                  item
                  direction={"column"}
                  className={classes.containerFormInterior}
                    >
                  <label htmlFor={"login-form-email"}><Typography variant={"subtitle2"}>Email</Typography></label>
                  <TextField
                    id={"login-form-email"}
                    className={classes.field}
                    data-testid={"login-form-email"}
                    aria-describedby={"email-text"}
                    margin={"dense"}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleEmailOnchange(e)}
                    autoComplete={"email"}
                    inputProps={{ "data-testid": "email-input" }}
                    value={email}
                  />
                  <label htmlFor={"login-form-password"} style={{ marginTop: "10px" }}><Typography variant={"subtitle2"}>Password</Typography></label>
                  <TextField
                    id={"login-form-password"}
                    className={classes.field}
                    data-testid={"login-form-password"}
                    type={isPasswordShown ? "text" : "password"}
                    aria-describedby={"password-text"}
                    margin={"dense"}
                    variant={"outlined"}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handlePasswordOnchange(e)}
                    autoComplete={"current-password"}
                    inputProps={{ "data-testid": "password-input" }}
                    InputProps={{ endAdornment: toggleVisibilityButton }}
                    />
                  <Box my={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={
                                { "data-testid": "remember-user-checkbox" } as InputHTMLAttributes<HTMLInputElement>
                              }
                          onChange={() => handleRememberUserOnChange()}
                          name={"rememberMe"}
                            // checked={rememberUser}
                          defaultChecked={rememberUser}
                            />
                          }
                      label={<Typography variant={"subtitle2"}>Remember me</Typography>}
                      />
                  </Box>
                  <Button
                    variant={"contained"}
                    size={"large"}
                    type={"submit"}
                    className={classes.loginButton}
                    data-testid={"login-button"}
                    >
                    {loading
                      ? <CircularProgress
                          size={"2rem"}
                          data-testid={"login-button-spinner"}
                          className={classes.spinner}
                          />
                      : <Typography variant={"button"} style={{ color: theme.buttonColor }}>Log in</Typography>
                      }
                  </Button>
                  <Box mt={1}>
                    <Link
                      type={"button"}
                      component={RouterLink}
                      variant={"body2"}
                      to={"/forgot-password"}
                      data-testid={"forgot-password-button"}
                      className={classes.linkForgot}
                        >
                      <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>Forgot Password?</Typography>
                    </Link>
                  </Box>
                </Grid>
              </form>
              {loginErrorDisplay}
            </Grid>
          </Grid>
        </Grid>
      </main>
    </StyledEngineProvider>
  );
};

export default LoginForm;
