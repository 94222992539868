import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";
import { forgotPassword } from "src/store/thunks/forgotPassword/forgotPassword";

import { ForgotPasswordSliceState } from "./ForgotPasswordSliceState";

export const initialState: ForgotPasswordSliceState = {
  loading: false,
  username: localStorage.getItem("username") ?? "",
  error: ""
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    usernameOnchange: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    clearError: (state) => {
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectError = (state: RootState) => state.forgotPassword.error;
export const selectLoading = (state: RootState) => state.forgotPassword.loading;
export const selectUsername = (state: RootState) => state.forgotPassword.username;

export const {
  usernameOnchange,
  clearError
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
