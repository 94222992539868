import * as Yup from "yup";

export enum validationErrors {
  min12 = "Must be a minimum of 12 characters",
  lowercase = "At least one lowercase letter",
  uppercase = "At least one uppercase letter",
  specialAndNumber = "One special character and one number"
}

export const resetPasswordFormValidation = Yup.object().shape({
  newPassword: Yup.string()
    .required("Required")
    .min(12, validationErrors.min12)
    .matches(/(.*[a-z].*)/, validationErrors.lowercase)
    .matches(/(.*[A-Z].*)/, validationErrors.uppercase)
    .matches(/^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/, validationErrors.specialAndNumber),
  newConfirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")])
    .required("Confirm new password is required")
});
