import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Role } from "src/models/Role";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getDefaultRoles } from "src/store/thunks/roles/getDefaultRoles";

import { DefaultRolesSliceState } from "./DefaultRolesSliceState";

export const initialState: DefaultRolesSliceState = {
  loading: false,
  error: "",
  defaultRoles: JSON.parse(sessionStorage.getItem("defaultRoles") as string) ?? []
};

export const defaultRolesSlice = createSlice({
  name: "defaultRoles",
  initialState,
  reducers: {
    setDefaultRoles: (state, action: PayloadAction<Role[]>) => {
      state.defaultRoles.data = action.payload;
      sessionStorage.setItem("defaultRoles", JSON.stringify(state.defaultRoles));
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDefaultRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDefaultRoles.fulfilled, (state, action: PayloadAction<APIListResponse<Role[]>>) => {
        state.defaultRoles = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.defaultRoles.data = action.payload.data.filter(role => role.is_deleted !== 1);
        state.defaultRoles.meta = action.payload.meta;
        sessionStorage.setItem("defaultRoles", JSON.stringify(state.defaultRoles));
        state.loading = false;
      })
      .addCase(getDefaultRoles.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectDefaultRoles = (state: RootState) => state.defaultRoles?.defaultRoles;
export const selectLoading = (state: RootState) => state.defaultRoles.loading;

export const {
  setDefaultRoles
} = defaultRolesSlice.actions;

export default defaultRolesSlice.reducer;
