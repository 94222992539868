import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Application } from "src/models/Application";
import { RootState } from "src/store/rootStore";
import { getOperator } from "src/store/thunks/operator/get/getOperator";
import { updateOperator } from "src/store/thunks/operator/update/updateOperator";

import { EditOperatorState } from "./editOperatorSliceState";

export const initialState: EditOperatorState = {
  operator: {
    business_name: "",
    business_contact: "",
    business_email_address: "",
    dba_name: "",
    display_name: "",
    billing_street_address: "",
    billing_street_address_2: "",
    billing_country: "",
    billing_city: "",
    billing_region: "",
    billing_postal_code: "",
    billing_phone: "",
    is_mailing_same_as_billing: false,
    mailing_street_address: "",
    mailing_street_address_2: "",
    mailing_country: "",
    mailing_city: "",
    mailing_region: "",
    mailing_postal_code: "",
    mailing_phone: "",
    applications: [],
    selectedApplications: []
  },
  summaryTab1: true,
  summaryTab2: false,
  summaryTab3: false,
  error: "",
  loading: false,
  selectedOperatorId: 0,
  operatorLoaded: false
};

export const editOperator = createSlice({
  name: "editOperator",
  initialState,
  reducers: {
    editNewOperatorSlice: (state: EditOperatorState) => {
      Object.assign(state, initialState);
    },
    businessNameOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.business_name = action.payload;
    },
    businessContactOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.business_contact = action.payload;
    },
    businessEmailAddressOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.business_email_address = action.payload;
    },
    dbaNameOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.dba_name = action.payload;
    },
    displayNameOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.display_name = action.payload;
    },
    billingStreetAddressOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.billing_street_address = action.payload;
    },
    billingStreetAddress2Onchange: (state, action: PayloadAction<string>) => {
      state!.operator!.billing_street_address_2 = action.payload;
    },
    billingCountryOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.billing_country = action.payload;
    },
    billingCityOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.billing_city = action.payload;
    },
    billingRegionOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.billing_region = action.payload;
    },
    billingPostalCodeOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.billing_postal_code = action.payload;
    },
    billingPhoneOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.billing_phone = action.payload;
    },
    isMailingSameAsBillingOnchange: (state, action: PayloadAction<boolean>) => {
      state!.operator!.is_mailing_same_as_billing = action.payload;
    },
    mailingStreetAddressOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.mailing_street_address = action.payload;
    },
    mailingStreetAddress2Onchange: (state, action: PayloadAction<string>) => {
      state!.operator!.mailing_street_address_2 = action.payload;
    },
    mailingCountryOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.mailing_country = action.payload;
    },
    mailingCityOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.mailing_city = action.payload;
    },
    mailingRegionOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.mailing_region = action.payload;
    },
    mailingPostalCodeOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.mailing_postal_code = action.payload;
    },
    mailingPhoneOnchange: (state, action: PayloadAction<string>) => {
      state!.operator!.mailing_phone = action.payload;
    },
    setSummaryTab1: (state, action: PayloadAction<boolean>) => {
      state.summaryTab1 = action.payload;
    },
    setSummaryTab2: (state, action: PayloadAction<boolean>) => {
      state.summaryTab2 = action.payload;
    },
    setSummaryTab3: (state, action: PayloadAction<boolean>) => {
      state.summaryTab3 = action.payload;
    },
    setSelectedOperatorId: (state, action: PayloadAction<number>) => {
      state.selectedOperatorId = action.payload;
    },
    setOperatorLoaded: (state, action: PayloadAction<boolean>) => {
      state.operatorLoaded = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedApplications: (state, action: PayloadAction<number[]>) => {
      state!.operator!.selectedApplications = action.payload;
    },
    editCopyBillingToMailing: (state: EditOperatorState) => {
      state!.operator!.mailing_street_address = state!.operator!.billing_street_address;
      state!.operator!.mailing_street_address_2 = state!.operator!.billing_street_address_2;
      state!.operator!.mailing_region = state!.operator!.billing_region;
      state!.operator!.mailing_city = state!.operator!.billing_city;
      state!.operator!.mailing_postal_code = state!.operator!.billing_postal_code;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateOperator.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOperator.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateOperator.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      .addCase(getOperator.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOperator.fulfilled, (state, action) => {
        state.loading = false;
        state.operator = action.payload;
        state!.operator!.selectedApplications = action.payload.applications.map((app: Application) => app.id);
      })
      .addCase(getOperator.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectLoading = (state: RootState) => state.editOperator?.loading;
export const selectSummaryTab1 = (state: RootState) => state.editOperator.summaryTab1;
export const selectSummaryTab2 = (state: RootState) => state.editOperator.summaryTab2;
export const selectSummaryTab3 = (state: RootState) => state.editOperator.summaryTab3;
export const selectSelectedOperatorId = (state: RootState) => state.editOperator.selectedOperatorId;
export const selectOperatorLoaded = (state: RootState) => state.editOperator.operatorLoaded;
export const selectOperatorBusinessName = (state: RootState) => state.editOperator?.operator?.business_name ?? "";
export const selectOperatorBusinessContact = (state: RootState) => state.editOperator?.operator?.business_contact ?? "";
export const selectOperatorBusinessEmailAddress = (state: RootState) => state.editOperator?.operator?.business_email_address ?? "";
export const selectOperatorDbaName = (state: RootState) => state.editOperator?.operator?.dba_name ?? "";
export const selectOperatorDisplayName = (state: RootState) => state.editOperator?.operator?.display_name ?? "";
export const selectOperatorBillingStreetAddress = (state: RootState) => state.editOperator?.operator?.billing_street_address ?? "";
export const selectOperatorBillingStreetAddress2 = (state: RootState) => state.editOperator?.operator?.billing_street_address_2 ?? "";
export const selectOperatorBillingCountry = (state: RootState) => state.editOperator?.operator?.billing_country ?? "";
export const selectOperatorBillingCity = (state: RootState) => state.editOperator?.operator?.billing_city ?? "";
export const selectOperatorBillingRegion = (state: RootState) => state.editOperator?.operator?.billing_region ?? "";
export const selectOperatorBillingPostalCode = (state: RootState) => state.editOperator?.operator?.billing_postal_code ?? "";
export const selectOperatorBillingPhone = (state: RootState) => state.editOperator?.operator?.billing_phone ?? "";
export const selectOperatorIsMailingSameAsBilling = (state: RootState) => state.editOperator?.operator?.is_mailing_same_as_billing ?? false;
export const selectOperatorMailingStreetAddress = (state: RootState) => state.editOperator?.operator?.mailing_street_address ?? "";
export const selectOperatorMailingStreetAddress2 = (state: RootState) => state.editOperator?.operator?.mailing_street_address_2 ?? "";
export const selectOperatorMailingCountry = (state: RootState) => state.editOperator?.operator?.mailing_country ?? "";
export const selectOperatorMailingCity = (state: RootState) => state.editOperator?.operator?.mailing_city ?? "";
export const selectOperatorMailingRegion = (state: RootState) => state.editOperator?.operator?.mailing_region ?? "";
export const selectOperatorMailingPostalCode = (state: RootState) => state.editOperator?.operator?.mailing_postal_code ?? "";
export const selectOperatorMailingPhone = (state: RootState) => state.editOperator?.operator?.mailing_phone ?? "";
export const selectOperatorApplications = (state: RootState) => state.editOperator?.operator?.applications ?? "";
export const selectOperatorSelectedApplications = (state: RootState) => state.editOperator?.operator?.selectedApplications ?? [];

export const {
  editNewOperatorSlice,
  businessNameOnchange,
  businessContactOnchange,
  businessEmailAddressOnchange,
  dbaNameOnchange,
  displayNameOnchange,
  billingStreetAddressOnchange,
  billingStreetAddress2Onchange,
  billingCountryOnchange,
  billingCityOnchange,
  billingRegionOnchange,
  billingPostalCodeOnchange,
  billingPhoneOnchange,
  isMailingSameAsBillingOnchange,
  mailingStreetAddressOnchange,
  mailingStreetAddress2Onchange,
  mailingCountryOnchange,
  mailingCityOnchange,
  mailingRegionOnchange,
  mailingPostalCodeOnchange,
  mailingPhoneOnchange,
  setSummaryTab1,
  setSummaryTab2,
  setSummaryTab3,
  setSelectedOperatorId,
  setOperatorLoaded,
  setLoading,
  setSelectedApplications,
  editCopyBillingToMailing
} = editOperator.actions;

export default editOperator.reducer;
