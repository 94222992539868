import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles<{ isDrawerShown: boolean }>({ name: "AppLayout" })((theme, { isDrawerShown }) => ({
  root: {
    height: "100%"
  },
  container: {
    padding: "0",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "90%"
    }
  },
  contentContainer: {
    width: "0rem",
    flexGrow: 1
  },
  mainContainer: {
    margin: "0 4.5rem"
  },
  navDrawer: {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    }),
    width: isDrawerShown ? "300px" : "85px",
    borderRight: `1px solid ${theme.sideNavigationBorder}`
  },

  chevron: {
    marginTop: "3px",
    color: theme.palette.primary.main,
    width: "24px",
    height: "24px"
  },
  closeDrawerButton: {
    display: "block !important",
    width: "28px",
    height: "28px",
    minHeight: "28px",
    backgroundColor: theme.palette.common.white,
    top: isDrawerShown ? "39px" : "36px",
    marginLeft: isDrawerShown ? "-42px" : "-34px",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short
    }),
    [theme.breakpoints.up("md")]: {
      display: "inline",
      right: "42px"
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: isDrawerShown ? "-78px" : "-74px",
      display: "none"
    },
    "&:hover": {
      backgroundColor: theme.graySecondary50
    }
  }
}));

export default useStyles;
