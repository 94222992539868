import { createAsyncThunk } from "@reduxjs/toolkit";

import { noAuthInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const validateToken = createAsyncThunk("validateToken", async(token: string | undefined, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;

  if (!token) {
    return rejectWithValue("Oops! Please try again. Your token is invalid!");
  }

  return axios
    .get(`/api/v1/users/retrieve-registration-data/${token}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      if (err.response.status === 500 && err.response?.data?.detail === "This user has already finished his registration") {
        return rejectWithValue("Registration finished.");
      }

      if (err.response.status === 500 && err.response?.data?.detail === "This user's registration has expired") {
        return rejectWithValue("Registration expired.");
      }

      const errorMessage = "There was an error fetching the current user information";

      dispatch(
        showSnackbar({
          message: errorMessage,
          variant: "error"
        })
      );

      return rejectWithValue(errorMessage);
    });
});
