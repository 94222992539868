import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Role } from "src/models/Role";
import { RootState } from "src/store/rootStore";
import { getRole } from "src/store/thunks/role/get/getRole";

import { EditRoleAccessSliceState } from "./EditRoleSliceState";

export const initialState: EditRoleAccessSliceState | Promise<any> = {
  loading: false,
  error: "",
  role: {} as Role
};

export const editRoleSlice = createSlice({
  name: "editRole",
  initialState,
  reducers: {
    setEditRole: (state, action: PayloadAction<Role>) => {
      state.role = action.payload;
    },
    setEditRoleName: (state, action: PayloadAction<string>) => {
      state!.role!.name = action.payload;
    },
    setEditRoleColor: (state, action: PayloadAction<string>) => {
      state!.role!.color = action.payload;
    },
    setEditRoleDescription: (state, action: PayloadAction<string>) => {
      state!.role!.description = action.payload;
    },
    setEditRolePermissions: (state, action: PayloadAction<string[]>) => {
      state!.role!.permissions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRole.fulfilled, (state, action: PayloadAction<Role>) => {
        state.role = action.payload;
        state.loading = false;
      })
      .addCase(getRole.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectEditRole = (state: RootState) => state.editRole?.role;
export const selectLoading = (state: RootState) => state.editRole?.loading;

export const {
  setEditRole,
  setEditRoleName,
  setEditRoleColor,
  setEditRoleDescription,
  setEditRolePermissions
} = editRoleSlice.actions;

export default editRoleSlice.reducer;
