import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      padding: "2rem 0"
    },
    padding: "0",
    backgroundColor: theme.blueGreyColor,
    borderRadius: "10px",
    height: "100%"
  },
  infoHeader: {
    fontWeight: "bold"
  },
  backButton: {
    display: "flex",
    flexWrap: "nowrap",
    minWidth: "6rem",
    justifyContent: "center"
  },
  operatorPaper: {
    borderRadius: "10px",
    maxWidth: "500px",
    minHeight: "500px",
    boxShadow: "none",
    margin: "50px auto 50px"
  },
  outerGrid: {
    padding: "2rem",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 50px 100px 50px"
    }
  },
  checkCircle: {
    color: theme.checkCircle,
    width: "75px !important",
    height: "75px !important",
    marginBottom: "19px"
  },
  titleText: {
    textAlign: "center",
    paddingTop: "19px"
  },
  linkBack: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "32px",
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.palette.primary.contrastText
  },
  linkBack2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "32px !important",
    textAlign: "center",
    textTransform: "uppercase"
  },
  undoOutlined: {
    width: "16px",
    height: "16px",
    paddingRight: "5px"
  }
}));

export default useStyles;
