
import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: "10px"
  },
  header: {
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: "10px",
    display: "inline-flex",
    padding: "18px 43px",
    height: "60px",
    justifyContent: "space-between"
  },
  content: {
    border: `1px solid ${theme.gray500Base}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: "10px",
    marginTop: "10px",
    padding: "18px 43px"
  },
  appAccess: {
    display: "inline-flex",
    justifyContent: "space-between",
    marginTop: "15px"
  },

  section: {
    borderTop: `1px solid ${theme.gray500Base}`,
    paddingTop: "15px"
  },
  sectionTitle: {
    marginBottom: "10px"
  },
  resetButton: {
    border: `1px solid ${theme.gray500Base}`,
    margin: "10px 0",
    height: "36px",
    background: theme.palette.common.white,
    boxShadow: "none",
    marginTop: "30px"
  },
  wrappStepperButtons: {
    display: "flex",
    justifyContent: "right",
    marginTop: "30px"
  },
  cancelButton: {
    margin: "10px 0",
    height: "36px",
    background: theme.palette.common.white,
    boxShadow: "none"
  },
  saveButton: {
    background: theme.palette.primary.contrastText,
    height: "36px",
    margin: "10px 0",
    boxShadow: "none",
    border: `1px solid ${theme.gray500Base}`
  },
  permission: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 50px 20px"
  },
  feature: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 50px 20px"
  },
  features: {
    alignItems: "baseline"
  }
}));

export default useStyles;
