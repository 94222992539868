import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { SnackbarPayload } from "src/models/snackbar-payload";
import { RootState } from "src/store/rootStore";

import { SnackbarSliceState } from "./SnackbarSliceState";

export const initialState: SnackbarSliceState = {
  message: "",
  variant: "success"
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<SnackbarPayload>) => {
      state.message = action.payload.message;
      state.variant = action.payload.variant ?? "success";
    },
    hideSnackbar: (state) => {
      state.message = "";
    }
  }
});

export const selectSnackbarMessage = (state: RootState) => state.snackbar.message;
export const selectSnackbarVariant = (state: RootState) => state.snackbar.variant;

export const {
  showSnackbar,
  hideSnackbar
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
