import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

type meta = {
  page: number,
  pageSize: number,
  search: string
}

export const getUserAudit = createAsyncThunk(
  "getUserAudit",
  async(meta: meta, thunkAPI) => {
    const { dispatch, getState, rejectWithValue } = thunkAPI;
    const pageSize = meta.pageSize;
    const page = meta.page;
    const state = getState() as RootState;
    const id = state.editUser.user?.operator_id;
    const filterName = meta.search && ("&orUserName=" + encodeURIComponent(`%${meta.search}%`));
    return axios.get(`/api/v1/operators/${id}/audit-actions?pageSize=${pageSize}&page=${page}${filterName}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the User Audit Log";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
