import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";
import { resetPassword } from "src/store/thunks/resetPassword/resetPassword";
import { validateToken } from "src/store/thunks/resetPassword/validateToken";

import { ResetPasswordSliceState } from "./ResetPasswordSliceState";

export const initialState: ResetPasswordSliceState = {
  loading: false,
  email: localStorage.getItem("email") ?? "",
  password: localStorage.getItem("password") ?? "",
  confirmPassword: localStorage.getItem("confirmPassword") ?? "",
  isPasswordShown: false,
  token: localStorage.getItem("token") ?? "",
  error: ""
};

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    emailOnchange: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    passwordOnchange: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    confirmPasswordOnchange: (state, action: PayloadAction<string>) => {
      state.confirmPassword = action.payload;
    },
    showPasswordOnchange: (state) => {
      state.isPasswordShown = !state.isPasswordShown;
    },
    tokenOnchange: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearError: (state) => {
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      .addCase(validateToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateToken.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectError = (state: RootState) => state.resetPassword.error;
export const selectLoading = (state: RootState) => state.resetPassword.loading;
export const selectEmail = (state: RootState) => state.resetPassword.email;
export const selectPassword = (state: RootState) => state.resetPassword.password;
export const selectConfirmPassword = (state: RootState) => state.resetPassword.confirmPassword;
export const selectPasswordShown = (state: RootState) => state.resetPassword.isPasswordShown;
export const selectToken = (state: RootState) => state.resetPassword.token;

export const {
  emailOnchange,
  passwordOnchange,
  confirmPasswordOnchange,
  showPasswordOnchange,
  tokenOnchange,
  clearError
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
