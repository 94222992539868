import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles<{ bold: boolean }>({ name: "AppBreadcrumbs" })((theme) => ({
  link: {
    textDecoration: "none",
    textTransform: "uppercase",
    "&:visited": {
      color: theme.gray400Light
    }
  }
}));

export default useStyles;
