import React from "react";

import {
  Button,
  Grid,
  Paper,
  Typography,
  useTheme
} from "@mui/material";

import { UserAssignments } from "src/models/UserAssignments";
import ManageAssignments from "src/pages/AddUser/ManageAssignments/ManageAssignments";

import useStyles from "./UserRoles.styles";

interface UserRolesProps {
    handleAddAssignment: Function;
    editUserAssignments: UserAssignments[];
    handleAssignmentsChange: Function;
    errors: any;
}

const UserRoles: React.FC<UserRolesProps> = ({ handleAddAssignment, editUserAssignments, handleAssignmentsChange, errors }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Paper elevation={4} className={classes.paper}>
      <Grid
        container
        alignItems={"center"}
       >
        <Grid item xs={12} className={classes.titleWrapper}>
          <Typography variant={"h6"}>
            Role Assignments
          </Typography>
          <Button
            variant={"contained"}
            data-testid={"new-user-add-assignment-button"}
            className={classes.addAssignmentButton}
            onClick={() => handleAddAssignment({ roles: [], facilities: [] })}
        >
            <Typography variant={"button"} style={{ color: theme.buttonColor }}> + Add Assignment</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} >
          {errors.editUserAssignments && <Typography className={classes.assignmentError}>{errors.editUserAssignments}</Typography>}
          {editUserAssignments.map((assignment: any, index: number) => (
            <ManageAssignments
              key={`assignment ${index}`}
              userAssignments={editUserAssignments}
              handleAssignmentsChange={handleAssignmentsChange}
              idx={index}
              assignment={assignment}
          />
          ))}
        </Grid>
      </Grid>
    </Paper>);
};

export default UserRoles;
