import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  boxContent: {
    backgroundColor: theme.palette.common.white,
    width: "100%"
  },
  title: {
    borderBottom: `1px solid ${theme.gray500Base}`,
    paddingBottom: "20px"
  },
  textInput: {

  },
  list: {
    border: `1px solid ${theme.gray500Base}`
  },
  wrappStepperButtons: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "right",
    marginTop: "20px"
  },
  saveButton: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    background: theme.palette.primary.contrastText,
    height: "36px",
    marginLeft: "30px"
  },
  cancelLink: {
    textDecoration: "none"
  },
  cancelButton: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    paddingTop: "10px"
  }
}));

export default useStyles;
