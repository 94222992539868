import React, { ReactElement } from "react";

import { Button, Grid, Paper, Typography, useTheme } from "@mui/material";

import { useAppDispatch } from "../../../../../store/hooks";
import { setCurrentStep } from "../../../../../store/reducers/onboardOperator/onboardOperatorSlice";

import useStyles from "./GetStarted.styles";

interface GetStartedProps {}

const GetStarted: React.FC<GetStartedProps> = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleGetStarted = () => dispatch(setCurrentStep(2));

  const title = "Onboard Operators";
  const advisoryText = "Set up operator business accounts to onboard new operators/clients to our service. Operators " +
          "must have provided the following information for each section:";

  return (
    <Grid
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.root}
    >
      <Paper elevation={4}
        className={classes.paper}
      >
        <Grid container justifyContent={"space-between"}>
          <Grid
            pr={1}
            pb={1}
            item
            md={8}
          >

            <Typography variant={"h1"} style={{ fontWeight: 400, marginBottom: "32px", marginTop: "40px" }}>{title}</Typography>
            <Typography variant={"h7"} my={2}>{advisoryText}</Typography>
          </Grid>
          <Grid
            pb={1}
            item
            md={4}
          >
            <Button
              size={"large"}
              className={classes.button}
              variant={"contained"}
              data-testid={"get-started-button"}
              onClick={() => handleGetStarted()}
            >
              <Typography variant={"button"} style={{ color: theme.buttonColor }}>Get Started</Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default GetStarted;
