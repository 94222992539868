import React, { ReactElement, useCallback, useEffect } from "react";

import {
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconSearch } from "@tabler/icons-react";
import { debounce } from "lodash";

import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";

import { usePagination } from "src/components/hooks/usePagination";
import TableReusablePagination from "src/components/ui/TableReusablePagination/TableReusablePagination";
import { Facility } from "src/models/Facility";
import { APIListResponse } from "src/models/responses/ListResponse";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectLoading } from "src/store/reducers/applications/applicationsSlice";
import { selectFacilities } from "src/store/reducers/facilities/facilitiesSlice";
import { getFacilities } from "src/store/thunks/facilities/getAll/getFacilities";

import useStyles from "./OperatorFacilities.style";

const OperatorFacilities: React.FC = (): ReactElement => {
  const { classes } = useStyles();

  const { page, rowsPerPage, search, setSearch, handleChangePage, handleChangeRowsPerPage } =
    usePagination("facilitiesTable");

  const facilities: APIListResponse<Facility[]> = useAppSelector(selectFacilities) || {};

  const dispatch = useAppDispatch();

  const loading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getFacilities({
      page: page + 1,
      pageSize: rowsPerPage,
      search
    }));
  }, [page, rowsPerPage]);

  const handleSearch = useCallback(debounce((searchTerm: string) => {
    handleChangePage(0);
    dispatch(getFacilities({
      page: 1,
      pageSize: rowsPerPage,
      search: searchTerm
    }));
  }, 500), []);

  return (
    <Grid className={classes.tabPanelGrid}>
      <Typography variant={"body1"} className={classes.subtitle}>
        Please note that these facilities shown are being managed in the S360 Property Management System.
      </Typography>
      <Paper elevation={4} className={classes.paper}>
        <Grid container item xs={12}
          style={{ padding: "0 25px 25px" }}>
          <Box className={classes.boxContent}>
            <Typography variant={"h6"} className={classes.titleTable}>
              Facilities
            </Typography>
            <TextField
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
                handleSearch(event.target.value);
              }}
              placeholder={"Search"}
              className={classes.filterInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconSearch className={classes.filterInputIcon} />
                  </InputAdornment>
                )
              }}
                      />
          </Box>
          {loading
            ? (
              <Grid item xs={12} className={classes.spinner}>
                <CircularProgress size={"8rem"} data-testid={"facilities-spinner"} />
              </Grid>
              )
            : (
              <TableContainer component={Paper} style={{ padding: "0 5px 5px", boxShadow: "none" }}>
                <Table sx={{ minWidth: 500 }} aria-label={"custom pagination table"}>
                  <TableHead>
                    <TableRow>
                      <TableCell align={"left"}>
                        <Typography variant={"body4"}>Store Name</Typography>
                      </TableCell>
                      <TableCell align={"left"}>
                        <Typography variant={"body4"}>Facility ID</Typography>
                      </TableCell>
                      <TableCell align={"left"}>
                        <Typography variant={"body4"}>Address</Typography>
                      </TableCell>
                      <TableCell align={"left"}>
                        <Typography variant={"body4"}>Phone</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {facilities.data?.map((row, index) => {
                      return (
                        <React.Fragment key={row.name + index}>
                          <TableRow>
                            <TableCell align={"left"}>
                              <Typography variant={"body4"}>{row.name}</Typography>
                            </TableCell>
                            <TableCell align={"left"}>
                              <Typography variant={"body4"}>{row.facility_id}</Typography>
                            </TableCell>
                            <TableCell align={"left"}>
                              <Typography variant={"body4"}>{row.address}</Typography>
                            </TableCell>
                            <TableCell align={"left"}>
                              <Typography variant={"body4"}>
                                <PatternFormat
                                  format={"(###) ###-####"}
                                  mask={"_"}
                                  value={row.phone}
                                  displayType={"text"}
                                        />
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
                <Grid item xs={12}>
                  <TableReusablePagination
                    count={facilities.meta?.total || 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={(_event, page) => handleChangePage(page)}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    component={"div"}
                          />
                </Grid>
              </TableContainer>
              )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default OperatorFacilities;
