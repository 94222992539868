import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";
import { createOperator } from "src/store/thunks/operator/create/createOperator";
import isValidEmail from "src/utils/validateEmail/validateEmail";

import { BusinessInformationSliceState } from "./BusinessInformationSliceState";

export const initialState: BusinessInformationSliceState = {
  operatorId: undefined,
  loading: false,
  error: "",
  companyName: sessionStorage.getItem("companyName") ?? "",
  dba: sessionStorage.getItem("dba") ?? "",
  displayName: sessionStorage.getItem("displayName") ?? "",
  primaryContactPhoneNumber: sessionStorage.getItem("primaryContactPhoneNumber") ?? "",
  primaryContactEmail: sessionStorage.getItem("primaryContactEmail") ?? "",
  primaryContactBusinessContact: sessionStorage.getItem("primaryContactBusinessContact") ?? "",
  mailingSameAsBilling: JSON.parse(sessionStorage.getItem("mailingSameAsBilling") as string) ?? true,
  billingAddress: {
    address: sessionStorage.getItem("billingAddress") ?? "",
    addressTwo: sessionStorage.getItem("billingAddressTwo") ?? "",
    city: sessionStorage.getItem("billingCity") ?? "",
    state: sessionStorage.getItem("billingState") ?? "",
    country: sessionStorage.getItem("billingCountry") ?? "",
    postalCode: sessionStorage.getItem("billingPostalCode") ?? "",
    type: "billing"
  },
  mailingAddress: {
    address: sessionStorage.getItem("mailingAddress") ?? "",
    addressTwo: sessionStorage.getItem("mailingAddressTwo") ?? "",
    city: sessionStorage.getItem("mailingCity") ?? "",
    state: sessionStorage.getItem("mailingState") ?? "",
    country: sessionStorage.getItem("mailingCountry") ?? "",
    postalCode: sessionStorage.getItem("mailingPostalCode") ?? "",
    type: "shipping"
  }
};

export const businessSlice = createSlice({
  name: "businessInformation",
  initialState,
  reducers: {
    setOperatorId: (state: BusinessInformationSliceState, action: PayloadAction<number>) => {
      state.operatorId = action.payload;
    },
    setLoading: (state: BusinessInformationSliceState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setOperatorName: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.companyName = action.payload;
      sessionStorage.setItem("companyName", state.companyName);
    },
    setDBA: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.dba = action.payload;
      sessionStorage.setItem("dba", state.dba);
    },
    setDisplayName: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.displayName = action.payload;
      sessionStorage.setItem("displayName", state.displayName);
    },
    setBillingAddress: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.billingAddress.address = action.payload;
      sessionStorage.setItem("billingAddress", state.billingAddress.address);
    },
    setBillingAddressTwo: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.billingAddress.addressTwo = action.payload;
      sessionStorage.setItem("billingAddressTwo", state.billingAddress.addressTwo);
    },
    setBillingCity: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.billingAddress.city = action.payload;
      sessionStorage.setItem("billingCity", state.billingAddress.city);
    },
    setBillingState: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.billingAddress.state = action.payload;
      sessionStorage.setItem("billingState", state.billingAddress.state);
    },
    setBillingCountry: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.billingAddress.country = action.payload;
      sessionStorage.setItem("billingCountry", state.billingAddress.country);
    },
    setBillingPostalCode: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.billingAddress.postalCode = action.payload;
      sessionStorage.setItem("billingPostalCode", state.billingAddress.postalCode);
    },
    setBusinessPhoneNumber: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.primaryContactPhoneNumber = action.payload;
      sessionStorage.setItem("primaryContactPhoneNumber", state.primaryContactPhoneNumber);
    },
    setMailingSameAsBilling: (state: BusinessInformationSliceState, action: PayloadAction<boolean>) => {
      state.mailingSameAsBilling = action.payload;
      sessionStorage.setItem("mailingSameAsBilling", JSON.stringify(state.mailingSameAsBilling));
    },
    setMailingAddress: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.mailingAddress.address = action.payload;
      sessionStorage.setItem("mailingAddress", state.mailingAddress.address);
    },
    setMailingAddressTwo: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.mailingAddress.addressTwo = action.payload;
      sessionStorage.setItem("mailingAddressTwo", state.mailingAddress.addressTwo);
    },
    setMailingCity: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.mailingAddress.city = action.payload;
      sessionStorage.setItem("mailingCity", state.mailingAddress.city);
    },
    setMailingState: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.mailingAddress.state = action.payload;
      sessionStorage.setItem("mailingState", state.mailingAddress.state);
    },
    setMailingCountry: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.mailingAddress.country = action.payload;
      sessionStorage.setItem("mailingCountry", state.mailingAddress.country);
    },
    setMailingPostalCode: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.mailingAddress.postalCode = action.payload;
      sessionStorage.setItem("mailingPostalCode", state.mailingAddress.postalCode);
    },
    setPrimaryContactEmail: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.primaryContactEmail = action.payload;
      sessionStorage.setItem("primaryContactEmail", state.primaryContactEmail);
    },
    setPrimaryContactBusinessContact: (state: BusinessInformationSliceState, action: PayloadAction<string>) => {
      state.primaryContactBusinessContact = action.payload;
      sessionStorage.setItem("primaryContactBusinessContact", state.primaryContactBusinessContact);
    },
    copyBillingToMailing: (state: BusinessInformationSliceState) => {
      state.mailingAddress.address = state.billingAddress.address;
      state.mailingAddress.addressTwo = state.billingAddress.addressTwo;
      state.mailingAddress.state = state.billingAddress.state;
      state.mailingAddress.city = state.billingAddress.city;
      state.mailingAddress.postalCode = state.billingAddress.postalCode;
      sessionStorage.setItem("mailingAddress", state.billingAddress.address);
      sessionStorage.setItem("mailingAddressTwo", state.billingAddress.addressTwo);
      sessionStorage.setItem("mailingCity", state.billingAddress.city);
      sessionStorage.setItem("mailingState", state.billingAddress.state);
      sessionStorage.setItem("mailingCountry", state.billingAddress.country);
      sessionStorage.setItem("mailingPostalCode", state.billingAddress.postalCode);
    },
    resetBusinessInformationSlice: (state: BusinessInformationSliceState) => {
      Object.assign(state, initialState);
      sessionStorage.removeItem("companyName");
      sessionStorage.removeItem("dba");
      sessionStorage.removeItem("displayName");
      sessionStorage.removeItem("billingAddress");
      sessionStorage.removeItem("billingAddressTwo");
      sessionStorage.removeItem("billingCity");
      sessionStorage.removeItem("billingState");
      sessionStorage.removeItem("billingCountry");
      sessionStorage.removeItem("billingPostalCode");
      sessionStorage.removeItem("primaryContactPhoneNumber");
      sessionStorage.removeItem("mailingSameAsBilling");
      sessionStorage.removeItem("mailingAddress");
      sessionStorage.removeItem("mailingAddressTwo");
      sessionStorage.removeItem("mailingCity");
      sessionStorage.removeItem("mailingState");
      sessionStorage.removeItem("mailingCountry");
      sessionStorage.removeItem("mailingPostalCode");
      sessionStorage.removeItem("primaryContactEmail");
      sessionStorage.removeItem("primaryContactBusinessContact");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOperator.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOperator.fulfilled, (state, action:PayloadAction<any>) => {
        state.loading = false;
        state.operatorId = action.payload?.id;
      })
      .addCase(createOperator.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectLoading = (state: RootState) => state.businessInformation.loading;
export const selectOperatorName = (state: RootState) => state.businessInformation.companyName;
export const selectOperatorDBA = (state: RootState) => state.businessInformation.dba;
export const selectOperatorDisplayName = (state: RootState) => state.businessInformation.displayName;
export const selectOperatorBillingAddress = (state: RootState) => state.businessInformation.billingAddress.address;
export const selectOperatorBillingAddressTwo = (state: RootState) =>
  state.businessInformation.billingAddress.addressTwo;
export const selectOperatorBillingCity = (state: RootState) => state.businessInformation.billingAddress.city;
export const selectOperatorBillingState = (state: RootState) => state.businessInformation.billingAddress.state;
export const selectOperatorBillingCountry = (state: RootState) => state.businessInformation.billingAddress.country;
export const selectOperatorBillingPostalCode = (state: RootState) => state.businessInformation.billingAddress.postalCode;
export const selectPrimaryContactEmail = (state: RootState) => state.businessInformation.primaryContactEmail;
export const selectPrimaryContactBusinessContact = (state: RootState) => state.businessInformation.primaryContactBusinessContact;
export const selectMailingSameAsBilling = (state: RootState) => state.businessInformation.mailingSameAsBilling;
export const selectOperatorMailingAddress = (state: RootState) => state.businessInformation.mailingAddress.address;
export const selectOperatorMailingAddressTwo = (state: RootState) =>
  state.businessInformation.mailingAddress.addressTwo;
export const selectOperatorMailingCity = (state: RootState) => state.businessInformation.mailingAddress.city;
export const selectOperatorMailingState = (state: RootState) => state.businessInformation.mailingAddress.state;
export const selectOperatorMailingCountry = (state: RootState) => state.businessInformation.mailingAddress.country;
export const selectOperatorMailingPostalCode = (state: RootState) => state.businessInformation.mailingAddress.postalCode;
export const selectOperatorPhoneNumber = (state: RootState): string => state.businessInformation.primaryContactPhoneNumber;
export const selectValidBillingAddress = (state: RootState): boolean => {
  return !!(
    state.businessInformation.billingAddress.address &&
    state.businessInformation.billingAddress.city &&
    state.businessInformation.billingAddress.state &&
    state.businessInformation.billingAddress.postalCode.length === 5
  );
};
export const selectValidMailingAddress = (state: RootState): boolean => {
  if (state.businessInformation.mailingSameAsBilling && selectValidBillingAddress(state)) {
    return true;
  }

  return !!(
    !state.businessInformation.mailingSameAsBilling &&
    state.businessInformation.mailingAddress.address &&
    state.businessInformation.mailingAddress.city &&
    state.businessInformation.mailingAddress.state &&
    state.businessInformation.mailingAddress.country &&
    (state.businessInformation.mailingAddress.postalCode.length === 5)
  );
};

export const selectValidOperatorPhoneNumber = (state: RootState): boolean => {
  return state.businessInformation.primaryContactPhoneNumber.length === 10;
};

export const selectValidContact = (state: RootState): boolean => {
  return !!(
    state.businessInformation.primaryContactEmail &&
    isValidEmail(state.businessInformation.primaryContactEmail) &&
    state.businessInformation.primaryContactBusinessContact
  );
};

export const selectOperatorId = (state: RootState) => state.businessInformation.operatorId;

export const {
  setLoading,
  setError,
  setOperatorName,
  setDBA,
  setDisplayName,
  setOperatorId,
  setBillingAddress,
  setBillingAddressTwo,
  setBillingCity,
  setBillingState,
  setBillingCountry,
  setMailingCountry,
  setBillingPostalCode,
  setBusinessPhoneNumber,
  setPrimaryContactEmail,
  setPrimaryContactBusinessContact,
  setMailingSameAsBilling,
  setMailingAddress,
  setMailingAddressTwo,
  setMailingCity,
  setMailingState,
  setMailingPostalCode,
  copyBillingToMailing,
  resetBusinessInformationSlice
} = businessSlice.actions;

export default businessSlice.reducer;
