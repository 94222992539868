import React, { FC, ReactNode } from "react";

import { Button, CircularProgress, Grid, Typography, useTheme } from "@mui/material";

import useStyles from "./StepperButtons.styles";

interface StepperButtonsProps {
  loading?: boolean
  backAction: Function
  backActionText: ReactNode
  forwardActionText: ReactNode
}

const StepperButtons: FC<StepperButtonsProps> = ({
  loading,
  backActionText,
  forwardActionText,
  backAction
}: StepperButtonsProps) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Grid pt={5} className={classes.stepButtonsGroup}>
      <Button
        className={classes.cancelButton}
        onClick={() => backAction()}
        variant={"text"}
        data-testid={"step-back-button"}
      >
        <Grid container alignItems={"center"}>
          <Typography variant={"button"} style={{ color: theme.palette.error.main }}>{backActionText}</Typography>
        </Grid>
      </Button>
      <Button
        type={"submit"}
        variant={"contained"}
        className={classes.nextButton}
        data-testid={"step-forward-button"}
      >
        {loading
          ? <CircularProgress data-testid={"next-loading-spinner"} size={"1.5rem"} className={classes.spinner} />
          : <Typography variant={"button"} style={{ color: theme.buttonColor }}>{forwardActionText}</Typography>}
      </Button>
    </Grid>
  );
};

export default StepperButtons;
