import React, { ReactElement } from "react";

import { Grid } from "@mui/material";

import useStyles from "./StepLayout.styles"; // <--- you may need to update this!

interface StepLayoutProps {
  children: React.ReactNode
}

const StepLayout: React.FC<StepLayoutProps> = ({ children }: StepLayoutProps): ReactElement => {
  const { classes } = useStyles();

  return (
    <Grid
      direction={"column"}
      className={classes.root}
      container
      justifyContent={"space-between"}
      p={5}
    >
      <Grid
        item
        className={classes.businessInfoFooter}>
        {children}
      </Grid>
    </Grid>
  );
};

export default StepLayout;
