import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Application } from "src/models/Application";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getApplications } from "src/store/thunks/applications/getAll/getApplications";

import { ApplicationAccessSliceState } from "./ApplicationsSliceState";

export const initialState: ApplicationAccessSliceState = {
  loading: false,
  error: "",
  applications: []
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplications: (state, action: PayloadAction<Application[]>) => {
      state.applications = action.payload;
    },
    toggleAppEnabled: (state, action: PayloadAction<number>) => {
      const selectedApp: Application = state.applications.find((application) => application.id === action.payload)!;
      selectedApp.enabled = !selectedApp.enabled;
    },
    resetApplications: (state) => {
      state.applications.forEach(app => {
        app.enabled = false;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplications.fulfilled, (state, action: PayloadAction<APIListResponse<Application[]>>) => {
        state.applications = action.payload.data.map(app => ({ ...app, enabled: false }));
        state.loading = false;
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectApplications = (state: RootState) => state.applications?.applications.filter(applications => applications.is_active === 1);
export const selectOneAppSelected = (state: RootState) => !!state.applications.applications
  .filter(app => app.enabled).length;
export const selectLoading = (state: RootState) => state.applications.loading;
export const selectBusinessInformation = (state: RootState) => state.applications;

export const {
  setApplications,
  toggleAppEnabled,
  resetApplications
} = applicationSlice.actions;

export default applicationSlice.reducer;
