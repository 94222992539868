import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles({ name: "Redirect500" })((theme) => ({
  statusErrorText: {
    color: theme.palette.error.main,
    fontSize: "65px",
    lineHeight: "76px"
  },
  statusText: {
    color: theme.palette.text.primary,
    fontSize: "125px",
    fontWeight: "bold",
    lineHeight: "100px"
  },
  titleText: {
    color: theme.palette.text.primary,
    fontSize: "30px",
    fontWeight: "bold"
  },
  subtitleText: {
    color: theme.palette.text.primary,
    fontSize: "20px"
  },
  returnText: {
    color: theme.palette.primary.contrastText,
    fontSize: "18px"
  }
}));

export default useStyles;
