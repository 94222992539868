import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "16px 16px 16px 0",
    marginRight: "88px",
    [theme.breakpoints.down("xl")]: {
      marginRight: "128px"
    },
    [theme.breakpoints.down("lg")]: {
      marginRight: "144px"
    }
  },
  card: {
    width: "170px",
    height: "170px",
    borderRadius: "10px",
    border: `1px solid ${theme.gray500Base}`,
    "&:hover": {
      backgroundColor: theme.palette.background.default
    }

  },
  iconContainer: {
    width: "100%",
    height: "120px"
  },
  appIcon: {
    width: "100px",
    height: "100px",
    marginTop: "17px"
  },
  linkText: {
    width: "100%",
    height: "50px",
    paddingTop: "10px"
  },
  link: {
    textDecoration: "none"
  },
  emptyGrid: {
    flexGrow: 1
  }
}));

export default useStyles;
