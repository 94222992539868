import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../api/axiosInstance";

export const refreshToken = createAsyncThunk("refreshToken", (_, { rejectWithValue }) => {
  return axios
    .get("/api/v1/auth/refresh")
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      if (error.response.data?.status === 401) {
        return rejectWithValue("Oops! Please try again. Your session token was invalidated.");
      }

      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }

      return rejectWithValue("Please try again later, we're experiencing a server error. We're working on it!");
    });
});
