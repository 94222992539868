import React, { ChangeEvent, Fragment, ReactElement, useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { IconCheck, IconCircleCheck, IconEye, IconEyeOff, IconFingerprint, IconX } from "@tabler/icons-react";

import { useFormik } from "formik";

import { Link as RouterLink, useSearchParams } from "react-router-dom";

import StoreSpaceLogo from "src/assets/storage-360-logo.svg";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "src/store/hooks";
import {
  clearError,
  emailOnchange,
  firstNameOnchange,
  lastNameOnchange,
  passwordConfirmationOnchange,
  passwordOnchange,
  selectCancelledRegistration,
  selectError,
  selectLoading,
  selectPassword,
  selectPasswordConfirmation,
  selectPasswordShown,
  selectStep,
  selectUserEmail,
  selectUserFirstName,
  selectUserLastName,
  selectUserUsername,
  showPasswordOnchange,
  stepOnchange
} from "src/store/reducers/confirmUser/confirmUserSlice";
import { confirmUser } from "src/store/thunks/user/confirm/confirmUser";
import { validateToken } from "src/store/thunks/user/confirm/validateToken";

import { confirmationUserFormValidation, validationErrors } from "./confirmationUserFormValidation";

import useStyles from "./ConfirmationForm.styles";

const ConfirmationForm: React.FC = (): ReactElement => {
  const error = useSelector(selectError);
  const [searchParams, _setSearchParams] = useSearchParams();
  const registration_token = searchParams.get("registration_token");
  const loading = useSelector(selectLoading);
  const theme = useTheme();

  const confirmUserFirstName = useSelector(selectUserFirstName);
  const confirmUserLastName = useSelector(selectUserLastName);
  const confirmUserEmail = useSelector(selectUserEmail);
  const confirmUserUsername = useSelector(selectUserUsername);
  const confirmPassword = useSelector(selectPassword);
  const confirmPasswordConfirmation = useSelector(selectPasswordConfirmation);
  const isPasswordShown = useSelector(selectPasswordShown);
  const isRegistrationCancelled = useSelector(selectCancelledRegistration);
  const step = useSelector(selectStep);
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const [registrationFinished, setRegistrationFinished] = useState(false);
  const [registrationExpired, setRegistrationExpired] = useState(false);
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [submitClicked, setSubmitClicked] = useState(false);

  const handleClearError = () => dispatch(clearError());
  const handleShowPasswordOnchange = () => dispatch(showPasswordOnchange());

  const handleSave = () => {
    dispatch(confirmUser(registration_token)).then((resp) => {
      if (resp.type.includes("fulfilled")) {
        dispatch(stepOnchange(3));
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      confirmUserFirstName,
      confirmUserLastName,
      confirmUserEmail,
      confirmUserUsername,
      confirmPassword,
      confirmPasswordConfirmation
    },
    validationSchema: confirmationUserFormValidation,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: (values) => {
      return confirmationUserFormValidation
        .validate(values, { abortEarly: false })
        .then(() => {})
        .catch((err: any) => {
          return err.inner.reduce((obj: any, e: any) => {
            if (!(e.path in obj)) obj[e.path] = [];
            obj[e.path] = obj[e.path].concat(e.errors);
            return obj;
          }, {});
        });
    },
    onSubmit: () => {
      handleSave();
    }
  });

  const { errors, touched, values, handleSubmit } = formik;

  const errorElements = (Object.keys(validationErrors) as (keyof typeof validationErrors)[]).map((key) => {
    return (
      <Box key={key} style={{ display: "flex", alignItems: "center" }}>
        {errors.confirmPassword?.includes(validationErrors[key])
          ? (
            <IconX style={{ color: theme.palette.error.main, width: "14px", height: "14px" }} />
            )
          : (
            <IconCheck style={{ color: theme.palette.success.main, width: "14px", height: "14px" }} />
            )}
        <Typography
          variant={"body3"}
          style={{
            color: errors.confirmPassword?.includes(validationErrors[key])
              ? theme.palette.error.main
              : theme.palette.success.main,
            marginLeft: "4px"
          }}
      >
          {validationErrors[key]}
        </Typography>
      </Box>
    );
  });

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    if (registration_token) {
      dispatch(validateToken(registration_token)).then((resp) => {
        if (resp.type.includes("fulfilled")) {
          setTokenIsValid(true);
        } else if (resp.payload === "Registration finished.") {
          setRegistrationFinished(true);
          setTokenIsValid(false);
        } else if (resp.payload === "Registration expired.") {
          setRegistrationExpired(true);
          setTokenIsValid(false);
        } else {
          setTokenIsValid(false);
        }
      });
    }
  }, [registration_token]);

  const handleFirstNameOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(firstNameOnchange(fieldValue));
  };

  const handleLastNameOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(lastNameOnchange(fieldValue));
  };

  const handleEmailOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(emailOnchange(fieldValue));
  };

  const handlePasswordOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(passwordOnchange(fieldValue));
  };

  const handlePasswordConfirmationOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(passwordConfirmationOnchange(fieldValue));
  };

  const confirmErrorDisplay =
    error || !tokenIsValid
      ? (
        <Box width={"75%"} mt={4}>
          <Alert data-testid={"error-message"} severity={"error"} onClose={() => handleClearError()}>
            {typeof error === "string" && error}
          </Alert>
        </Box>
        )
      : (
          ""
        );

  const IntroMessage = () => {
    if (loading) {
      return null;
    }

    return (
      <>
        <IconFingerprint stroke={1} className={classes.imageFinger} />
        {isRegistrationCancelled
          ? (
            <Typography variant={"h1"} fontWeight={500} className={classes.title}>
              This users registration has been cancelled!
            </Typography>
            )
          : registrationExpired
            ? (
              <Typography variant={"h1"} fontWeight={500} className={classes.title}>
                This users registration has expired!
              </Typography>
              )
            : registrationFinished
              ? (
                <Typography variant={"h1"} fontWeight={500} className={classes.title}>
                  This user has already finished registration!
                </Typography>
                )
              : (
                <>
                  <Typography variant={"h5"} className={classes.title}>
                    Getting started with identity
                  </Typography>
                  <Typography variant={"body2"} mb={"35px"} mt={2}
                    className={classes.subtitle}>
                    Welcome! Since this is your first time logging in, let&apos;s set up your account
                  </Typography>
                  <Button
                    variant={"contained"}
                    size={"large"}
                    type={"submit"}
                    className={classes.getStarted}
                    data-testid={"getstarted-button"}
                    onClick={() => {
                      dispatch(stepOnchange(2));
                    }}>
                    <Typography variant={"button"} style={{ color: theme.buttonColor }}>
                      Get Started
                    </Typography>
                  </Button>
                  <Box mt={1}>
                    <Link
                      type={"button"}
                      component={RouterLink}
                      variant={"body2"}
                      to={"/forgot-password"}
                      data-testid={"forgot-password-button"}
                      className={classes.linkSignin}>
                      <Typography
                        variant={"button"}
                        style={{ color: theme.palette.primary.contrastText, textDecoration: "none" }}>
                        Already have an account? Sign in
                      </Typography>
                    </Link>
                  </Box>
                </>
                )}
      </>
    );
  };

  const SuccessMessage = () => (
    <>
      <IconCircleCheck className={classes.checkCircle} />
      <Typography variant={"h2"} className={classes.title}>
        All Done
      </Typography>
      <Typography variant={"body2"} mb={"35px"} className={classes.subtitle}>
        Your account has now been successfully created. <br /> Please check your email for the account verification
        link.
      </Typography>
      <Link type={"button"} component={RouterLink} variant={"body2"}
        className={classes.linkBack} to={"/"}>
        <Button
          variant={"contained"}
          size={"large"}
          type={"submit"}
          className={classes.signInButton}
          data-testid={"signin-button"}>
          <Typography variant={"button"} style={{ color: theme.buttonColor }}>
            Sign In
          </Typography>
        </Button>
      </Link>
    </>
  );

  const toggleVisibilityButton = (
    <IconButton
      aria-label={"toggle password"}
      data-testid={"toggle-password-button"}
      onClick={() => handleShowPasswordOnchange()}>
      {!isPasswordShown
        ? (
          <IconEye data-testid={"on-icon"} className={classes.iconEye} />
          )
        : (
          <IconEyeOff data-testid={"off-icon"} className={classes.iconEye} />
          )}
    </IconButton>
  );

  return (
    <main>
      <Grid className={classes.root} container item
        justifyContent={"center"} alignItems={"center"} md={5}>
        <Grid item xs={12} className={classes.containerForm}>
          <Grid item xs={12} className={classes.containerImage}>
            <img src={StoreSpaceLogo} alt={"Store Space Logo"} className={classes.image} />
          </Grid>
          <Paper elevation={3} className={classes.confirmPaper}>
            <Grid
              item
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.outerGrid}>
              {step === 1 && (
                <Fragment>
                  <IntroMessage />
                </Fragment>
              )}

              {step === 3 && (
                <Fragment>
                  <SuccessMessage />
                </Fragment>
              )}

              {step === 2 && (
                <Fragment>
                  <Typography variant={"h5"} className={classes.title} mb={2}>
                    Welcome! Let&apos;s create your account.
                  </Typography>
                  <Typography variant={"body2"} mb={"43px"} className={classes.subtitle}>
                    Let&apos;s start by reviewing your personal information to make sure it&apos;s correct and ready to
                    go.
                  </Typography>
                  <Box className={classes.formContainer}>
                    <form
                      className={classes.form}
                      data-testid={"confirm-form"}
                      onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        formik.handleSubmit();
                      }}>
                      <Grid container justifyContent={"space-between"}>
                        <Grid className={classes.formInput} pr={2} pb={1}
                          item md={6}>
                          <InputLabel htmlFor={"confirm-user-first-name"}>
                            <Typography variant={"subtitle2"}>First Name</Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"confirm-user-first-name"}
                            data-testid={"confirm-user-first-name"}
                            name={"confirmUserFirstName"}
                            value={values.confirmUserFirstName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleFirstNameOnchange("confirmUserFirstName", e.target.value)
                            }
                            error={
                              touched.confirmUserFirstName &&
                              Boolean(errors.confirmUserFirstName)
                            }
                            helperText={
                              Boolean(errors.confirmUserFirstName) &&
                              touched.confirmUserFirstName &&
                              String(errors.confirmUserFirstName)
                            }
                          />
                        </Grid>
                        <Grid className={classes.formInput} pl={2} pb={1}
                          item md={6}>
                          <InputLabel htmlFor={"confirm-user-last-name"}>
                            <Typography variant={"subtitle2"}>Last Name</Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"confirm-user-last-name"}
                            data-testid={"confirm-user-last-name"}
                            name={"confirmUserLastName"}
                            value={values.confirmUserLastName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleLastNameOnchange("confirmUserLastName", e.target.value)
                            }
                            error={
                              touched.confirmUserLastName &&
                              Boolean(errors.confirmUserLastName)
                            }
                            helperText={
                              Boolean(errors.confirmUserLastName) &&
                              touched.confirmUserLastName &&
                              String(errors.confirmUserLastName)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container item mt={1}
                        direction={"column"}>
                        <label htmlFor={"confirm-form-email"}>
                          <Typography variant={"subtitle2"}>Email Address</Typography>
                        </label>
                        <TextField
                          id={"confirm-form-email"}
                          data-testid={"confirm-form-email"}
                          aria-describedby={"email-text"}
                          margin={"dense"}
                          variant={"outlined"}
                          name={"confirmUserEmail"}
                          value={values.confirmUserEmail}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleEmailOnchange("confirmUserEmail", e.target.value)
                          }
                          autoComplete={"email"}
                          inputProps={{ "data-testid": "email-input" }}
                          className={classes.disabled}
                          disabled
                          error={touched && touched.confirmUserEmail && errors && Boolean(errors.confirmUserEmail)}
                        />
                        <Typography variant={"body4"} sx={{ marginTop: "6px" }}>
                          Please enter a password containing minimum 12 characters that has at least one uppercase
                          letter, one lowercase letter, one special character, one number and they are not repeating
                          more than twice!
                        </Typography>
                        <label htmlFor={"confirm-form-password"} className={classes.password_label}>
                          <Typography variant={"subtitle2"}>New Password</Typography>
                        </label>
                        <TextField
                          id={"confirm-form-password"}
                          data-testid={"confirm-form-password"}
                          type={isPasswordShown ? "text" : "password"}
                          aria-describedby={"password-text"}
                          margin={"dense"}
                          name={"confirmPassword"}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handlePasswordOnchange("confirmPassword", e.target.value)
                          }
                          autoComplete={"current-password"}
                          inputProps={{ "data-testid": "password-input" }}
                          InputProps={{ endAdornment: toggleVisibilityButton }}
                        />
                        {values.confirmPassword && errorElements}
                        {!values.confirmPassword && submitClicked && <Typography variant={"body3"} style={{ color: theme.palette.error.main }}>New password is required.</Typography>}
                        <label htmlFor={"confirm-form-password"} className={classes.password_label}>
                          <Typography variant={"subtitle2"}>Confirm New Password</Typography>
                        </label>
                        <TextField
                          id={"confirm-form-password-confirmation"}
                          data-testid={"confirm-form-password-confirmation"}
                          type={isPasswordShown ? "text" : "password"}
                          aria-describedby={"password-confirmation-text"}
                          margin={"dense"}
                          name={"confirmPasswordConfirmation"}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handlePasswordConfirmationOnchange("confirmPasswordConfirmation", e.target.value)
                          }
                          autoComplete={"current-password-confirmation"}
                          inputProps={{ "data-testid": "password-confirmation-input" }}
                          InputProps={{ endAdornment: toggleVisibilityButton }}

                        />
                        {values.confirmPasswordConfirmation && (errors.confirmPasswordConfirmation ? <Typography variant={"body3"} style={{ color: theme.palette.error.main }}>Both passwords must match.</Typography> : <Typography variant={"body3"} style={{ color: theme.palette.success.main }}>Passwords match. Success!</Typography>)}
                        {!values.confirmPasswordConfirmation && submitClicked && <Typography variant={"body3"} style={{ color: theme.palette.error.main }}>Confirm new password is required.</Typography>}
                        <Grid item xs={12} style={{ display: "inline-flex", justifyContent: "space-around" }}
                          mt={2}>
                          <Button
                            variant={"contained"}
                            size={"large"}
                            onClick={() => {
                              dispatch(stepOnchange(1));
                            }}
                            className={classes.confirmCancelButton}
                            data-testid={"confirm-cancel-button"}>
                            <Typography variant={"button"} style={{ color: theme.palette.error.dark }}>
                              Cancel
                            </Typography>
                          </Button>

                          <Button
                            variant={"contained"}
                            size={"large"}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSubmitClicked(true);
                              handleSubmit();
                            }}
                            className={classes.confirmButton}
                            data-testid={"confirm-button"}>
                            {loading
                              ? (
                                <CircularProgress
                                  size={"2rem"}
                                  data-testid={"confirm-button-spinner"}
                                  className={classes.spinner}
                              />
                                )
                              : (
                                <Typography variant={"button"} style={{ color: theme.buttonColor }}>
                                  Complete
                                </Typography>
                                )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                    {confirmErrorDisplay}
                  </Box>
                </Fragment>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
};

export default ConfirmationForm;
