import React, { ReactElement, useState } from "react";

import { Divider, List, Typography } from "@mui/material";
import { IconGridDots, IconHome, IconUserCircle, IconUsers } from "@tabler/icons-react";

import { useAppSelector, useAppSelector as useSelector } from "src/store/hooks";
import { selectShowDrawer } from "src/store/reducers/navigation/navigationSlice";
import { selectUserType } from "src/store/reducers/userInfo/userInfoSlice";

import NavLink from "./NavLink/NavLink";
import { NavLinkType } from "./NavLink/NavLinkType";
import NavLinkMobile from "./NavLinkMobile/NavLinkMobile";

import useStyles from "./NavDrawer.styles";

interface NavDrawerProps {
  isDrawerShown?: boolean;
}

const NavDrawer: React.FC<NavDrawerProps> = (): ReactElement => {
  const { classes } = useStyles();

  const userType = useSelector(selectUserType);
  const isDrawerShown = useAppSelector(selectShowDrawer);

  const [openClick, setOpenClick] = useState("");
  const [openHover, setOpenHover] = useState("");

  const globalLinks: NavLinkType[] = [
    { name: "Home", path: "/", icon: <IconHome style={{ width: "18px", height: "18px" }} /> },
    {
      name: "Users",
      path: "",
      icon: <IconUsers style={{ width: "18px", height: "18px" }} />,
      childLinks: [
        { name: "Add User", path: "/users/add-user" },
        { name: "Manage Users & Roles", path: "/users-roles" },
        { name: "User Audit Log", path: "/users/user-audit-log" }
      ]
    },
    { name: "Apps", path: "/", icon: <IconGridDots style={{ width: "18px", height: "18px" }} /> }
  ];

  const permissionLinks: NavLinkType[] = [
    {
      name: "Operators",
      path: "",
      icon: <IconUserCircle style={{ width: "18px", height: "18px" }} />,
      childLinks: [
        { name: "Onboard Operator", path: "/operators/onboard-operator" },
        { name: "Operator List", path: "/operators/operators-list" }
      ]
    }
  ];

  return (
    <nav className={classes.root} data-testid={"navigation-drawer"}>
      <List component={"nav"} aria-label={"global navigation drawer"}>
        {globalLinks.map((link, i) =>
          isDrawerShown
            ? (
              <NavLink key={`${link.name}-${i}`} link={link} />
              )
            : (
              <NavLinkMobile
                key={`${link.name}-${i}`}
                link={link}
                openClick={openClick}
                openHover={openHover}
                setOpenClick={setOpenClick}
                setOpenHover={setOpenHover}
            />
              )
        )}
      </List>
      <Divider className={classes.divider} />
      {userType === "operator-agnostic" && (
        <>
          <Typography
            variant={"body4"}
            className={classes.menuAdminSettings}
            style={{ margin: isDrawerShown ? "30px 0 10px 30px" : "30px 0 10px 7px" }}>
            {isDrawerShown ? "Admin Settings" : "Admin"}
          </Typography>
          <List component={"nav"} aria-label={"permissioned navigation drawer"}>
            {permissionLinks.map((link, i) =>
              isDrawerShown
                ? (
                  <NavLink key={`${link.name}-${i}`} link={link} />
                  )
                : (
                  <NavLinkMobile
                    key={`${link.name}-${i}`}
                    link={link}
                    openClick={openClick}
                    openHover={openHover}
                    setOpenClick={setOpenClick}
                    setOpenHover={setOpenHover}
                />
                  )
            )}
          </List>
        </>
      )}
    </nav>
  );
};

export default NavDrawer;
