import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { UpdateRolePayload } from "./updateRolePayload";

export const updateRole = createAsyncThunk(
  "updateRole",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const editRole = state.editRole;

    const payload: UpdateRolePayload = {
      name: editRole.role?.name || "",
      color: editRole.role?.color || "",
      description: editRole.role?.description || "Can perform all facility management tasks",
      permissions: editRole.role?.permissions || [""]
    };

    return axios.put(`/api/v1/roles/${editRole.role?.id}`, payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Role updated successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error updating the role";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
