import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { LoginActivity } from "src/models/LoginActivity";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getLoginActivity } from "src/store/thunks/loginActivity/getAll/getLoginActivity";

import { LoginActivitySliceState } from "./LoginActivitySliceState";

export const initialState: LoginActivitySliceState = {
  loading: false,
  error: "",
  loginActivity: {
    data: [],
    meta: {} as APIListMetaData,
    links: {
      first: null,
      last: null,
      prev: null,
      next: null
    }
  }
};

export const loginActivitySlice = createSlice({
  name: "loginActivity",
  initialState,
  reducers: {
    setLoginActivity: (state, action: PayloadAction<LoginActivity[]>) => {
      state.loginActivity.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoginActivity.fulfilled, (state, action: PayloadAction<APIListResponse<LoginActivity[]>>) => {
        state.loginActivity = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.loginActivity.data = action.payload.data;
        state.loginActivity.meta = action.payload.meta;
        state.loginActivity.links = action.payload.links;
        state.loading = false;
      })
      .addCase(getLoginActivity.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectLoginActivity = (state: RootState) => state.loginActivity.loginActivity;
export const selectLoading = (state: RootState) => state.loginActivity.loading;
export const selectBusinessInformation = (state: RootState) => state.loginActivity;

export const {
  setLoginActivity
} = loginActivitySlice.actions;

export default loginActivitySlice.reducer;
