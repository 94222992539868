import React, { Fragment, ReactElement, useEffect } from "react";

import { List, ListItem, ListItemIcon, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";

import { useNavigate } from "react-router-dom";

import { NavLinkType } from "../NavLink/NavLinkType";

import useStyles from "./NavLinkMobile.styles";

interface NavLinkMobileProps {
  link: NavLinkType;
  openClick: string;
  setOpenClick: Function;
  openHover: string;
  setOpenHover: Function;
}

const NavLinkMobile: React.FC<NavLinkMobileProps> = ({
  link,
  openClick,
  setOpenClick,
  openHover,
  setOpenHover
}: NavLinkMobileProps): ReactElement => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleClick = (linkName: string) => {
    if (link.childLinks && link.childLinks.length) {
      setOpenClick(openClick === linkName ? "" : linkName);
    } else {
      navigate(link.path);
      setOpenClick("");
    }
  };

  const handleHover = (linkName: string) => {
    setOpenHover(openHover === linkName ? "" : linkName);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tooltip = document.querySelector(".MuiTooltip-tooltip");
      if (tooltip && !tooltip.contains(event.target as Node)) {
        setOpenClick("");
        setOpenHover("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenClick, setOpenHover]);

  const title =
    link.childLinks && link.childLinks.length && openClick === link.name
      ? (
        <List>
          <ListItem style={{ marginBottom: "15px" }}>
            <Typography variant={"body3"} className={classes.navLinkTooltipLinkTitle}>
              {link.name}
            </Typography>
          </ListItem>
          {link.childLinks.map((childLink) => (
            <ListItem key={childLink.name} onClick={() => navigate(childLink.path)}>
              <Typography variant={"body3"} className={classes.navLinkTooltipLink}>
                {childLink.name}
              </Typography>
            </ListItem>
          ))}
        </List>
        )
      : (
          link.name
        );

  return (
    <Fragment>
      <ListItem
        style={{ marginBottom: "20px" }}
        data-testid={"list-item-dropdown"}
        className={clsx(
          classes.listItem,
          (window.location.pathname === link.path || window.location.pathname === undefined) && classes.selectedLink
        )}
        onClick={() => handleClick(link.name)}
        onMouseEnter={() => {
          handleHover(link.name);
        }}
        onMouseLeave={() => handleHover(link.name)}
        button>
        <Tooltip
          onClose={() => handleClick("")}
          open={openClick === link.name || openHover === link.name}
          title={
            <Typography variant={"body3"} className={classes.navLinkTooltipLink}>
              {title}
            </Typography>
          }
          arrow
          placement={"right"}
          classes={{ tooltip: classes.navLinkTooltip, arrow: classes.tooltipArrow }}
          disableFocusListener
          disableHoverListener
          disableTouchListener>
          <ListItemIcon className={classes.listIcon} style={{ marginLeft: 0 }}>
            {link.icon}
          </ListItemIcon>
        </Tooltip>
      </ListItem>
    </Fragment>
  );
};

export default NavLinkMobile;
