
import React, { ReactElement, useCallback, useEffect } from "react";

import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconCircleMinus, IconEdit, IconSearch } from "@tabler/icons-react";
import { debounce } from "lodash";

import { PatternFormat } from "react-number-format";
import { Link as RouterLink } from "react-router-dom";

import { usePagination } from "src/components/hooks/usePagination";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { AppBreadcrumb } from "src/models/app-breadcrumb";
import { selectLoading, selectOperators } from "src/store/reducers/operators/operatorsSlice";
import { deactivateOperator } from "src/store/thunks/operator/deactivate/deactivateOperator";
import { getOperators } from "src/store/thunks/operators/getAll/getOperators";

import { useAppDispatch, useAppSelector, useAppSelector as useSelector } from "../../store/hooks";

import useStyles from "./ListOperators.styles";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Operators"
  },
  {
    name: "Operator List",
    bold: true
  }
];

const ListOperators: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(0);

  const {
    search,
    setSearch,
    handleChangePaginationModel,
    paginationModel
  } = usePagination("operatorsTable");

  const operators = useAppSelector(selectOperators);

  const loading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getOperators({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search
    }));
  }, [paginationModel]);

  const handleSearch = useCallback(debounce((searchTerm: string) => {
    handleChangePaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    dispatch(getOperators({
      page: 1,
      pageSize: paginationModel.pageSize,
      search: searchTerm
    }));
  }, 500), []);

  // const handleSearch = useCallback(debounce((searchTerm: string) => {
  //   handleChangePage(0);
  //   dispatch(getOperators({
  //     page: 1,
  //     pageSize: rowsPerPage,
  //     search: searchTerm
  //   }));
  // }, 500), []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeactivateOperator = (operatorId: number) => {
    dispatch(deactivateOperator(operatorId)).then(() => {
      dispatch(getOperators({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        search
      }));
      setOpen(false);
    });
  };

  const handleOpenModalConfirm = (rowId: number) => {
    setOpen(true);
    setSelectedItem(rowId);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
      filterable: false
    },
    {
      field: "business_name",
      headerName: "Operator Name",
      flex: 0.8,
      filterable: false
    },
    {
      field: "dba_name",
      headerName: "DBA",
      flex: 1.2,
      filterable: false
    },
    {
      field: "display_name",
      headerName: "Display Name",
      flex: 0.8,
      filterable: false
    },
    {
      field: "billing_street_address",
      headerName: "Billing Address",
      flex: 0.8,
      filterable: false
    },
    {
      field: "mailing_street_address_2",
      headerName: "Mailing Address 2",
      flex: 0.8,
      filterable: false
    },
    {
      field: "billing_contact",
      headerName: "Billing Contact",
      flex: 0.8,
      filterable: false,
      valueGetter: ({ row }) => row.dba_name
    },
    {
      field: "billing_phone",
      headerName: "Phone",
      flex: 0.8,
      filterable: false,
      renderCell: (params) => <Typography variant={"body4"}>
        <PatternFormat
          format={"(###) ###-####"}
          mask={"_"}
          value={params.row.billing_phone}
          displayType={"text"}
      />
      </Typography>
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      filterable: false,
      sortable: false,
      renderCell: (params) => <Box sx={{ display: "inline-flex" }}>
        <IconButton >
          <Link
            type={"button"}
            component={RouterLink}
            variant={"body4"}
            to={`/operators/edit-operator/${params.id}`}
            data-testid={"edit-operator-link"}
        >
            <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>Edit Operator</Typography>} arrow>
              <IconEdit className={classes.actionButton} />
            </Tooltip>
          </Link>
        </IconButton>
        {params.row.is_active > 0
          ? <IconButton
              data-testid={"deactivate-operator-link"}
              onClick={() => handleOpenModalConfirm(params.row.id)}
          >
            <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>Deactivate Operator</Typography>} arrow>
              <IconCircleMinus className={classes.actionButton} />
            </Tooltip>
          </IconButton>
          : <IconButton>
            <IconCircleMinus style={{ color: theme.palette.error.main }} />
          </IconButton>}
      </Box>
    }
  ];

  return (
    <Grid
      data-testid={"onboard-operator-view"}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.root}
    >
      <AppLayout>
        <PageHeader
          title={"List Operators"}
          breadcrumbs={breadcrumbs}
        />
        <Grid
          container
          item
          lg={12}
          mt={5}
          mb={5}
        >
          <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", boxShadow: "none" }}>
            <Grid container item xs={12}
              style={{ padding: "0 25px 25px" }}>
              <Box className={classes.boxContent}>
                <Typography variant={"h6"} className={classes.titleTable}>Operator List:</Typography>
                <Link ml={3}
                  type={"button"}
                  component={RouterLink}
                  variant={"body2"}
                  to={"/operators/onboard-operator"}
                  data-testid={"onboard-operator-link"}
                  className={classes.linkAdd}
                >
                  <Button
                    variant={"contained"}
                    size={"large"}
                    className={classes.addButtonTable}
                    data-testid={"add-operator-button"}
                  >
                    <Typography variant={"button"} style={{ color: theme.buttonColor }}>+ New Operator</Typography>
                  </Button>
                </Link>
                <TextField
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    handleSearch(event.target.value);
                  }}
                  placeholder={"Search"}
                  className={classes.filterInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <IconSearch className={classes.filterInputIcon} />
                      </InputAdornment>
                    )
                  }}
            />
              </Box> <Grid item xs={12}>
                <DataGrid
                  rowCount={operators?.meta?.total || 0}
                  rows={operators.data || []}
                  columns={columns}
                  disableRowSelectionOnClick
                  paginationMode={"server"}
                  loading={loading}
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page }
                    }
                  }}
                  onPaginationModelChange={handleChangePaginationModel}
                  pageSizeOptions={[5, 10, 25, 50]}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </AppLayout>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={"alert-dialog-title"}
        aria-describedby={"alert-dialog-description"}
                        >
        <Card className={classes.dialogCard}>
          <Typography variant={"h5"} style={{ fontWeight: 500, textAlign: "center" }}>
            Confirm to Deactivate Operator
          </Typography>
          <DialogContent>
            <Typography variant={"body2"} style={{ textAlign: "center" }} mb={3}>
              Are you sure you want to deactivate Operator?
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={handleClose} className={classes.dialogCancelBtn}>
              <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
            </Button>
            <Button data-testid={"deactivate1-operator-link"} onClick={(_e:any) => handleDeactivateOperator(selectedItem)} className={classes.dialogConfirmBtn}>
              <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>Confirm</Typography>
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
    </Grid>
  );
};

export default ListOperators;
