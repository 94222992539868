
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid, IconButton,
  Link,
  Paper,
  TextField,
  Typography, useTheme
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { IconArrowBack, IconCheck, IconCircleCheck, IconClick, IconEye, IconEyeOff, IconX } from "@tabler/icons-react";

import { useFormik } from "formik";

import { Link as RouterLink, useSearchParams } from "react-router-dom";

import StoreSpaceLogo from "src/assets/storage-360-logo.svg";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "src/store/hooks";
import {
  clearError, confirmPasswordOnchange, passwordOnchange, selectConfirmPassword, selectError,
  selectLoading, selectPassword, selectPasswordShown,
  showPasswordOnchange
} from "src/store/reducers/resetPassword/resetPasswordSlice";
import { resetPassword } from "src/store/thunks/resetPassword/resetPassword";
import { validateToken } from "src/store/thunks/resetPassword/validateToken";

import useStyles from "./ResetPasswordForm.style";
import { resetPasswordFormValidation, validationErrors } from "./resetPasswordFormValidation";

const ResetPasswordForm: React.FC = (): ReactElement => {
  const theme2 = useTheme();
  const { classes } = useStyles();
  const theme = useTheme();
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);

  const isPasswordShown = useSelector(selectPasswordShown);
  const [searchParams, _setSearchParams] = useSearchParams();

  const token = searchParams.get("token") || "";
  const email = searchParams.get("email") || "";

  const newPassword = useSelector(selectPassword);
  const newConfirmPassword = useSelector(selectConfirmPassword);

  const dispatch = useDispatch();
  const handleClearError = () => dispatch(clearError());
  const handleShowPasswordOnchange = () => dispatch(showPasswordOnchange());
  const [passwordReset, setPasswordReset] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const [passwordIsUsed, setPasswordIsUsed] = useState(false);

  const [submitClicked, setSubmitClicked] = useState(false);

  const resetPasswordErrorDisplay = error || !tokenIsValid
    ? (
      <Box width={"75%"} mt={4}>
        <Alert
          data-testid={"error-message"}
          severity={"error"}
          onClose={() => handleClearError()}
        >
          {error}
        </Alert>
      </Box>
      )
    : "";

  useEffect(() => {
    if (!!email && !!token) {
      dispatch(validateToken({ email, token }))
        .then(resp => {
          if (resp.type.includes("fulfilled")) {
            setTokenIsValid(true);
          } else {
            setTokenIsValid(false);
          }
        });
    }
  }, [email, token]);

  const handleSave = () => {
    dispatch(resetPassword({ email, token }))
      .then(resp => {
        if (resp.type.includes("fulfilled")) {
          if (resp.payload?.message === "The password was already used!") {
            setPasswordIsUsed(true);
          } else {
            setPasswordReset(true);
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      newPassword,
      newConfirmPassword
    },
    validationSchema: resetPasswordFormValidation,
    validateOnBlur: false,
    enableReinitialize: true,
    validate: (values) => {
      return resetPasswordFormValidation
        .validate(values, { abortEarly: false })
        .then(() => {})
        .catch((err: any) => {
          return err.inner.reduce((obj: any, e: any) => {
            if (!(e.path in obj)) obj[e.path] = [];
            obj[e.path] = obj[e.path].concat(e.errors);
            return obj;
          }, {});
        });
    },
    onSubmit: () => {
      handleSave();
    }
  });

  const { errors, values, handleSubmit } = formik;

  const errorElements = (Object.keys(validationErrors) as (keyof typeof validationErrors)[]).map((key) => {
    return (
      <div key={key} style={{ display: "flex", alignItems: "center" }}>
        {errors.newPassword?.includes(validationErrors[key])
          ? (
            <IconX style={{ color: theme.palette.error.main, width: "14px", height: "14px" }} />
            )
          : (
            <IconCheck style={{ color: theme.palette.success.main, width: "14px", height: "14px" }} />
            )}
        <Typography
          variant={"body3"}
          style={{
            color: errors.newPassword?.includes(validationErrors[key])
              ? theme.palette.error.main
              : theme.palette.success.main,
            marginLeft: "4px"
          }}
      >
          {validationErrors[key]}
        </Typography>
      </div>
    );
  });

  const updateForm = (
    fieldName: string,
    fieldValue?: string | boolean
  ): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleNewPassword = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(passwordOnchange(fieldValue));
  };

  const handleNewConfirmPassword = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(confirmPasswordOnchange(fieldValue));
  };

  const toggleVisibilityButton = (
    <StyledEngineProvider>
      <IconButton
        aria-label={"toggle password"}
        data-testid={"toggle-password-button"}
        onClick={() => handleShowPasswordOnchange()}
      >
        {!isPasswordShown
          ? <IconEye style={{ color: theme2.palette.primary.main }} data-testid={"on-icon"}/>
          : <IconEyeOff style={{ color: theme2.palette.primary.main }} data-testid={"off-icon"}/>
        }
      </IconButton>
    </StyledEngineProvider>
  );

  return (
    <StyledEngineProvider injectFirst>
      <main className={classes.main}>
        <Grid
          className={classes.root}
          container
          item
          justifyContent={"center"}
          id={"container-confirm-forgot-password-form"}
        >
          <Grid item lg={6}
            container
            justifyContent={"center"}
            alignItems={"center"}>
            <Box className={classes.wrappImages}>
              <img
                src={StoreSpaceLogo}
                alt={"Store Space Logo"}
                className={classes.imageLogo}
              />
              <IconClick className={classes.imageClickIcon} strokeWidth={1}/>
            </Box>
          </Grid>
          <Grid item lg={6}
            className={classes.containerForm}>
            {!passwordReset
              ? (
                <Grid
                  item
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={classes.outerGrid}
                >
                  <Typography variant={"h2"} className={classes.title}
                    pb={"50px"}>
                    Create New<br/>Login Information
                  </Typography>
                  <form
                    className={classes.form}
                    data-testid={"confirm-forgot-password-form"}
                    onSubmit={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      formik.handleSubmit();
                    }}
                  >
                    <Grid container
                      item
                      direction={"column"}
                      className={classes.containerFormInterior}
                    >
                      <Typography variant={"body2"} sx={{ marginBottom: "20px" }}>Please enter a password containing minimum 12 characters that has at least one uppercase letter, one lowercase letter, one special character, one number and they are not repeating more than twice!</Typography>
                      <label htmlFor={"confirm-forgot-password"}><Typography variant={"subtitle2"}>New Password</Typography></label>
                      <TextField
                        id={"confirm-forgot-form-password"}
                        className={classes.field}
                        data-testid={"forgot-form-password"}
                        aria-describedby={"password-text"}
                        margin={"dense"}
                        variant={"outlined"}
                        inputProps={{ "data-testid": "password-input" }}
                        type={isPasswordShown ? "text" : "password"}
                        InputProps={{ endAdornment: toggleVisibilityButton }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleNewPassword("newPassword", e.target.value)
                        }
                      />
                      {values.newPassword && errorElements}
                      {!values.newPassword && submitClicked && <Typography variant={"body3"} style={{ color: theme.palette.error.main }}>New password is required.</Typography>}
                      {passwordIsUsed && <Box style={{ display: "flex", alignItems: "center" }}><IconX style={{ color: theme.palette.error.main, width: "14px", height: "14px" }} /><Typography variant={"body3"} style={{ color: theme.palette.error.main }}>Cannot match a previously used password.</Typography></Box>}
                      <label htmlFor={"confirm-forgot-repeat-password"}><Typography variant={"subtitle2"}>Confirm Password</Typography></label>
                      <TextField
                        id={"confirm-forgot-repeat-password"}
                        className={classes.field}
                        data-testid={"forgot-repeat-password"}
                        aria-describedby={"repeat-password-text"}
                        margin={"dense"}
                        variant={"outlined"}
                        inputProps={{ "data-testid": "repeat-password-input" }}
                        type={isPasswordShown ? "text" : "password"}
                        InputProps={{ endAdornment: toggleVisibilityButton }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleNewConfirmPassword("newConfirmPassword", e.target.value)
                        }
                      />
                      {values.newConfirmPassword && (errors.newConfirmPassword ? <Typography variant={"body3"} style={{ color: theme.palette.error.main }}>Both passwords must match.</Typography> : <Typography variant={"body3"} style={{ color: theme.palette.success.main }}>Passwords match. Success!</Typography>)}
                      {!values.newConfirmPassword && submitClicked && <Typography variant={"body3"} style={{ color: theme.palette.error.main }}>Confirm new password is required.</Typography>}
                      <Button
                        variant={"contained"}
                        size={"large"}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSubmitClicked(true);
                          handleSubmit();
                        }}
                        className={" mt-5 " + classes.forgotButton}
                        data-testid={"forgot-form-button"}
                      >
                        {loading
                          ? <CircularProgress
                              size={"2rem"}
                              data-testid={"forgot-form-button-spinner"}
                              className={classes.spinner}
                          />
                          : <Typography variant={"button"} style={{ color: theme.buttonColor }}>Reset Login Information</Typography>
                        }
                      </Button>
                    </Grid>

                  </form>
                  <Link
                    type={"button"}
                    component={RouterLink}
                    variant={"body2"}
                    to={"/"}
                    className={classes.linkBack}
                  >
                    <IconArrowBack className={classes.backIcon} />
                    <Typography variant={"button"} className={classes.linkBack}>Go Back To Sign In</Typography>
                  </Link>
                  {resetPasswordErrorDisplay}
                </Grid>)
              : (<Paper
                  elevation={3}
                  className={classes.forgotPaper}
              >
                <Grid
                  item
                  container
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={classes.outerGrid}
                >
                  <IconCircleCheck className={classes.checkCircle} />
                  <Typography variant={"h2"} className={classes.title}>
                    New Login Information<br/>has been saved!
                  </Typography>
                  <Link
                    type={"button"}
                    component={RouterLink}
                    variant={"body2"}
                    to={"/"}
                    className={classes.linkBack}
                  >
                    <IconArrowBack className={classes.backIcon} />
                    <Typography variant={"button"} className={classes.linkBack}>Go Back To Sign In</Typography>
                  </Link>
                </Grid>
              </Paper>)}
          </Grid>
        </Grid>
      </main>
    </StyledEngineProvider>
  );
};

export default ResetPasswordForm;
