import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()(() => ({
  root: {
    height: "100%",
    width: "100%"
  },
  businessInfoFooter: {
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex"
  }
}));

export default useStyles;
