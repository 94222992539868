import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: "3rem"
  },
  listCheckboxes: {
    width: "76%"
  },
  wrappStepperButtons: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "right",
    marginTop: "40px"
  },
  backButton: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    border: `1px solid ${theme.gray500Base}`,
    padding: "10px 20px",
    width: "45px",
    height: "16px",
    background: theme.palette.common.white,
    borderRadius: "4px"
  },
  cancel: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    marginRight: "5px",
    marginTop: "35px"
  },
  switch: {
    marginLeft: "30px !important"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  formControlLabelRow: {
    justifyContent: "space-between",
    marginLeft: 0,
    width: "100%"
  },
  subtitle: {
    marginBottom: "50px",
    paddingBottom: "15px",
    borderBottom: `1px solid ${theme.gray500Base}`
  },
  appicationList: {
    display: "flex",
    flexDirection: "row"
  },
  applicationListItem: {
    width: "calc(50% - 50px)",
    paddingRight: "50px"
  }
}));

export default useStyles;
