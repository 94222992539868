import { createAsyncThunk } from "@reduxjs/toolkit";

import { loginInstance as axios } from "../../../api/axiosInstance";
import { Store } from "../../Store";

export const login = createAsyncThunk(
  "login",
  (_, { getState, rejectWithValue }) => {
    const state = getState() as Store;
    const email = state.login.email;
    const password = state.login.password;

    if (!email) {
      return rejectWithValue("Oops! Please try again. Your email is incorrect.");
    }

    if (!password) {
      return rejectWithValue("Oops! Please try again. Your password is incorrect.");
    }

    const payload = {
      email,
      password
    };

    return axios.post("", payload)
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        if (error.response.data?.status === 401) {
          return rejectWithValue("Oops! Please try again. Either your email or password are incorrect.");
        }

        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.detail || error.response.data.message);
        }

        return rejectWithValue("Please try again later, we're experiencing a server error. We're working on it!");
      });
  }
);
