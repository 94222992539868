import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Role } from "src/models/Role";
import { RootState } from "src/store/rootStore";
import { createRole } from "src/store/thunks/role/create/createRole";

import { NewRoleSliceState } from "./NewRoleSliceState";

export const initialState: NewRoleSliceState = {
  role: {
    id: 1,
    name: "",
    color: "",
    ident: "",
    description: "",
    operator_id: 1,
    permissions: [""],
    is_active: 1
  },
  newRoleError: "",
  newRoleLoading: false
};

export const newRoleSlice = createSlice({
  name: "newRole",
  initialState,
  reducers: {
    createNewRoleSlice: (state: NewRoleSliceState) => {
      Object.assign(state, initialState);
    },
    setRoleName: (state, action: PayloadAction<string>) => {
      state!.role!.name = action.payload;
    },
    setRoleColor: (state, action: PayloadAction<string>) => {
      state!.role!.color = action.payload;
    },
    setRoleDescription: (state, action: PayloadAction<string>) => {
      state!.role!.description = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRole.pending, (state) => {
        state.newRoleLoading = true;
      })
      .addCase(createRole.fulfilled, (state, action: PayloadAction<Role>) => {
        state.newRoleLoading = false;
        state.role = action.payload;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.newRoleLoading = false;
        state.newRoleError = action.payload as string;
      });
  }
});

export const selectRole = (state: RootState) => state.newRole;
export const selectRoleName = (state: RootState) => state.newRole?.role?.name ?? "";
export const selectRoleColor = (state: RootState) => state.newRole?.role?.color ?? "";
export const selectRoleDescription = (state: RootState) => state.newRole?.role?.description ?? "";

export const { createNewRoleSlice, setRoleName, setRoleColor, setRoleDescription } =
newRoleSlice.actions;

export default newRoleSlice.reducer;
