import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { CreateRolePayload } from "./createRolePayload";

export const createRole = createAsyncThunk(
  "createRole",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const newRole = state.newRole;

    const payload: CreateRolePayload = {
      name: newRole.role?.name || "",
      color: newRole.role?.color || "",
      description: newRole.role?.description || "Can perform all facility management tasks",
      operator_id: newRole.role?.operator_id || 1,
      permissions: newRole.role?.permissions || [""],
      is_active: newRole.role?.is_active || 1
    };

    return axios.post("/api/v1/roles", payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Role created successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error creating the role";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
