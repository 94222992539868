import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const updateOperatorApplications = createAsyncThunk(
  "updateOperatorApplications",
  async(id: string | undefined, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const state = getState() as RootState;
    const selectedApplications = state.editOperator.operator?.selectedApplications;

    return axios.post(`/api/v1/operators/${id}/applications`, {
      applications: selectedApplications
    })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Operator edited successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.messages);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error editing the operator";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
