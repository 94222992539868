import React, { useCallback, useEffect, useState } from "react";

import { Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from "@mui/material";
import { IconSearch } from "@tabler/icons-react";
import debounce from "lodash/debounce";

import { usePagination } from "src/components/hooks/usePagination";
import TableReusablePagination from "src/components/ui/TableReusablePagination/TableReusablePagination";
import { Facility } from "src/models/Facility";
import { UserAssignments } from "src/models/UserAssignments";
import { APIListResponse } from "src/models/responses/ListResponse";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectRoles } from "src/store/reducers/roles/rolesSlice";
import { getFacilities } from "src/store/thunks/facilities/getAll/getFacilities";
import { getRoles } from "src/store/thunks/roles/getRoles";

import TrashIcon from "../../../assets/trash_icon.svg";

import useStyles from "./ManageAssignments.styles";

interface ManageAssignmentsProps {
  userAssignments: UserAssignments[]
    handleAssignmentsChange: Function,
    idx: number,
    assignment: UserAssignments
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const ManageAssignments: React.FC<ManageAssignmentsProps> = ({ userAssignments, handleAssignmentsChange, idx, assignment }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [facilities, setFacitilies] = useState<APIListResponse<Facility[]>>();
  const [isLoading, setIsLoading] = useState(false);

  const {
    page,
    rowsPerPage,
    search,
    setSearch,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination("facilitiesTable");

  useEffect(() => {
    dispatch(getRoles({
      page: 1,
      pageSize: 1000,
      search: ""
    }));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getFacilities({
      page: 1,
      pageSize: 10000,
      search
    })).then((resp: any) => {
      setFacitilies(resp.payload);
      setIsLoading(false);
    });
  }, []);

  const handleRolesOnchange = (
    index: number,
    fieldValue: Array<string>
  ) => {
    const updatedAssignments = structuredClone(userAssignments);
    const arrOfNum = fieldValue?.map(str => {
      return parseInt(str, 10);
    });
    updatedAssignments[index].roles = arrOfNum;
    handleAssignmentsChange(updatedAssignments);
  };

  const handleDeleteAssignment = (
    index: number
  ) => {
    const updatedAssignments = structuredClone(userAssignments);
    updatedAssignments.splice(index, 1);
    handleAssignmentsChange(updatedAssignments);
  };

  const handleSelectAllFacilities = (fieldValue: boolean) => {
    const updatedAssignments = structuredClone(userAssignments);
    if (fieldValue) {
      updatedAssignments[idx].facilities = facilities?.data?.map(facility => facility.id) || [];
    } else {
      updatedAssignments[idx].facilities = [];
    }
    handleAssignmentsChange(updatedAssignments);
  };

  const handleFacilityOnchange = (
    index: number,
    facilityId: number,
    fieldValue: boolean
  ) => {
    const updatedAssignments = structuredClone(userAssignments);
    if (fieldValue) {
      updatedAssignments[index].facilities.push(facilityId);
    } else {
      const idx = updatedAssignments[index].facilities.indexOf(facilityId);
      updatedAssignments[index].facilities.splice(idx, 1);
    }

    handleAssignmentsChange(updatedAssignments);
  };

  const handleSearch = useCallback(debounce((searchTerm: string) => {
    setIsLoading(true);
    handleChangePage(0);
    dispatch(getFacilities({
      page: 1,
      pageSize: 10000,
      search: searchTerm
    })).then((resp: any) => {
      setFacitilies(resp.payload);
      setIsLoading(false);
    });
  }, 500), []);

  const roles = useAppSelector(selectRoles);

  return (
    <Grid key={idx} container>
      <Grid item xs={12} className={classes.applicationAccessTitle}>
        <Typography variant={"h6"}>Assignment {idx + 1}</Typography>
        <IconButton onClick={() => handleDeleteAssignment(idx)}>
          <img
            src={TrashIcon}
            alt={"Trash Icon"}
            className={classes.applicationAccessTitleIcon}
            />
        </IconButton>
      </Grid>
      <Grid item xs={12} >
        <InputLabel htmlFor={"user-assignments"}>
          <Typography variant={"subtitle2"}>Selected Role</Typography>
        </InputLabel>
        <FormControl sx={{ m: 0, width: "40%" }}>
          <Select
            id={"user-assignments"}
            name={"userAssignmentsRoles"}
            multiple
            value={assignment.roles}
            onChange={(e) => {
              const select = e.target as HTMLInputElement;
              const value = select.value;
              handleRolesOnchange(idx, typeof value === "string" ? value.split(",") : value);
            }}
            input={<OutlinedInput label={"Tag"} />}
            renderValue={(selected) => {
              const roleNames = selected?.map(roleName => {
                return roles.data?.find(role => role.id === roleName);
              });
              return roleNames?.map(role => role?.name).join(", ");
            }}
            MenuProps={MenuProps}
              >
            {roles.data?.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                <Checkbox checked={assignment.roles.indexOf(role.id) > -1} />
                <ListItemText primary={<Typography variant={"body2"}>{role.name}</Typography>} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.applicationAccessSubtitle}>
        <Typography variant={"subtitle2"}>Select Facilities</Typography>
      </Grid>
      <Grid item xs={9} className={classes.searchItems}>
        <FormControlLabel
          labelPlacement={"start"}
          label={<Typography variant={"subtitle2"}>Select All</Typography>}
          control={<Switch checked={userAssignments[idx].facilities.length === facilities?.data.length} onChange={(e) => handleSelectAllFacilities(e.target.checked)}/>}
              />
        <TextField
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            handleSearch(event.target.value);
          }}
          className={classes.filterInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconSearch className={classes.filterInputIcon} />
              </InputAdornment>
            )
          }}
          placeholder={"Search"}
          />
      </Grid>
      {isLoading
        ? <Grid item xs={12} className={classes.spinner}>
          <CircularProgress
            size={"8rem"}
            data-testid={"facilities-spinner"}
              />
        </Grid>
        : facilities?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((facility) => (
          <Grid item alignItems={"center"}
            xs={4}
            lg={3}
            xl={3}
            key={facility.id}>
            <FormControlLabel
              label={<Typography variant={"body2"}>{facility.name}</Typography>}
              value={assignment.facilities}
              control={
                <Checkbox
                  name={facility.name}
                  color={"primary"}
                  checked={assignment.facilities.indexOf(facility.id) > -1}
                  onChange={(e) => {
                    handleFacilityOnchange(idx, facility.id, e.target.checked);
                  }}
                />
              }
            />
          </Grid>
        ))}
      <Grid item xs={12} className={classes.pagination}>
        <TableReusablePagination
          count={facilities?.meta?.total || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={(_event, page) => handleChangePage(page)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          style={{
            borderBottom: "none"
          }}
          component={"div"}
            />
      </Grid>
    </Grid>
  );
};

export default ManageAssignments;
