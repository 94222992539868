import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  applicationAccessTitle: {
    display: "flex",
    marginTop: "25px",
    paddingTop: "5px",
    marginBottom: "20px"
  },
  applicationAccessTitleIcon: {
    width: "16px",
    marginLeft: "13px",
    marginTop: "-5px"
  },
  applicationAccessContent: {
    width: "100%",
    marginTop: "15px"
  },
  applicationCols: {
    padding: "5px"
  },
  userDetailsField: {
    display: "inline-block",
    [theme.breakpoints.down("lg")]: {
      width: "45%",
      marginRight: "2.5%",
      marginLeft: "2.5%",
      marginBottom: "25px"
    },
    width: "24%"
  },
  applicationAccessSubtitle: {
    marginTop: "30px"
  },
  viewBtn: {
    color: theme.palette.primary.main
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end"
  },
  spinner: {
    display: "flex",
    justifyContent: "center"
  },
  filterInput: {
    float: "right",
    width: "220px",
    height: "50px"
  },
  filterInputIcon: {
    color: theme.palette.primary.main,
    width: "24px"
  },
  searchItems: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    marginTop: "20px"
  }
}));

export default useStyles;
