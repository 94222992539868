import React from "react";

import { Box, IconButton, TablePagination, useTheme } from "@mui/material";
import { IconChevronLeft, IconChevronLeftPipe, IconChevronRight, IconChevronRightPipe } from "@tabler/icons-react";

import useStyles from "./TableReusablePagination.styles";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={"first page"}
      >
        {theme.direction === "rtl" ? <IconChevronRightPipe /> : <IconChevronLeftPipe />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={"previous page"}
      >
        {theme.direction === "rtl"
          ? (
            <IconChevronRight />
            )
          : (
            <IconChevronLeft />
            )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={"next page"}
      >
        {theme.direction === "rtl"
          ? (
            <IconChevronLeft />
            )
          : (
            <IconChevronRight />
            )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={"last page"}
      >
        {theme.direction === "rtl" ? <IconChevronLeftPipe /> : <IconChevronRightPipe />}
      </IconButton>
    </Box>
  );
}

interface TableReusablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  style?: any;
  component?: React.ElementType<any>;
}

const TableReusablePagination: React.FC<TableReusablePaginationProps> = ({
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  style = {},
  component = "td"
}) => {
  const { classes } = useStyles();
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      colSpan={3}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          "aria-label": "rows per page"
        },
        native: true
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      sx={style}
      component={component}
      className={classes.root}
    />
  );
};

export default TableReusablePagination;
