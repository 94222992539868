import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: "3rem",
    marginTop: "1rem",
    boxShadow: "none",
    borderRadius: "10px"
  },
  addAssignmentButton: {
    backgroundColor: theme.palette.primary.contrastText,
    cursor: "pointer",
    marginLeft: "23px",
    width: "202px",
    height: "36px"
  },
  titleWrapper: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItens: "center",
    borderBottom: `1px solid ${theme.gray500Base}`,
    paddingBottom: "10px",
    alignItems: "center"
  },
  assignmentError: {
    marginTop: "20px",
    color: theme.palette.error.main
  }
}));

export default useStyles;
