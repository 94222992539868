import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { AddApplicationsToOperatorPayload } from "./AddApplicationsToOperatorPayload";

export const addApplicationsToOperator = createAsyncThunk(
  "addApplicationsToOperator",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const businessInformation = state.businessInformation;
    const applications = state.applications.applications;

    const operatorId = businessInformation.operatorId;
    const payload: AddApplicationsToOperatorPayload = {
      applications: applications.filter(item => item.enabled === true).map(item => item.id)
    };

    return axios.post(`/api/v1/operators/${operatorId}/applications`, payload)
      .then((resp) => {
        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.messages);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error adding the applications to operator";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
