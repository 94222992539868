import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import ApplicationsReducer from "./reducers/applications/applicationsSlice";
import AuthenticationReducer from "./reducers/auth/authSlice";
import BusinessInformationReducer from "./reducers/businessInformation/businessInformationSlice";
import ConfirmUserReducer from "./reducers/confirmUser/confirmUserSlice";
import CustomRolesSliceReducer from "./reducers/customRoles/customRolesSlice";
import DashboardReducer from "./reducers/dashboard/dashboardSlice";
import DefaultRolesSliceReducer from "./reducers/defaultRoles/defaultRolesSlice";
import EditOperatorReducer from "./reducers/editOperator/editOperatorSlice";
import EditRoleReducer from "./reducers/editRole/editRoleSlice";
import EditUserReducer from "./reducers/editUser/editUserSlice";
import FacilitiesReducer from "./reducers/facilities/facilitiesSlice";
import forgotPasswordReducer from "./reducers/forgotPassword/forgotPasswordSlice";
import LoginReducer from "./reducers/login/loginSlice";
import LoginActivityReducer from "./reducers/loginActivity/loginActivitySlice";
import LogoutReducer from "./reducers/logout/logoutSlice";
import NavigationReducer from "./reducers/navigation/navigationSlice";
import NewRoleReducer from "./reducers/newRole/newRoleSlice";
import NewUserReducer from "./reducers/newUser/newUserSlice";
import OnboardOperatorReducer from "./reducers/onboardOperator/onboardOperatorSlice";
import OperatorsReducer from "./reducers/operators/operatorsSlice";
import resetPasswordReducer from "./reducers/resetPassword/resetPasswordSlice";
import RolesReducer from "./reducers/roles/rolesSlice";
import SnackbarReducer from "./reducers/snackbar/snackbarSlice";
import UserAuditReducer from "./reducers/userAudit/userAuditSlice";
import UserInfoReducer from "./reducers/userInfo/userInfoSlice";
import UsersReducer from "./reducers/users/usersSlice";

export const rootStore = configureStore({
  reducer: {
    applications: ApplicationsReducer,
    authentication: AuthenticationReducer,
    businessInformation: BusinessInformationReducer,
    confirmUser: ConfirmUserReducer,
    dashboard: DashboardReducer,
    editRole: EditRoleReducer,
    forgotPassword: forgotPasswordReducer,
    roles: RolesReducer,
    login: LoginReducer,
    logout: LogoutReducer,
    navigation: NavigationReducer,
    newUser: NewUserReducer,
    newRole: NewRoleReducer,
    onboardOperator: OnboardOperatorReducer,
    snackbar: SnackbarReducer,
    userInfo: UserInfoReducer,
    editUser: EditUserReducer,
    users: UsersReducer,
    loginActivity: LoginActivityReducer,
    facilities: FacilitiesReducer,
    operators: OperatorsReducer,
    userAudit: UserAuditReducer,
    editOperator: EditOperatorReducer,
    resetPassword: resetPasswordReducer,
    customRoles: CustomRolesSliceReducer,
    defaultRoles: DefaultRolesSliceReducer
  }
});

export type AppDispatch = typeof rootStore.dispatch;
export type RootState = ReturnType<typeof rootStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
