import { createAsyncThunk } from "@reduxjs/toolkit";

import { Store } from "src/store/Store";

import { axiosInstance as axios } from "../../../api/axiosInstance";
import { showSnackbar } from "../../reducers/snackbar/snackbarSlice";

type meta = {
  page: number,
  pageSize: number,
  search: string
}

export const getRoles = createAsyncThunk(
  "getRoles",
  async(meta: meta, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const state = getState() as Store;
    const operatorId = state.userInfo.user?.operator_id;
    const userType = state.userInfo.user?.user_type;
    const pageSize = meta.pageSize;
    const page = meta.page;
    const url = userType === "operator-agnostic" ? "roles" : `operators/${operatorId}/roles`;
    const filterName = meta.search && ("&filter[name]=" + encodeURIComponent(`${meta.search}`));

    return axios.get(`/api/v1/${url}?pageSize=${pageSize}&page=${page}${filterName}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Roles";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
