import { GridSortModel } from "@mui/x-data-grid";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { Store } from "../../../Store";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

type meta = {
  page: number,
  pageSize: number,
  search: string,
  sortOptions: GridSortModel
}

export const getUsers = createAsyncThunk(
  "getUsers",
  async(meta: meta, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI;
    const state = getState() as Store;
    const userType = state.userInfo.user?.user_type;
    const operatorId = state.userInfo.user?.operator_id;
    const pageSize = meta.pageSize;
    const page = meta.page;
    const order = meta.sortOptions[0]?.field;
    const orderDirection = meta.sortOptions[0]?.sort;
    const filter = userType === "operator-agnostic" ? "" : `&filter[operator_id]=${operatorId}`;
    const filterName = meta.search && ("&filter[first_name]=" + encodeURIComponent(`${meta.search}`) + "&orFilter[last_name]=" + encodeURIComponent(`${meta.search}`) + "&orFilter[email]=" + encodeURIComponent(`${meta.search}`) + "&orFacilityId=" + encodeURIComponent(`${meta.search}`));
    const sort = order ? `&order=${order}&orderDirection=${orderDirection}` : "";

    return axios.get(`/api/v1/users?with[]=roles&with[]=facilities&pageSize=${pageSize}&page=${page}${sort}${filter}${filterName}`)
      .then((resp) => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error getting the Users";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
