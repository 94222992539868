import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  linkBack: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    marginBottom: "35px",
    marginTop: "35px",
    display: "block !important"
  },
  subtitleText: {
    color: theme.palette.primary.main
  },
  backArrow: {
    width: "10px",
    marginBottom: "-7px"
  },
  wrappSubtitle: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.gray500Base}`,
    paddingBottom: "20px",
    marginBottom: "25px"
  },
  containerToggleContent: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "10px"
    },
    justifyContent: "center"
  },
  userDetailsField: {
    display: "inline-block",
    [theme.breakpoints.down("lg")]: {
      width: "45%",
      marginRight: "2.5%",
      marginLeft: "2.5%",
      marginBottom: "25px"
    },
    width: "24%",
    marginRight: "1%"
  },
  userDetailsFieldSwitch: {
    display: "inline-block",
    [theme.breakpoints.down("lg")]: {
      width: "45%",
      marginRight: "2.5%",
      marginLeft: "2.5%",
      marginBottom: "25px"
    },
    width: "30%",
    marginRight: "1%",
    marginLeft: "1%"
  },
  userDetailsFieldsDown: {
    marginTop: "20px"
  },
  applicationAccessTop: {
    padding: "5px 20px 5px 5px"
  },
  applicationAccessToggle: {
    float: "right"
  },
  formInputGroup: {
    width: "260px",
    float: "right",
    marginRight: "-25px"
  },
  formInput: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0 !important"
    }
  },
  confirmCancelButton: {
    height: "40px",
    padding: "0 16px 0 16px",
    lineHeight: "40px",
    textTransform: "uppercase",
    marginTop: "16px",
    textDecoration: "none",
    boxShadow: "none",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "left"
    }
  },
  confirmButton: {
    height: "40px",
    textTransform: "uppercase",
    marginTop: "16px",
    marginLeft: "40px",
    background: theme.palette.primary.contrastText,
    float: "right"
  },
  spinner: {
    color: theme.palette.common.white
  },
  applicationCols: {
    padding: "5px"
  },
  wrappPaper: {
    width: "96%",
    [theme.breakpoints.down("lg")]: {
      width: "75%"
    },
    [theme.breakpoints.down("md")]: {
      width: "96%"
    },
    padding: "32px 40px 40px 40px",
    borderRadius: "10px",
    boxShadow: "none"
  },
  wrappPaperDown: {
    width: "96%",
    [theme.breakpoints.down("lg")]: {
      width: "75%"
    },
    [theme.breakpoints.down("md")]: {
      width: "96%"
    },
    padding: "40px",
    borderRadius: "10px",
    boxShadow: "none",
    marginTop: "25px"
  },
  addAssignmentHeader: {
    width: "403px"
  },
  addAssignmentSubheader: {
    width: "280px"
  },
  applicationAccessTitleIcon: {
    width: "18px"
  },
  addAssignmentButton: {
    backgroundColor: theme.palette.primary.contrastText,
    cursor: "pointer",
    marginLeft: "23px",
    width: "202px",
    height: "36px"
  },
  applicationAccessContent: {
    width: "100%",
    padding: "5px 20px 5px 5px"
  }
}));

export default useStyles;
