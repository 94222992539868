import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  onboardDetailsForm: {
    boxShadow: "none",
    border: `1px solid ${theme.gray500Base}`,
    borderRadius: "10px",
    backgroundColor: theme.graySecondary50
  }
}));

export default useStyles;
