import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  linkBack: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    marginBottom: "35px",
    marginTop: "35px",
    display: "block !important"
  },
  backArrow: {
    width: "10px",
    marginBottom: "-7px"
  }
}));

export default useStyles;
