
import * as Yup from "yup";

import { emailRegex } from "src/utils/validateEmail/validateEmail";

export enum validationErrors {
  min12 = "Must be a minimum of 12 characters",
  lowercase = "At least one lowercase letter",
  uppercase = "At least one uppercase letter",
  specialAndNumber = "One special character and one number"
}

export const confirmationUserFormValidation = Yup.object().shape({
  confirmUserFirstName: Yup.string().required("First Name is required."),
  confirmUserLastName: Yup.string().required("Last Name is required."),
  confirmUserEmail: Yup.string().matches(emailRegex, "Email is invalid.").required("Email is required."),
  confirmPassword: Yup.string()
    .required("Required")
    .min(12, validationErrors.min12)
    .matches(/(.*[a-z].*)/, validationErrors.lowercase)
    .matches(/(.*[A-Z].*)/, validationErrors.uppercase)
    .matches(/^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/, validationErrors.specialAndNumber),
  confirmPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref("confirmPassword")])
    .required("Confirm new password is required")
});
