import { createAsyncThunk } from "@reduxjs/toolkit";

import { Role } from "src/models/Role";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const updateRoleState = createAsyncThunk(
  "updateRoleState",
  async(role: Role | undefined, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    return axios.post(`/api/v1/roles/${role?.id}/update-state`, {
      state: Number(!role?.is_active)
    })
      .then((resp) => {
        dispatch(showSnackbar({
          message: "Role updated successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.errors);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error when updating the role";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
