import React, { FC } from "react";

import { Grid, Typography } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";

import { Link } from "react-router-dom";

import { ReactComponent as ErrorIcon } from "src/assets/icons/500.svg";

import useStyles from "./Redirect500.styles";
interface Redirect500Props{}

const Redirect500: FC<Redirect500Props> = () => {
  const { classes } = useStyles();
  return (
    <Grid container >
      <Grid
        item
        xs={12}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
        height={"90vh"}
      >
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          <Grid display={"flex"} alignItems={"center"} justifyContent={"center"}
            flexDirection={"column"}>
            <Typography className={classes.statusErrorText}>
              ERROR
            </Typography>
            <Typography className={classes.statusText}>
              500
            </Typography>
          </Grid>

          <ErrorIcon/>

          <Grid>
            <Typography className={classes.titleText}>
              Uh oh!
            </Typography>
            <p className={classes.subtitleText}>
              Looks like something went wrong. It’s not you, it’s us. <br/>
              We’ll work on getting that fixed.
            </p>
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
            >
                <IconArrowLeft/>
                <Typography className={classes.returnText}>
                  Return to dashboard
                </Typography>
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Redirect500;
