import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  paper: {
    padding: "22.5px 25px 24px 25px",
    borderRadius: "10px",
    boxShadow: "none"
  },
  paperTitle: {
    borderBottom: `1px solid ${theme.gray500Base}`,
    paddingBottom: "22.5px",
    fontWeight: 500
  },
  paperContent: {
    marginTop: "20px"
  },
  linkBack: {
    color: theme.palette.primary.contrastText,
    textDecoration: "none",
    marginTop: "40px",
    marginBottom: "40px",
    display: "block !important"
  },
  backArrow: {
    width: "10px",
    marginBottom: "-7px"
  },
  tabAccess: {
    color: theme.palette.primary.main,
    padding: 0,
    textAlign: "left",
    marginRight: "15px",
    fontWeight: 400
  },
  subtitle: {
    color: theme.palette.primary.main,
    marginTop: "45px",
    marginBottom: "25px"
  },
  basicInformation: {
    backgroundColor: theme.graySecondary75,
    padding: "40px",
    borderRadius: "10px"
  },
  addressInformation: {
    padding: "40px 0 0"
  },
  applicationAccess: {
    padding: "40px 0 0"
  },
  applicationAccessContent: {
    padding: "25px 20px 0 20px"
  },
  listCheckboxes: {
    width: "100%"
  },
  formControlLabelRow: {
    justifyContent: "space-between",
    marginLeft: 0,
    width: "100%",
    fontWeight: 700
  },
  switch: {
    marginLeft: "30px !important"
  },
  paperSubtitle: {
    borderBottom: `1px solid ${theme.gray500Base}`,
    paddingBottom: "8px",
    fontWeight: 500,
    textAlign: "center"
  },
  appicationList: {
    display: "flex",
    flexDirection: "row"
  },
  applicationListItem: {
    width: "100%"
  },
  basicInformationForm: {
    display: "block",
    maxWidth: "300px",
    margin: "25px auto auto"
  },
  addressInformationPadding: {
    padding: "0 30px 0 30px"
  },
  addressInformationForm: {
    margin: "25px 0 0"
  },
  wrappButtons: {
    display: "block",
    float: "right"
  },
  buttonsItems: {
    display: "inline-block"
  },
  cancelButton: {
    textTransform: "uppercase",
    textDecoration: "none"
  },
  saveButton: {
    width: "78px",
    height: "36px",
    background: theme.palette.primary.contrastText,
    borderRadius: "4px",
    textTransform: "uppercase",
    color: theme.palette.common.white,
    marginLeft: "51px"
  },
  infoIcon: {
    width: "18px",
    marginBottom: "-6px",
    marginLeft: "6px"
  },
  spinner: {
    color: theme.palette.common.white
  }
}));

export default useStyles;
