import React, { ReactElement, useCallback, useEffect } from "react";

import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconSearch } from "@tabler/icons-react";
import { debounce } from "lodash";

import { usePagination } from "src/components/hooks/usePagination";
import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { UserAudit } from "src/models/UserAudit";
import { AppBreadcrumb } from "src/models/app-breadcrumb";
import { APIListResponse } from "src/models/responses/ListResponse";
import { selectLoading, selectUserAudit } from "src/store/reducers/userAudit/userAuditSlice";
import { getUserAudit } from "src/store/thunks/userAudit/getAll/getUserAudit";

import { useAppDispatch, useAppSelector, useAppSelector as useSelector } from "../../store/hooks";

import useStyles from "./UserAuditLog.styles";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Users"
  },
  {
    name: "User Audit Log",
    bold: true
  }
];

const UserAuditLog: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const {
    search,
    setSearch,
    handleChangePage,
    handleChangePaginationModel,
    paginationModel
  } = usePagination("userAuditTable");

  const userAudit: APIListResponse<UserAudit[]> = useAppSelector(selectUserAudit) || {};

  const loading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getUserAudit({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      search
    }));
  }, [paginationModel]);

  const handleSearch = useCallback(debounce((searchTerm: string) => {
    handleChangePage(0);
    handleChangePaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    dispatch(getUserAudit({
      page: 1,
      pageSize: paginationModel.pageSize,
      search: searchTerm
    }));
  }, 500), []);

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "User",
      flex: 0.8,
      filterable: false
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.8,
      filterable: false
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1.2,
      filterable: false
    },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 0.8,
      filterable: false
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 0.8,
      filterable: false
    },
    {
      field: "ip_address",
      headerName: "IP Address",
      flex: 0.8,
      filterable: false
    },
    {
      field: "application",
      headerName: "Application",
      flex: 0.8,
      filterable: false
    },
    {
      field: "action_name",
      headerName: "Activity",
      flex: 0.8,
      filterable: false
    }
  ];

  return (
    <Grid
      data-testid={"user-audit-log-view"}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      className={classes.root}
    >
      <AppLayout>
        <PageHeader
          title={"User Audit Log"}
          breadcrumbs={breadcrumbs}
        />
        <Grid
          container
          item
          lg={12}
          mt={5}
          mb={5}
        >
          <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", boxShadow: "none" }}>
            <Grid container item xs={12}
              style={{ padding: "0 25px 25px" }}>
              <Box className={classes.boxContent}>
                <Typography variant={"h6"} className={classes.titleTable}>User Audit Log</Typography>
                <TextField
                  value={search}
                  onChange={(event) => { setSearch(event.target.value); handleSearch(event.target.value); }}
                  placeholder={"Search"}
                  className={classes.filterInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <IconSearch className={classes.filterInputIcon} />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Grid item xs={12}>
                <DataGrid
                  rowCount={userAudit?.meta?.total || 0}
                  rows={userAudit.data || []}
                  columns={columns}
                  disableRowSelectionOnClick
                  paginationMode={"server"}
                  autoHeight
                  loading={loading}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page }
                    }
                  }}
                  onPaginationModelChange={handleChangePaginationModel}
                  pageSizeOptions={[5, 10, 25, 50]}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </AppLayout>
    </Grid>

  );
};

export default UserAuditLog;
