import React, { useState } from "react";

import {
  Grid,
  Tab,
  Tabs,
  Typography
} from "@mui/material";

import { useSearchParams } from "react-router-dom";

import PageHeader from "../../components/ui/PageHeader/PageHeader";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import { AppBreadcrumb } from "../../models/app-breadcrumb";
import ListRoles from "../ListRoles/ListRoles";
import ListUsers from "../ListUsers/ListUsers";
const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Users"
  },
  {
    name: "Manage Users & Roles",
    bold: true
  }
];

const ListUsersRoles: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("show");
  const [tab, setTab] = useState(activeTab === "Roles" ? 1 : 0);

  function handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
    setTab(newValue);
    setSearchParams("");
  }

  return (
    <Grid
      data-testid={"onboard-user-view"}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100%" }}
    >
      <AppLayout>
        <PageHeader
          title={"Manage Users & Roles"}
          breadcrumbs={breadcrumbs}
        />
        <>
          <Tabs value={tab} onChange={handleChangeTab}>
            <Tab label={<Typography variant={"h6"} style={{ textTransform: "none" }}>Users</Typography>} />
            <Tab label={<Typography variant={"h6"} style={{ textTransform: "none" }}>Roles</Typography>} />
          </Tabs>
          {tab === 0 && <ListUsers />}
          {tab === 1 && <ListRoles />}
        </>
      </AppLayout>
    </Grid>
  );
};

export default ListUsersRoles;
