import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: "22.5px 25px 24px 25px",
    borderRadius: "10px",
    boxShadow: "none"
  },
  boxContent: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "35px 0 20px 0",
    borderBottom: `1px solid ${theme.gray500Base}`
  },
  tabPanelGrid: {
    padding: "0 !important"
  },
  subtitle: {
    color: theme.palette.primary.main,
    marginTop: "45px",
    marginBottom: "25px"
  },
  titleTable: {
    display: "inline-block"
  },
  spinner: {
    color: theme.palette.common.white
  },
  filterInput: {
    float: "right",
    width: "220px",
    height: "50px"
  },
  filterInputIcon: {
    color: theme.palette.primary.main
  }
}));

export default useStyles;
