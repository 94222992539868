import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";

export const getUser = createAsyncThunk(
  "getUser",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const id = state.editUser.selectedUserId;

    return axios.get(`/api/v1/users/${id}`)
      .then(resp => {
        return resp.data;
      })
      .catch(() => {
        const errorMessage = "There was an error fetching the current user information 2";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
