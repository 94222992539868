import React, { ReactElement } from "react";

import { Container } from "@mui/material";

import { Link } from "react-router-dom";

const Redirect404: React.FC = (): ReactElement => (
  <main>
    <Container>
      <h1>
        404 Error
      </h1>
      <p>Click the link below to return to the dashboard.</p>
      <Link to={"/"}>Return home</Link>
    </Container>
  </main>
);

export default Redirect404;
