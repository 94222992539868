import React, { FC, PropsWithChildren } from "react";

import { Blocker, History, createBrowserHistory } from "history";

import { BrowserRouter } from "react-router-dom";

const history: History = createBrowserHistory();

const CustomRouter: FC<PropsWithChildren> = ({ children, ...props }) => {
  return (
    <BrowserRouter {...props}>
      {children}
    </BrowserRouter>
  );
};

export const rootNavigate = (to: string) => {
  history.push(to);
};

export const blockNavigation = (blocker: Blocker) => {
  return history.block(blocker);
};

export default CustomRouter;
