
import React, { ReactElement } from "react";

import { Box, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";

import { AppBreadcrumb } from "../../../models/app-breadcrumb";
import AppBreadcrumbs from "../../navigation/AppBreadcrumbs/AppBreadcrumbs";

import useStyles from "./PageHeader.styles";

interface PageHeaderProps {
  breadcrumbs?: AppBreadcrumb[]
  title: string
  subtitle?: string
}

const now = moment();

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, breadcrumbs }: PageHeaderProps): ReactElement => {
  const { classes } = useStyles();

  let subheadingDisplay;

  if (subtitle) {
    subheadingDisplay = (
      <Box
        mt={1}
        data-testid={"subtitle-display"}
      >
        <Typography
          variant={"body1"}
        >
          {subtitle}
        </Typography>
      </Box>
    );
  }

  if (breadcrumbs) {
    subheadingDisplay = <AppBreadcrumbs breadcrumbs={breadcrumbs} />;
  }

  const subheading = (
    <Box
      mt={1}
      mb={4}
      data-testid={"subtitle-display"}
    >
      <Typography variant={"body2"} component={"div"}>{subheadingDisplay}</Typography>
    </Box>
  );

  return (
    <Box mt={4}>
      <Grid
        container
        direction={"column"}
      >
        <Grid
          item
          container
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"h2"}
            fontWeight={500}
          >
            {title}
          </Typography>
          <Typography
            variant={"h6"}
            className={classes.date}
          >
            Today&apos;s date: {now.format("MMMM Do")}
          </Typography>
        </Grid>
        {subheading}
      </Grid>
      <Divider />
    </Box>
  );
};

export default PageHeader;
