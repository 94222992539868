import React, { ChangeEvent, useEffect, useState } from "react";

import { Box, Button, Grid, IconButton, InputLabel, Link, List, ListItem, Paper, TextField, TextareaAutosize, Typography, useTheme } from "@mui/material";
import { IconTrash } from "@tabler/icons-react";

import { useFormik } from "formik";

import { HexColorPicker } from "react-colorful";
import { useNavigate } from "react-router";
import { Link as RouterLink, useParams } from "react-router-dom";

import { User } from "src/models/User";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectEditRole, setEditRoleColor, setEditRoleDescription, setEditRoleName } from "src/store/reducers/editRole/editRoleSlice";
import { getRolesUsers } from "src/store/thunks/role/getRolesUsers/getRolesUsers";
import { removeUserFromRole } from "src/store/thunks/role/removeUserFromRole/removeUserFromRole";
import { updateRole } from "src/store/thunks/role/update/updateRole";

import RemoveUserDialog from "./RemoveUserDialog/RemoveUserDialog";

import useStyles from "./RoleInformation.styles";

const RoleInformation : React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [userToRemoveId, setUserToRemoveId] = useState<number>(0);
  const navigate = useNavigate();
  const theme = useTheme();

  const role = useAppSelector(selectEditRole);

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    dispatch(getRolesUsers(params.id)).then(resp => {
      if (!resp.type.includes("rejected")) {
        setUsers(resp.payload.data);
      }
    });
  }, []);

  const handleSubmit = () => {
    dispatch(updateRole());
  };

  const formik = useFormik({
    initialValues: {
      name: role.name || "",
      color: role.color || "",
      description: role.description || ""
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmit();
      navigate("/users-roles?show=Roles");
    }
  });

  const { values } = formik;

  const updateForm = (
    fieldName: string,
    fieldValue?: string | boolean
  ): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleNameChange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setEditRoleName(fieldValue));
  };

  const handleColorChange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setEditRoleColor(fieldValue));
  };

  const handleDescriptionChange = (
    fieldName: string,
    fieldValue: string
  ) => {
    updateForm(fieldName, fieldValue);
    dispatch(setEditRoleDescription(fieldValue));
  };

  const removeUser = (): void => {
    dispatch(removeUserFromRole(userToRemoveId)).then(() => {
      setOpen(false);
      dispatch(getRolesUsers(params.id)).then(resp => {
        if (!resp.type.includes("rejected")) {
          setUsers(resp.payload.data);
        }
      });
    });
  };

  return (
    <form
      data-testid={"edit-role-form"}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        formik.handleSubmit();
      }}
      >
      <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", boxShadow: "none" }}>
        <Grid
          container
          item
          pl={5}
          pr={5}
          pt={2}
          pb={2}
          mt={3}
          mb={3}
          spacing={1}
    >
          <Box className={classes.boxContent}>
            <Typography variant={"h2"} className={classes.title}>Update Role Information</Typography>
          </Box>
          <Grid
            item
            md={4}
            >
            <InputLabel htmlFor={"edit-role-name"}
              >
              <Typography variant={"subtitle2"}>Role Name</Typography>
            </InputLabel>
            <TextField
              className={classes.textInput}
              fullWidth
              id={"edit-role-name"}
              data-testid={"edit-role-name"}
              name={"editRoleName"}
              value={values.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleNameChange("name", e.target.value)
                }
              />
          </Grid>
          <Grid
            item
            md={4}
            >
            <InputLabel htmlFor={"edit-role-color"}
              >
              <Typography variant={"subtitle2"}>Role Color</Typography>
            </InputLabel>
            <TextField
              className={classes.textInput}
              fullWidth
              id={"edit-role-color"}
              data-testid={"edit-role-color"}
              name={"editRoleColor"}
              value={values.color}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleColorChange("color", e.target.value)
                }
              />
            <HexColorPicker
              color={values.color}
              onChange={(color: string) => handleColorChange("color", color === "#NaNNaNNaN" ? theme.palette.common.white : color)}
                />
          </Grid>

          <Grid item md={12}>
            <InputLabel htmlFor={"edit-role-description"}
              >
              <Typography variant={"subtitle2"}>Role Description</Typography>
            </InputLabel>
            <TextareaAutosize
              style={{ width: "100%", minHeight: "135px" }}
              id={"edit-role-description"}
              value={values.description}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleDescriptionChange("description", e.target.value)
                }
              />
          </Grid>
          <Grid item md={12}>
            <Typography sx={{ mt: 4, mb: 2 }} variant={"subtitle2"}>
              Role Members
            </Typography>
            <List className={classes.list}>
              {users?.map((user) => {
                return (
                  <ListItem key={user.id} secondaryAction={
                    <IconButton edge={"end"} aria-label={"delete"} onClick={() => {
                      setUserToRemoveId(user.id);
                      setOpen(true);
                    }}>
                      <IconTrash />
                    </IconButton>
                  }>{user.username}</ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} className={classes.wrappStepperButtons}>
            <Link
              type={"button"}
              component={RouterLink}
              variant={"body2"}
              to={"/users-roles?show=Roles"}
              className={classes.cancelLink}
              >
              <Button
                className={classes.cancelButton}
                variant={"text"}
                data-testid={"cancel-button"}
            >
                <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
              </Button>
            </Link>

            <Button
              variant={"contained"}
              size={"large"}
              type={"submit"}
              data-testid={"update-role-button"}
              className={classes.saveButton}
            >
              <Typography variant={"button"} style={{ color: theme.buttonColor }}>Save</Typography>
            </Button>
          </Grid>
        </Grid>
        <RemoveUserDialog open={open} setOpen={setOpen} removeUser={removeUser}/>
      </Paper>
    </form>
  );
};

export default RoleInformation;
