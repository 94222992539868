import React, { ReactElement } from "react";

import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import { IconChevronRight } from "@tabler/icons-react";

import { NavLink } from "react-router-dom";

import { AppBreadcrumb } from "src/models/app-breadcrumb";

import useStyles from "./AppBreadcrumbs.styles";

interface AppBreadcrumbsProps {
  breadcrumbs: AppBreadcrumb[]
}

const AppBreadcrumbs: React.FC<AppBreadcrumbsProps> = ({ breadcrumbs }: AppBreadcrumbsProps): ReactElement => {
  const theme = useTheme();
  const { classes } = useStyles({ bold: true });

  const fullBreadcrumbs = breadcrumbs.map(breadcrumb => (
    breadcrumb.linkPath
      ? <NavLink
          key={breadcrumb.name}
          className={classes.link}
          to={breadcrumb.linkPath}
          data-testid={`${breadcrumb.name}-breadcrumb-link`}
      >
        {breadcrumb.name}
      </NavLink>
      : <span
          key={breadcrumb.name}
          data-testid={`${breadcrumb.name}-breadcrumb-text`}
      >
        <Typography variant={"button"} style={{ color: breadcrumb.bold ? theme.palette.primary.contrastText : theme.gray400Light }}>{breadcrumb.name}</Typography>
      </span>
  ));

  return (
    <Breadcrumbs
      aria-label={"breadcrumb"}
      data-testid={"AppBreadcrumbs"}
      separator={<IconChevronRight style={{ color: `${theme.gray400Light}`, width: "16px", height: "16px" }} />}
    >
      <NavLink
        className={classes.link}
        to={"/"}
        data-testid={"Dashboard-breadcrumb-link"}
      >
        <Typography variant={"button"} style={{ color: theme.gray400Light }}>Dashboard</Typography>
      </NavLink>
      {fullBreadcrumbs}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
