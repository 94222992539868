import axios from "axios";

const loginInstance = axios.create({
  baseURL: window.config.loginUri,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
});

const noAuthInstance = axios.create({
  baseURL: window.config.identityApiUri,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
});

const axiosInstance = axios.create({
  baseURL: window.config.identityApiUri,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("bearerToken") || sessionStorage.getItem("bearerToken");
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.href = "/login";
      alert("Your session has expired! Please log in again.");
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, loginInstance, noAuthInstance };
