import React, { ReactElement } from "react";

import { Grid, Typography } from "@mui/material";

interface OnboardInformationProps {
  title: string
  advisoryText: string
}

const OnboardInformation: React.FC<OnboardInformationProps> = (props: OnboardInformationProps): ReactElement => {
  return (
    <Grid
      container
      item
      xs={12}
      py={6}
      px={4}
      flexDirection={"column"}
    >
      <Typography variant={"h1"} style={{ fontWeight: 400 }}>{props.title}</Typography>
      <Typography variant={"h7"} my={2}>{props.advisoryText}</Typography>
    </Grid>
  );
};

export default OnboardInformation;
