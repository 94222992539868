import React, { ReactElement, useEffect } from "react";

import {
  Box,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IconAlertCircle, IconFlag, IconShieldCheck } from "@tabler/icons-react";

import { usePagination } from "src/components/hooks/usePagination";
import { LoginActivity } from "src/models/LoginActivity";
import { APIListResponse } from "src/models/responses/ListResponse";
import { selectLoading, selectLoginActivity } from "src/store/reducers/loginActivity/loginActivitySlice";
import { getLoginActivity } from "src/store/thunks/loginActivity/getAll/getLoginActivity";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import useStyles from "./RecentActivityLog.styles";

const RecentActivityLog: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    handleChangePaginationModel,
    paginationModel
  } = usePagination("recentActivityTable");

  const loginActivity: APIListResponse<LoginActivity[]> = useAppSelector(selectLoginActivity) || {};

  const loading = useAppSelector(selectLoading);

  useEffect(() => {
    dispatch(getLoginActivity({
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }));
  }, [paginationModel]);

  const columns: GridColDef[] = [
    {
      field: "location",
      headerName: "Location",
      flex: 0.6,
      filterable: false
    },
    {
      field: "device",
      headerName: "Device - OS",
      flex: 0.8,
      filterable: false
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1.2,
      filterable: false
    },
    {
      field: "browser",
      headerName: "App/Service",
      flex: 0.8,
      filterable: false
    },
    {
      field: "ip_address",
      headerName: "IP Address",
      flex: 0.8,
      filterable: false
    },
    {
      field: "session",
      headerName: "Session",
      flex: 0.8,
      filterable: false,
      renderCell: (params) => {
        const isCurrentSession = params.id === loginActivity.data[0].id && paginationModel.page === 0;
        if (isCurrentSession === true) {
          return (
            <span className={classes.currentSession}>
              <IconShieldCheck className={classes.sessionIcon} />
              Current Session
            </span>
          );
        } else {
          return (
            <span className={classes.previousSession}>
              <IconAlertCircle className={classes.sessionIcon} />
              Previous Session
            </span>
          );
        }
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      filterable: false,
      renderCell: () => (
        <span className={classes.tableCellReport}>
          <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>Report Activity</Typography>} arrow>
            <IconFlag className={classes.flagIcon} />
          </Tooltip>
          <Typography variant={"body4"} className={classes.flagIconTxt}>Report</Typography>
        </span>
      )
    }

  ];

  return (
    <Grid
      container
      item
      lg={12}
      mt={5}
      mb={5}
        >
      <Typography variant={"body1"} mb={2}>You’re currently signed in on these devices or have been in the last 30 days. There might be multiple activity sessions from the same device. Report any suspicious behavior using the actions below.</Typography>
      <Paper elevation={4} style={{ width: "100%", borderRadius: "10px", boxShadow: "none" }}>
        <Grid container item xs={12}
          style={{ padding: "0 25px 25px" }}>
          <Box className={classes.boxContent}>
            <Typography variant={"h6"} className={classes.titleTable}>Recent Activity Log</Typography>
          </Box><Grid item xs={12}>
            <DataGrid
              rowCount={loginActivity?.meta?.total || 0}
              rows={loginActivity.data || []}
              columns={columns}
              disableRowSelectionOnClick
              paginationMode={"server"}
              autoHeight
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page }
                }
              }}
              onPaginationModelChange={handleChangePaginationModel}
              pageSizeOptions={[5, 10, 25, 50]}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RecentActivityLog;
