import React from "react";

import {
  Grid
} from "@mui/material";

import PageHeader from "../../components/ui/PageHeader/PageHeader";
import AppLayout from "../../layouts/AppLayout/AppLayout";
import { AppBreadcrumb } from "../../models/app-breadcrumb";
import RecentActivityLog from "../RecentActivityLog/RecentActivityLog";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Users"
  },
  {
    name: "Activity Log",
    bold: true
  }
];

const ProfileSettingsRecentActivity: React.FC = () => {
  // const [tab, setTab] = useState(1);

  // function handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
  //   setTab(newValue);
  // }

  return (
    <Grid
      data-testid={"onboard-profile-settings-view"}
      item
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100%" }}
    >
      <AppLayout>
        <PageHeader
          title={"Activity Log"}
          breadcrumbs={breadcrumbs}
        />
        <>
          {/* <Tabs value={tab} onChange={handleChangeTab}>
            <Tab label={"General"} />
            <Tab label={"Recent Activity"} />
          </Tabs>
          {tab === 0 && <GeneralUserSettings />}
          {tab === 1 && <RecentActivityLog />} */}
          <RecentActivityLog />
        </>
      </AppLayout>
    </Grid>
  );
};

export default ProfileSettingsRecentActivity;
