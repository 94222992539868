import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";

import { User } from "src/models/User";
import { updateUserState } from "src/store/thunks/user/update/updateUserState";

import { useAppDispatch } from "../../../store/hooks";

import useStyles from "./DisableUserDialog.styles";

interface DisableUserDialogProps {
  open: boolean;
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  user?: User;
  handleUpdate: Function;
}

const DisableUserDialog: React.FC<DisableUserDialogProps> = ({ open, setOpen, user, handleUpdate }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const onConfirmDisable = async() => {
    const resp = await dispatch(updateUserState(user));
    if (!resp.type.includes("rejected")) {
      handleUpdate();
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} className={classes.dialog} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle sx={{ m: 0, p: 2 }} className={classes.dialogTitle} variant={"h5"}
        style={{ fontWeight: 500 }}>Confirm to {user?.is_active ? "Disable" : "Enable"} User</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant={"body2"}>Are you sure you want to {user?.is_active ? "disable" : "enable"} {user?.first_name}?</Typography>
        {user?.is_active ? <Typography variant={"body3"} className={classes.italicText}>Users with this user will lose all access associated with it</Typography> : <></>}
      </DialogContent>
      <DialogActions className={classes.diablogActions}>
        <Button
          variant={"contained"}
          size={"large"}
          onClick={() => setOpen(false)}
          data-testid={"cancel-button"}
          className={classes.cancelButton}
        >
          <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
        </Button>
        <Button
          variant={"contained"}
          size={"large"}
          data-testid={"confirm-button"}
          onClick={onConfirmDisable}
          className={classes.confirmButton}
        >
          <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>Confirm</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableUserDialog;
