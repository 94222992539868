
import React, { ReactElement } from "react";

import { Button, Chip, Divider, Grid, Typography, useTheme } from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import { useNavigate } from "react-router-dom";

import { Application } from "src/models/Application";
import { resetApplications, selectApplications } from "src/store/reducers/applications/applicationsSlice";
import { addApplicationsToOperator } from "src/store/thunks/operator/create/addApplicationsToOperator";

import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  resetBusinessInformationSlice,
  selectLoading,
  selectMailingSameAsBilling,
  selectOperatorBillingAddress,
  selectOperatorBillingAddressTwo,
  selectOperatorBillingCity,
  selectOperatorBillingPostalCode,
  selectOperatorBillingState,
  selectOperatorDBA,
  selectOperatorDisplayName,
  selectOperatorMailingAddress,
  selectOperatorMailingAddressTwo,
  selectOperatorMailingCity, selectOperatorMailingPostalCode,
  selectOperatorMailingState,
  selectOperatorName,
  selectPrimaryContactEmail
} from "../../../../../store/reducers/businessInformation/businessInformationSlice";
import {
  selectCurrentStep,
  selectSummaryTab1,
  selectSummaryTab2,
  setCurrentStep,
  setSummaryTab1,
  setSummaryTab2
} from "../../../../../store/reducers/onboardOperator/onboardOperatorSlice";
import { AppDispatch } from "../../../../../store/rootStore";
import { createOperator } from "../../../../../store/thunks/operator/create/createOperator";
import StepLayout from "../StepLayout/StepLayout";
import StepperButtons from "../StepLayout/StepperButtons/StepperButtons";

import useStyles from "./OnboardSummary.styles";

export const resetOnboardOperator = (dispatch: AppDispatch) => {
  dispatch(setCurrentStep(1));
  dispatch(resetBusinessInformationSlice());
  dispatch(resetApplications());
};

const OnboardSummary: React.FC = (): ReactElement => {
  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const currentStep = useAppSelector(selectCurrentStep);

  const summaryTab1 = useAppSelector(selectSummaryTab1);
  const summaryTab2 = useAppSelector(selectSummaryTab2);
  const operatorName = useAppSelector(selectOperatorName);
  const operatorDBA = useAppSelector(selectOperatorDBA);
  const operatorDisplayName = useAppSelector(selectOperatorDisplayName);
  const operatorAddress =
    `${useAppSelector(selectOperatorBillingAddress)} ${useAppSelector(selectOperatorBillingAddressTwo)}`.trim();
  const operatorCity = useAppSelector(selectOperatorBillingCity);
  const operatorState = useAppSelector(selectOperatorBillingState);
  const operatorPostalCode = useAppSelector(selectOperatorBillingPostalCode);
  const mailingSameAsBilling = useAppSelector(selectMailingSameAsBilling);
  const mailingAddress =
    `${useAppSelector(selectOperatorMailingAddress)} ${useAppSelector(selectOperatorMailingAddressTwo)}`.trim();
  const mailingCity = useAppSelector(selectOperatorMailingCity);
  const mailingState = useAppSelector(selectOperatorMailingState);
  const mailingPostalCode = useAppSelector(selectOperatorMailingPostalCode);
  const contactEmail = useAppSelector(selectPrimaryContactEmail);
  const applications = useAppSelector(selectApplications);

  const backwards = () => dispatch(setCurrentStep(currentStep - 1));
  const backwardsToStart = () => dispatch(setCurrentStep(currentStep - 4));

  const mailingSameAsBillingText = mailingSameAsBilling
    ? (
      <Grid>
        <Typography data-testid={"mailing-same-as-billing-text"} variant={"caption"}>
          Mailing address the same as billing address
        </Typography>
      </Grid>
      )
    : "";

  const mailingAddressDisplay = !mailingSameAsBilling
    ? (
      <Grid
        container
        item
        direction={"column"}
        xs={12}
        lg={6}
      >
        <Typography data-testid={"mailing-address-text"} className={classes.infoHeader}>Mailing Address</Typography>
        <Typography>{mailingAddress}</Typography>
        <Typography>{mailingCity}, {mailingState} {mailingPostalCode}</Typography>
      </Grid>
      )
    : "";

  const navigate = useNavigate();

  const handleFinish = () => {
    dispatch(createOperator())
      .then(resp => {
        if (!resp.type.includes("rejected")) {
          dispatch(addApplicationsToOperator()).then(resp => {
            if (!resp.type.includes("rejected")) {
              navigate("/operators/operators-success");
              resetOnboardOperator(dispatch);
            }
          });
        }
      });
  };

  return (
    <StepLayout>
      <form
        data-testid={"onboard-summary"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleFinish();
        }}
      >
        <Grid container direction={"column"} mb={3}>
          <Typography variant={"h6"} className={classes.title}>
            Operator Review
          </Typography>
          <Grid
            container
            item
            alignItems={"center"}
            className={classes.wrappSubtitle}
            onClick={() => dispatch(setSummaryTab1(!summaryTab1))}
          >
            <Typography variant={"h6"} className={classes.subtitle}>Business Information</Typography>
            {summaryTab1
              ? <IconChevronDown data-testid={"menu-less-icon"} className={classes.menuIcon} />
              : <IconChevronUp data-testid={"menu-more-icon"} className={classes.menuIcon} />
            }
          </Grid>
          {summaryTab1
            ? (
              <Grid spacing={1} container className={classes.containerToggleContent}>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"subtitle2"} className={classes.infoHeader}>Business Name:</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"body2"}>{operatorName}</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"subtitle2"} className={classes.infoHeader}>DBA:</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"body2"}>{operatorDBA}</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"subtitle2"} className={classes.infoHeader}>Display Name:</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"body2"}>{operatorDisplayName}</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"subtitle2"} className={classes.infoHeader}>Billing Address:</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"body2"}>{operatorAddress}</Typography>
                  <Typography variant={"body2"}>{operatorCity}, {operatorState} {operatorPostalCode}</Typography>
                </Grid>
                {mailingAddressDisplay}
                {mailingSameAsBilling && <Grid item xs={12}>{mailingSameAsBillingText}</Grid>}
                <Grid item xs={12} lg={6}>
                  <Typography variant={"subtitle2"} className={classes.infoHeader}>Contact Email:</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography variant={"body2"}>{contactEmail}</Typography>
                </Grid>
              </Grid>
              )
            : null}

        </Grid>
        <Divider />
        <Grid container direction={"column"} mb={3}>
          <Grid
            container
            item
            alignItems={"center"}
            className={classes.wrappSubtitle}
            onClick={() => dispatch(setSummaryTab2(!summaryTab2))}
          >
            <Typography variant={"h6"} className={classes.subtitle}>Application Access</Typography>
            {summaryTab2
              ? <IconChevronDown data-testid={"menu-less2-icon"} className={classes.menuIcon} />
              : <IconChevronUp data-testid={"menu-more2-icon"} className={classes.menuIcon} />
            }
          </Grid>
        </Grid>
        <Divider />
        {summaryTab2
          ? (
            <Grid mt={3}>
              {
                applications.filter((app: Application) => app.enabled)
                  .map((enabledApp: Application) => (
                    <Chip
                      data-testid={`chip-${enabledApp.id}`}
                      key={enabledApp.name}
                      label={<Typography variant={"subtitle2"} style={{ fontWeight: 700 }}>{enabledApp.name}</Typography>}
                      className={classes.appChip}
                    />
                  ))
              }
            </Grid>
            )
          : null}
        <Grid className={classes.wrappStepperButtons}>
          <Button
            className={classes.cancel}
            onClick={() => backwardsToStart()}
            variant={"text"}
            data-testid={"step-cancel-button"}
          >
            <Typography variant={"button"} style={{ color: theme.palette.error.main }}>Cancel</Typography>
          </Button>
          <StepperButtons
            loading={loading}
            backActionText={
              <div className={classes.backButton}>
                <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText }}>
                  Back
                </Typography>
              </div>
            }
            forwardActionText={"Next"}
            backAction={backwards}
          />
        </Grid>
      </form>
    </StepLayout>
  );
};

export default OnboardSummary;
