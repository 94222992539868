import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "src/store/rootStore";

import { OnboardOperatorState } from "./OnboardOperatorSliceState";

export const initialState: OnboardOperatorState = {
  currentStep: sessionStorage.getItem("currentStep") ? parseInt(sessionStorage.getItem("currentStep")!) : 1,
  summaryTab1: false,
  summaryTab2: false
};

export const onboardSlice = createSlice({
  name: "onboardOperator",
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      sessionStorage.setItem("currentStep", state.currentStep.toFixed());
    },
    setSummaryTab1: (state, action: PayloadAction<boolean>) => {
      state.summaryTab1 = action.payload;
    },
    setSummaryTab2: (state, action: PayloadAction<boolean>) => {
      state.summaryTab2 = action.payload;
    }
  }
});

export const selectCurrentStep = (state: RootState) => state.onboardOperator.currentStep;
export const selectSummaryTab1 = (state: RootState) => state.onboardOperator.summaryTab1;
export const selectSummaryTab2 = (state: RootState) => state.onboardOperator.summaryTab2;

export const {
  setCurrentStep,
  setSummaryTab1,
  setSummaryTab2
} = onboardSlice.actions;

export default onboardSlice.reducer;
