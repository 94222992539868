import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Facility } from "src/models/Facility";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getFacilities } from "src/store/thunks/facilities/getAll/getFacilities";

import { FacilitiesAccessSliceState } from "./facilitiesSliceState";

export const initialState: FacilitiesAccessSliceState = {
  loading: false,
  error: "",
  facilities: {
    data: [],
    meta: {} as APIListMetaData,
    links: {
      first: null,
      last: null,
      prev: null,
      next: null
    }
  }
};

export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState,
  reducers: {
    setFacilities: (state, action: PayloadAction<Facility[]>) => {
      state.facilities.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFacilities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFacilities.fulfilled, (state, action: PayloadAction<APIListResponse<Facility[]>>) => {
        state.facilities = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.facilities.data = action.payload.data;
        state.facilities.meta = action.payload.meta;
        state.facilities.links = action.payload.links;
        state.loading = false;
      })
      .addCase(getFacilities.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectFacilities = (state: RootState) => state?.facilities?.facilities;
export const selectLoading = (state: RootState) => state.facilities.loading;
export const selectBusinessInformation = (state: RootState) => state.facilities;

export const {
  setFacilities
} = facilitiesSlice.actions;

export default facilitiesSlice.reducer;
