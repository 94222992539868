import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  dropdownLink: {
    paddingLeft: "92px"
  },
  selectedLink: {
    background: theme.selectedNavigationItem,
    borderRadius: "0.5rem",
    "&:hover": {
      borderRadius: "0.5rem",
      backgroundColor: theme.selectedNavigationItemHover
    }
  },
  listItem: {
    marginBottom: "12px",
    paddingTop: "2px",
    paddingBottom: "2px",
    "&:hover": {
      borderRadius: "0.5rem"
    }
  },
  listIcon: {
    color: theme.palette.primary.main,
    marginLeft: "24px",
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "12px"
    }
  },
  arrowIcon: {
    color: theme.palette.primary.main
  },
  listText: {
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    "& .MuiTypography-root": {
      fontWeight: 500
    }
  },
  navLinkTooltip: {
    backgroundColor: theme.navLinkTooltip,
    marginLeft: "0 !important"
  },
  navLinkTooltipLink: {
    color: theme.palette.common.white,
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer"
    }
  },
  navLinkTooltipLinkTitle: {
    color: theme.gray450Base,
    fontWeight: 500
  },
  tooltipArrow: {
    color: theme.navLinkTooltip
  }
}));

export default useStyles;
