import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.sideNavigationBackground,
    height: "100%",
    padding: "32px 16px 0"
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.white
  },
  menuAdminSettings: {
    fontWeight: 700,
    textTransform: "uppercase",
    // margin: "30px 0 10px 30px",
    color: theme.palette.primary.main
  },
  divider: {
    background: theme.gray500Base,
    margin: `0 ${theme.spacing(4)}`
  }
}));

export default useStyles;
