import React, { ChangeEventHandler } from "react";

import TextField from "@mui/material/TextField";

import { PatternFormat } from "react-number-format";

interface PatternFormatCustomProps {
  inputRef: (instance: typeof PatternFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

// eslint-disable-next-line react/display-name
export const PatternFormatCustom = React.forwardRef((props: PatternFormatCustomProps, _ref: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      format={props.value.length >= 10 ? "(###) ###-####" : "##########"}
      mask={"_"}
    />
  );
});

interface MaskedInputProps {
  id?: string;
  dataTestId?: string;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string | false;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
  variant?: "standard" | "filled" | "outlined";
  value: number | string;
}

export default function MaskedInput(props: MaskedInputProps) {
  const { id, value, name, onChange, type = "text", dataTestId, ...options } = props;

  return (
    <TextField
      id={id}
      fullWidth={options.fullWidth}
      variant={options.variant}
      placeholder={options.placeholder}
      onChange={onChange}
      value={value}
      type={type}
      name={name}
      inputProps={{ "data-testid": dataTestId }}
      InputProps={{
        inputComponent: PatternFormatCustom as any
      }}
      error={options.error}
      helperText={options.helperText}
    />
  );
}
