import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%",
    boxShadow: "none !important",
    border: "none"
  },
  paper: {
    [theme.breakpoints.down("md")]: {
      height: "100%"
    },
    height: "200px",
    width: "100%",
    backgroundColor: theme.graySecondary50,
    padding: "0 30px 0 30px",
    borderRadius: "10px",
    boxShadow: "none",
    border: `1px solid ${theme.gray500Base}`
  },
  button: {
    float: "right",
    marginTop: "80px",
    padding: "10px 20px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "20px"
    },
    width: "152px",
    height: "36px",
    background: theme.palette.primary.contrastText,
    borderRadius: "4px",
    textTransform: "uppercase"
  }
}));

export default useStyles;
