import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance as axios } from "../../../../api/axiosInstance";
import { format422 } from "../../../../utils/format422/format422";
import { showSnackbar } from "../../../reducers/snackbar/snackbarSlice";
import { RootState } from "../../../rootStore";

import { CreateUserPayload } from "./createUserPayload";

export const createUser = createAsyncThunk(
  "createUser",
  (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const newUser = state.newUser;

    const payload: CreateUserPayload = {
      first_name: newUser.user?.first_name || "",
      last_name: newUser.user?.last_name || "",
      email: newUser.user?.email || "",
      phone: newUser.user?.phone || "",
      operator_id: 1,
      assignments: newUser.user?.assignments || []
    };

    return axios.post("/api/v1/users/registration", payload)
      .then((resp) => {
        dispatch(showSnackbar({
          message: "User created successfully",
          variant: "success"
        }));

        return resp.data;
      })
      .catch((err) => {
        if (err.response.status === 422) {
          const errorMessage = format422(err.response.data.messages);

          dispatch(showSnackbar({
            message: errorMessage,
            variant: "error"
          }));

          return rejectWithValue(errorMessage);
        }

        const errorMessage = "There was an error creating the user";

        dispatch(showSnackbar({
          message: errorMessage,
          variant: "error"
        }));

        return rejectWithValue(errorMessage);
      });
  }
);
