import { makeStyles } from "src/makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    height: "100%"
  },
  boxContent: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    padding: "35px 0 20px 0",
    borderBottom: `1px solid ${theme.gray500Base}`
  },
  titleTable: {
    display: "inline-block"
  },
  spinner: {
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center"
  },
  actionsButton: {
    color: theme.palette.primary.main,
    width: "16px",
    height: "16px"
  },
  filterInput: {
    float: "right",
    width: "220px",
    height: "50px",
    "&::placeholder": {
      color: theme.palette.error.main,
      fontStyle: "Roboto",
      fontSize: "16px",
      letterSpacing: "0.5px"
    }
  },
  filterInputIcon: {
    color: theme.palette.primary.main,
    width: "24px"
  },
  addButtonTable: {
    height: "36px",
    textTransform: "uppercase",
    color: theme.palette.common.white,
    background: theme.palette.primary.contrastText,
    marginLeft: "24px"
  },
  searchInput: {
    float: "right"
  },
  customTooltip: {
    fontSize: "24px"
  }
}));

export default useStyles;
