import * as Yup from "yup";

import { emailRegex } from "src/utils/validateEmail/validateEmail";

export const editUserFormValidation = Yup.object().shape({
  editUserFirstName: Yup.string().required("First Name is required."),
  editUserLastName: Yup.string().required("Last Name is required."),
  editUserEmail: Yup.string().matches(emailRegex, "Email is invalid.").required("Email is required."),
  editUserPhone: Yup.string().length(10, "Phone Number must be 10 digits"),
  editUserAssignments: Yup.array().min(1, "You need to add at least 1 assignment").test("check-empty", "Please add at least one role and one facility for each assignment!", function(assignments) {
    return assignments?.every(assignment => {
      return assignment.facilities.length > 0 && assignment.roles.length > 0;
    });
  })
});
