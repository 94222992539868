import React, { ChangeEvent, Fragment, ReactElement, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { IconArrowLeft, IconInfoCircle } from "@tabler/icons-react";

import { useFormik } from "formik";

import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import PageHeader from "src/components/ui/PageHeader/PageHeader";
import AppLayout from "src/layouts/AppLayout/AppLayout";
import { Application } from "src/models/Application";
import { AppBreadcrumb } from "src/models/app-breadcrumb";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectApplications } from "src/store/reducers/applications/applicationsSlice";
import {
  billingCityOnchange,
  billingPostalCodeOnchange,
  billingRegionOnchange,
  billingStreetAddress2Onchange,
  billingStreetAddressOnchange,
  businessContactOnchange,
  businessEmailAddressOnchange,
  businessNameOnchange,
  dbaNameOnchange,
  displayNameOnchange,
  editCopyBillingToMailing,
  isMailingSameAsBillingOnchange,
  mailingCityOnchange,
  mailingPostalCodeOnchange,
  mailingRegionOnchange,
  mailingStreetAddress2Onchange,
  mailingStreetAddressOnchange,
  selectLoading,
  selectOperatorBillingCity,
  selectOperatorBillingPostalCode,
  selectOperatorBillingRegion,
  selectOperatorBillingStreetAddress,
  selectOperatorBillingStreetAddress2,
  selectOperatorBusinessContact,
  selectOperatorBusinessEmailAddress,
  selectOperatorBusinessName,
  selectOperatorDbaName,
  selectOperatorDisplayName,
  selectOperatorIsMailingSameAsBilling,
  selectOperatorMailingCity,
  selectOperatorMailingPostalCode,
  selectOperatorMailingRegion,
  selectOperatorMailingStreetAddress,
  selectOperatorMailingStreetAddress2,
  selectOperatorSelectedApplications,
  setLoading,
  setOperatorLoaded,
  setSelectedApplications
} from "src/store/reducers/editOperator/editOperatorSlice";
import { getOperator } from "src/store/thunks/operator/get/getOperator";
import { updateOperator } from "src/store/thunks/operator/update/updateOperator";
import { updateOperatorApplications } from "src/store/thunks/operator/updateApplications/updateApplications";
import states from "src/utils/usStates";

import OperatorFacilities from "./OperatorFacilities/OperatorFacilities";
import { editOperatorFormValidation } from "./editOperatorFormValidation";

import useStyles from "./EditOperator.styles";

const breadcrumbs: AppBreadcrumb[] = [
  {
    name: "Operators"
  },
  {
    name: "Edit Operator",
    bold: true
  }
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role={"tabpanel"}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const EditOperator: React.FC = (): ReactElement => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { classes } = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const params = useParams();

  const applications = useAppSelector(selectApplications);

  const editMailingSameAsBilling = useAppSelector(selectOperatorIsMailingSameAsBilling);
  const editBusinessName = useSelector(selectOperatorBusinessName);
  const editDbaName = useSelector(selectOperatorDbaName);
  const editDisplayName = useSelector(selectOperatorDisplayName);
  const editBusinessContact = useSelector(selectOperatorBusinessContact);
  const editBusinessEmail = useSelector(selectOperatorBusinessEmailAddress);
  const editBillingAddress = useSelector(selectOperatorBillingStreetAddress);
  const editMailingAddress = useSelector(selectOperatorMailingStreetAddress);
  const editMailingAddress2 = useSelector(selectOperatorMailingStreetAddress2);
  const editBillingCity = useSelector(selectOperatorBillingCity);
  const editMailingCity = useSelector(selectOperatorMailingCity);
  const editBillingState = useSelector(selectOperatorBillingRegion);
  const editBillingPostalCode = useSelector(selectOperatorBillingPostalCode);
  const editMailingPostalCode = useSelector(selectOperatorMailingPostalCode);
  const editMailingState = useSelector(selectOperatorMailingRegion);
  const editBillingAddress2 = useSelector(selectOperatorBillingStreetAddress2);
  const selectedApplications = useSelector(selectOperatorSelectedApplications);

  const loading = useSelector(selectLoading);

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (selectedApplications.length === 0) return;
    dispatch(setLoading(true));
    dispatch(updateOperatorApplications(params.id))
      .then(() => {
        dispatch(updateOperator(params.id));
      })
      .then(() => {
        dispatch(setLoading(false));
        navigate("/operators/operators-list");
      });
  };
  const formik = useFormik({
    initialValues: {
      editMailingSameAsBilling,
      editBusinessName,
      editDbaName,
      editDisplayName,
      editBusinessContact,
      editBusinessEmail,
      editBillingAddress,
      editMailingAddress,
      editMailingAddress2,
      editBillingCity,
      editMailingCity,
      editBillingState,
      editBillingPostalCode,
      editMailingPostalCode,
      editMailingState,
      editBillingAddress2
    },
    validationSchema: editOperatorFormValidation,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: () => {
      if (editMailingSameAsBilling) {
        dispatch(editCopyBillingToMailing());
      }
      handleSubmit();
    }
  });

  const { errors, touched, values, submitCount } = formik;

  const updateForm = (fieldName: string, fieldValue?: string | boolean): void => {
    formik.setFieldTouched(fieldName);
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    dispatch(getOperator(params.id)).then((resp) => {
      if (resp.type.includes("fulfilled")) {
        dispatch(setOperatorLoaded(true));
      }
    });
  }, [params.id]);

  const handleSameAsBilling = (fieldName: string, fieldValue: boolean) => {
    updateForm(fieldName, fieldValue);
    dispatch(isMailingSameAsBillingOnchange(fieldValue));
  };
  const handleBusinessNameOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(businessNameOnchange(fieldValue));
  };
  const handleDbaNameOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(dbaNameOnchange(fieldValue));
  };
  const handleDisplayNameOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(displayNameOnchange(fieldValue));
  };
  const handleBusinessContactOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(businessContactOnchange(fieldValue));
  };
  const handleBusinessEmailOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(businessEmailAddressOnchange(fieldValue));
  };
  const handleBillingAddressOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(billingStreetAddressOnchange(fieldValue));
  };
  const handleMailingAddressOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(mailingStreetAddressOnchange(fieldValue));
  };
  const handleMailingAddress2Onchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(mailingStreetAddress2Onchange(fieldValue));
  };
  const handleBillingAddress2Onchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(billingStreetAddress2Onchange(fieldValue));
  };
  const handleBillingCityOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(billingCityOnchange(fieldValue));
  };
  const handleBillingPostalCodeOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(billingPostalCodeOnchange(fieldValue));
  };
  const handleMailingPostalCodeOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(mailingPostalCodeOnchange(fieldValue));
  };
  const handleMailingCityOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(mailingCityOnchange(fieldValue));
  };
  const handleBillingStateOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(billingRegionOnchange(fieldValue));
  };
  const handleMailingStateOnchange = (fieldName: string, fieldValue: string) => {
    updateForm(fieldName, fieldValue);
    dispatch(mailingRegionOnchange(fieldValue));
  };

  const handleUpdateSelectedApplications = (checked: boolean, id: number) => {
    const clonedSelectedApps = [...selectedApplications];
    if (checked) {
      clonedSelectedApps.push(id);
    } else {
      const index = selectedApplications.indexOf(id);
      clonedSelectedApps.splice(index, 1);
    }

    dispatch(setSelectedApplications(clonedSelectedApps));
  };

  return (
    <AppLayout>
      <Box className={classes.root}>
        <PageHeader title={"Edit Operator"} breadcrumbs={breadcrumbs} />
        <Link
          type={"button"}
          component={RouterLink}
          variant={"body2"}
          to={"/operators/operators-list"}
          className={classes.linkBack}>
          {" "}
          <IconArrowLeft className={classes.backArrow} />{" "}
          <Typography variant={"button"} style={{ color: theme.palette.primary.contrastText, textTransform: "none" }}>
            Go Back to Operator List
          </Typography>
        </Link>

        <Tabs value={value} onChange={handleChange} aria-label={"basic tabs example"}>
          <Tab
            className={classes.tabAccess}
            label={
              <Typography variant={"h6"} style={{ textTransform: "none" }}>
                Operator Information
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tabAccess}
            label={
              <Typography variant={"h6"} style={{ textTransform: "none" }}>
                Facilities
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Typography variant={"body1"} className={classes.subtitle}>
            Manage specific operators information, facilities and select application access. After any edits are made,
            press the `Save` button to finalize any changes.
          </Typography>
          <form
            data-testid={"edit-operator-form"}
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              formik.handleSubmit();
            }}>
            <Paper elevation={4} className={classes.paper}>
              <Typography variant={"h6"} className={classes.paperTitle}>
                Operator Information
              </Typography>
              <Box className={classes.paperContent}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12}
                    lg={4}>
                    <Box className={classes.basicInformation}>
                      <Typography variant={"h6"} className={classes.paperSubtitle}>
                        Basic Information
                      </Typography>
                      <Box className={classes.basicInformationForm}>
                        <Box mb={1}>
                          <InputLabel htmlFor={"edit-operator-business-name"}>
                            <Typography variant={"subtitle2"}>
                              Operator Name
                              <IconInfoCircle className={classes.infoIcon} />
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"edit-operator-business-name"}
                            data-testid={"edit-operator-business-name"}
                            name={"editBusinessName"}
                            value={values.editBusinessName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleBusinessNameOnchange("editBusinessName", e.target.value)
                            }
                            error={touched && touched.editBusinessName && errors && Boolean(errors.editBusinessName)}
                            helperText={touched && touched.editBusinessName && errors && errors.editBusinessName}
                          />
                        </Box>
                        <Box mb={3}>
                          <InputLabel htmlFor={"edit-operator-dba-name"}>
                            <Typography variant={"subtitle2"}>
                              DBA
                              <IconInfoCircle className={classes.infoIcon} />
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"edit-operator-dba-name"}
                            data-testid={"edit-operator-dba-name"}
                            name={"editDbaName"}
                            value={values.editDbaName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleDbaNameOnchange("editDbaName", e.target.value)
                            }
                            error={touched && touched.editDbaName && errors && Boolean(errors.editDbaName)}
                            helperText={touched && touched.editDbaName && errors && errors.editDbaName}
                          />
                        </Box>
                        <Box mb={3}>
                          <InputLabel htmlFor={"edit-operator-display-name"}>
                            <Tooltip title={<Typography variant={"body3"} style={{ color: theme.palette.common.white }}>This is the preferred business name your employees will see when they log in</Typography>} arrow>
                              <Typography variant={"subtitle2"}>
                                Display Name
                                <IconInfoCircle className={classes.infoIcon} />
                              </Typography>
                            </Tooltip>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"edit-operator-display-name"}
                            data-testid={"edit-operator-display-name"}
                            name={"editDisplayName"}
                            value={values.editDisplayName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleDisplayNameOnchange("editDisplayName", e.target.value)
                            }
                            error={touched && touched.editDisplayName && errors && Boolean(errors.editDisplayName)}
                            helperText={touched && touched.editDisplayName && errors && errors.editDisplayName}
                          />
                        </Box>
                        <Box mb={1}>
                          <InputLabel htmlFor={"edit-operator-business-contact"}>
                            <Typography variant={"subtitle2"}>Business Contact</Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"edit-operator-business-contact"}
                            data-testid={"edit-operator-business-contact"}
                            name={"editBusinessContact"}
                            value={values.editBusinessContact}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleBusinessContactOnchange("editBusinessContact", e.target.value)
                            }
                            error={
                              touched && touched.editBusinessContact && errors && Boolean(errors.editBusinessContact)
                            }
                            helperText={touched && touched.editBusinessContact && errors && errors.editBusinessContact}
                          />
                        </Box>
                        <Box>
                          <InputLabel htmlFor={"edit-operator-business-email"}>
                            <Typography variant={"subtitle2"}>Email Address</Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"edit-operator-business-email"}
                            data-testid={"edit-operator-business-email"}
                            name={"editBusinessEmail"}
                            value={values.editBusinessEmail}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleBusinessEmailOnchange("editBusinessEmail", e.target.value)
                            }
                            error={touched && touched.editBusinessEmail && errors && Boolean(errors.editBusinessEmail)}
                            helperText={touched && touched.editBusinessEmail && errors && errors.editBusinessEmail}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}
                    lg={4}>
                    <Box className={classes.addressInformation}>
                      <Box className={classes.addressInformationPadding}>
                        <Typography variant={"h6"} className={classes.paperSubtitle}>
                          Address Information
                        </Typography>
                      </Box>
                      <Box className={classes.addressInformationForm}>
                        <Box mb={1}>
                          <InputLabel htmlFor={"edit-operator-billing-address"}>
                            <Typography variant={"subtitle2"}>Billing Address 1</Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"edit-operator-billing-address"}
                            data-testid={"edit-operator-billing-address"}
                            name={"editBillingAddress"}
                            value={values.editBillingAddress}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleBillingAddressOnchange("editBillingAddress", e.target.value)
                            }
                            error={
                              touched && touched.editBillingAddress && errors && Boolean(errors.editBillingAddress)
                            }
                            helperText={touched && touched.editBillingAddress && errors && errors.editBillingAddress}
                          />
                        </Box>
                        <Box mb={1}>
                          <InputLabel htmlFor={"edit-operator-billing-address2"}>
                            <Typography variant={"subtitle2"}>Billing Address 2</Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            id={"edit-operator-billing-address2"}
                            data-testid={"edit-operator-billing-address2"}
                            name={"editBillingAddress2"}
                            value={values.editBillingAddress2}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleBillingAddress2Onchange("editBillingAddress2", e.target.value)
                            }
                            error={
                              touched && touched.editBillingAddress2 && errors && Boolean(errors.editBillingAddress2)
                            }
                            helperText={touched && touched.editBillingAddress2 && errors && errors.editBillingAddress2}
                          />
                        </Box>
                        <Grid container spacing={2} mb={3}>
                          <Grid item xs={12} md={6}
                            lg={6}>
                            <InputLabel htmlFor={"edit-operator-billing-city"}>
                              <Typography variant={"subtitle2"}>City</Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              id={"edit-operator-billing-city"}
                              data-testid={"edit-operator-billing-city"}
                              name={"editBillingCity"}
                              value={values.editBillingCity}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleBillingCityOnchange("editBillingCity", e.target.value)
                              }
                              error={touched && touched.editBillingCity && errors && Boolean(errors.editBillingCity)}
                              helperText={touched && touched.editBillingCity && errors && errors.editBillingCity}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}
                            lg={6}>
                            <InputLabel htmlFor={"edit-operator-billing-state"}>
                              <Typography variant={"subtitle2"}>State</Typography>
                            </InputLabel>
                            <FormControl fullWidth variant={"outlined"}>
                              <Select
                                id={"edit-operator-billing-state"}
                                inputProps={{ "data-testid": "edit-operator-billing-state" }}
                                name={"editBillingState"}
                                native
                                value={values.editBillingState}
                                onChange={(e) => handleBillingStateOnchange("editBillingState", e.target.value)}
                                error={
                                  touched && touched.editBillingState && errors && Boolean(errors.editBillingState)
                                }>
                                <option value={""} disabled>
                                  Select a State...
                                </option>
                                {states.map((state: any) => (
                                  <option key={state.text} value={state.value}>
                                    {state.value}
                                  </option>
                                ))}
                              </Select>
                              {touched &&
                                touched.editBillingState &&
                                errors &&
                                errors.editBillingState &&
                                Boolean(errors.editBillingState) && (
                                  <FormHelperText error>{errors.editBillingState}</FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}
                            lg={6}>
                            <InputLabel htmlFor={"edit-operator-billing-postal-code"}>
                              <Typography variant={"subtitle2"}>Zip/postal code</Typography>
                            </InputLabel>
                            <TextField
                              fullWidth
                              id={"edit-operator-billing-postal-code"}
                              data-testid={"edit-operator-billing-postal-code"}
                              name={"editBillingPostalCode"}
                              value={values.editBillingPostalCode}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleBillingPostalCodeOnchange("editBillingPostalCode", e.target.value)
                              }
                              error={
                                touched &&
                                touched.editBillingPostalCode &&
                                errors &&
                                Boolean(errors.editBillingPostalCode)
                              }
                              helperText={
                                touched && touched.editBillingPostalCode && errors && errors.editBillingPostalCode
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item pb={1} md={12}>
                          <FormControlLabel
                            label={
                              <Typography variant={"subtitle2"}>Mailing address the same as billing address</Typography>
                            }
                            control={
                              <Checkbox
                                checked={!!values.editMailingSameAsBilling}
                                name={"editMailingSameAsBilling"}
                                color={"primary"}
                                data-testid={"edit-operator-mailing-same-as-billing"}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleSameAsBilling("editMailingSameAsBilling", e.target.checked)
                                }
                              />
                            }
                          />
                        </Grid>

                        {!editMailingSameAsBilling
                          ? (
                            <Fragment>
                              <Box mb={1}>
                                <InputLabel htmlFor={"edit-operator-mailing-address"}>
                                  <Typography variant={"subtitle2"}>Mailing Address 1</Typography>
                                </InputLabel>
                                <TextField
                                  fullWidth
                                  id={"edit-operator-mailing-address"}
                                  data-testid={"edit-operator-mailing-address"}
                                  name={"editMailingAddress"}
                                  value={values.editMailingAddress}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleMailingAddressOnchange("editMailingAddress", e.target.value)
                                }
                                  error={
                                  touched && touched.editMailingAddress && errors && Boolean(errors.editMailingAddress)
                                }
                                  helperText={
                                  touched && touched.editMailingAddress && errors && errors.editMailingAddress
                                }
                              />
                              </Box>
                              <Box mb={1}>
                                <InputLabel htmlFor={"edit-operator-mailing-address2"}>
                                  <Typography variant={"subtitle2"}>Mailing Address 2</Typography>
                                </InputLabel>
                                <TextField
                                  fullWidth
                                  id={"edit-operator-mailing-address2"}
                                  data-testid={"edit-operator-mailing-address2"}
                                  name={"editMailingAddress2"}
                                  value={values.editMailingAddress2}
                                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleMailingAddress2Onchange("editMailingAddress2", e.target.value)
                                }
                                  error={
                                  touched &&
                                  touched.editMailingAddress2 &&
                                  errors &&
                                  Boolean(errors.editMailingAddress2)
                                }
                                  helperText={
                                  touched && touched.editMailingAddress2 && errors && errors.editMailingAddress2
                                }
                              />
                              </Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}
                                  lg={6}>
                                  <InputLabel htmlFor={"edit-operator-mailing-city"}>
                                    <Typography variant={"subtitle2"}>City</Typography>
                                  </InputLabel>
                                  <TextField
                                    fullWidth
                                    id={"edit-operator-mailing-city"}
                                    data-testid={"edit-operator-mailing-city"}
                                    name={"editMailingCity"}
                                    value={values.editMailingCity}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                      handleMailingCityOnchange("editMailingCity", e.target.value)
                                  }
                                    error={
                                    touched && touched.editMailingCity && errors && Boolean(errors.editMailingCity)
                                  }
                                    helperText={touched && touched.editMailingCity && errors && errors.editMailingCity}
                                />
                                </Grid>
                                <Grid item xs={12} md={6}
                                  lg={6}>
                                  <InputLabel htmlFor={"edit-operator-mailing-state"}>
                                    <Typography variant={"subtitle2"}>State</Typography>
                                  </InputLabel>
                                  <FormControl fullWidth variant={"outlined"}>
                                    <Select
                                      id={"edit-operator-mailing-state"}
                                      inputProps={{ "data-testid": "edit-operator-mailing-state" }}
                                      name={"editMailingState"}
                                      native
                                      value={values.editMailingState}
                                      onChange={(e) => handleMailingStateOnchange("editMailingState", e.target.value)}
                                      error={
                                      touched && touched.editMailingState && errors && Boolean(errors.editMailingState)
                                    }>
                                      <option value={""} disabled>
                                        Select a State...
                                      </option>
                                      {states.map((state: any) => (
                                        <option key={state.text} value={state.value}>
                                          {state.value}
                                        </option>
                                      ))}
                                    </Select>
                                    {touched &&
                                    touched.editMailingState &&
                                    errors &&
                                    errors.editMailingState &&
                                    Boolean(errors.editMailingState) && (
                                      <FormHelperText error>{errors.editMailingState}</FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}
                                  lg={6}>
                                  <InputLabel htmlFor={"edit-operator-mailing-postal-code"}>
                                    <Typography variant={"subtitle2"}>Mailing Zip</Typography>
                                  </InputLabel>
                                  <TextField
                                    fullWidth
                                    id={"edit-operator-mailing-postal-code"}
                                    data-testid={"edit-operator-mailing-postal-code"}
                                    name={"editMailingPostalCode"}
                                    value={values.editMailingPostalCode}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                      handleMailingPostalCodeOnchange("editMailingPostalCode", e.target.value)
                                  }
                                    error={
                                    touched &&
                                    touched.editMailingPostalCode &&
                                    errors &&
                                    Boolean(errors.editMailingPostalCode)
                                  }
                                    helperText={
                                    touched && touched.editMailingPostalCode && errors && errors.editMailingPostalCode
                                  }
                                />
                                </Grid>
                              </Grid>
                            </Fragment>
                            )
                          : null}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}
                    lg={4}>
                    <Box className={classes.applicationAccess}>
                      <Typography variant={"h6"} className={classes.paperSubtitle}>
                        Application Access
                      </Typography>
                      <Box className={classes.applicationAccessContent}>
                        <Box className={classes.listCheckboxes}>
                          <FormGroup className={classes.appicationList}>
                            {applications.map((app: Application) => (
                              <div key={app.id} className={classes.applicationListItem}>
                                <FormControlLabel
                                  className={classes.formControlLabelRow}
                                  label={<Typography variant={"subtitle2"}>{app.name}</Typography>}
                                  labelPlacement={"start"}
                                  control={
                                    <Switch
                                      name={app.name}
                                      data-testid={`toggle-app-access-switch-${app.id}`}
                                      className={classes.switch}
                                      checked={selectedApplications.includes(app.id)}
                                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        handleUpdateSelectedApplications(e.target.checked, app.id)
                                      }
                                    />
                                  }
                                />
                              </div>
                            ))}
                          </FormGroup>
                          {!!submitCount && selectedApplications.length === 0 && (
                            <FormHelperText error>You must select at least one application.</FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box mt={3} className={classes.wrappButtons}>
              <Box className={classes.buttonsItems}>
                <Link
                  type={"button"}
                  component={RouterLink}
                  variant={"body2"}
                  to={"/operators/operators-list"}
                  data-testid={"confirm-cancel-button"}
                  className={classes.cancelButton}>
                  <Typography variant={"button"} style={{ color: theme.palette.error.main }}>
                    Cancel
                  </Typography>
                </Link>
              </Box>
              <Box className={classes.buttonsItems}>
                <Button
                  variant={"contained"}
                  size={"large"}
                  type={"submit"}
                  data-testid={"confirm-button"}
                  className={classes.saveButton}>
                  {loading
                    ? (
                      <CircularProgress
                        size={"2rem"}
                        data-testid={"confirm-button-spinner"}
                        className={classes.spinner}
                    />
                      )
                    : (
                      <Typography variant={"button"} style={{ color: theme.buttonColor }}>
                        Save
                      </Typography>
                      )}
                </Button>
              </Box>
            </Box>
          </form>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OperatorFacilities />
        </TabPanel>
      </Box>
    </AppLayout>
  );
};

export default EditOperator;
