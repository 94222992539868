
import React, { Fragment, ReactElement, SyntheticEvent } from "react";

import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import clsx from "clsx";

import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectDropdownOpen,
  toggleOpenDropdown
} from "src/store/reducers/navigation/navigationSlice";

import { NavLinkType } from "./NavLinkType";

import useStyles from "./NavLink.styles";

interface NavLinkProps {
  link: NavLinkType
}

const NavLink: React.FC<NavLinkProps> = ({ link }: NavLinkProps): ReactElement => {
  const dropdownOpen = useAppSelector(selectDropdownOpen);
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const childLinks = () => !!(link.childLinks && link.childLinks.length);

  const dropdownArrow = () => (dropdownOpen === link.name) ? <IconChevronUp strokeWidth={1}/> : <IconChevronDown strokeWidth={1}/>;

  const handleLinkClick = (e: SyntheticEvent, link: NavLinkType) => {
    if (link.childLinks && link.childLinks.length) {
      dispatch(toggleOpenDropdown(link.name));
    } else {
      navigate(link.path);
    }
  };

  return (
    <Fragment>
      <ListItem
        data-testid={"list-item-dropdown"}
        className={clsx(classes.listItem,
          (window.location.pathname === link.path || window.location.pathname === undefined) && classes.selectedLink
        )}
        onClick={(e: SyntheticEvent) => handleLinkClick(e, link)}
        button
      >
        <ListItemIcon className={classes.listIcon} style={{ marginLeft: "20px" }}>{link.icon}</ListItemIcon>
        <ListItemText><Typography variant={"body2"}>{link.name}</Typography></ListItemText>
        <ListItemIcon className={classes.arrowIcon}>
          {(childLinks()) ? dropdownArrow() : null}
        </ListItemIcon>
      </ListItem>

      {link.childLinks && link.childLinks.length && (
        <Collapse
          in={link.name === dropdownOpen}
          timeout={"auto"}
          unmountOnExit
        >
          {link.childLinks.map((childLink) => {
            return (
              <List
                key={childLink.name}
                component={"nav"}
                aria-label={`${childLink.name} link`}
                disablePadding
              >
                <ListItem
                  data-testid={"dropdown-link-item"}
                  key={childLink.path + "-child"}
                  className={clsx(
                    classes.listItem, classes.dropdownLink,
                    window.location.pathname === childLink.path && classes.selectedLink
                  )}
                  onClick={() => navigate(childLink.path)}
                  button
                >
                  <ListItemText><Typography variant={"body2"}>{childLink.name}</Typography></ListItemText>
                </ListItem>
              </List>
            );
          })}
        </Collapse>
      )}
    </Fragment>
  );
};

export default NavLink;
