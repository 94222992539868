import * as React from "react";

import { ThemeOptions, createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import type {} from "@mui/x-data-grid/themeAugmentation";
const palettePrimaryLight = "#3d526f";
const colorWhite = "#FFFFFF";

const primary900 = "#223248";
const primary800 = "#354760";
// const primary700 = "#445A76";
// const primary600 = "#556D8E";
// const primary500 = "#637C9F";
// const primary400 = "#798FB0";
// const primary300 = "#90A3C2";
// const primary200 = "#ADBED8";
const primary175 = "#BECDE2";
const primary150 = "#B9C7DC";
const primary100 = "#C8D9EF";
const primary50 = "#F6F8FC";

const secondary900 = "#1A2233";
const secondary800 = "#2C354A";
// const secondary700 = "#3C4760";
// const secondary600 = "#4C5976";
const secondary500 = "#5A6786";
// const secondary400 = "#717C99";
// const secondary300 = "#8893AD";
// const secondary200 = "#A9B1C7";
// const secondary100 = "#C8D0E2";
const secondary75 = "#E9EBFA";
const secondary50 = "#EFF4FB";
const secondary10 = "#e1edf5";

// const blue700Dark = "#13599E";
// const blue600Dark = "#1667B8";
const blue500Base = "#1976D2";
// const blue400Light = "#1C84EB";
// const blue300Light = "#1E8FFF";

// const gray700Dark = "#999999";
const gray600Dark = "#B3B3B3";
const gray500Base = "#CCCCCC";
const gray450Base = "#9E9E9E";
const gray400Light = "#E0E0E0";
const gray300Light = "#F2F2F2";
const gray100Light = "#0000001f";
// const green700Dark = "#50992E";
// const green600Dark = "#5DB235";
const green500Base = "#70D640";
// const green400Light = "#7FD656";
// const green300Light = "#9CD680";

// const red700Dark = "#993131";
// const red600Dark = "#CC4242";
const red500Base = "#FF5252";
// const red400Light = "#FF6B6B";
// const red300Light = "#FF8585";

// const yellow700Dark = "#CC8E33";
const yellow600Dark = "#E5A03A";
// const yellow500Base = "#FFB240";
const yellow400Light = "#FFBC59";
// const yellow300Light = "#FFC673";

declare module "@mui/material/styles" {
  interface Theme {
      logoBackground: string;
      buttonColor: string;
      selectedNavigationItem: string;
      selectedNavigationItemHover: string;
      sideNavigationBackground: string;
      sideNavigationBorder: string;
      checkCircle: string;
      blueGreyColor: string;
      clickIconColor: string;
      graySecondary50: string;
      gray600Dark: string;
      gray400Light: string;
      gray300Light: string;
      gray500Base: string;
      graySecondary75: string;
      yellow400Light: string;
      navLinkTooltip: string;
      gray450Base: string;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
      logoBackground: string;
      buttonColor: string;
      selectedNavigationItem: string;
      selectedNavigationItemHover: string;
      sideNavigationBackground: string;
      sideNavigationBorder: string;
      checkCircle: string;
      blueGreyColor: string;
      clickIconColor: string;
      graySecondary50: string;
      gray600Dark: string;
      gray400Light: string;
      gray300Light: string;
      gray500Base: string;
      graySecondary75: string;
      yellow400Light: string;
      navLinkTooltip: string;
      gray450Base: string;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    h7: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  body3: React.CSSProperties;
  body4: React.CSSProperties;
  h7: React.CSSProperties;
}

const theme = createTheme({

  logoBackground: primary100,
  buttonColor: primary50,
  sideNavigationBackground: `${primary100}66`,
  sideNavigationBorder: gray100Light,
  selectedNavigationItem: primary150,
  selectedNavigationItemHover: primary175,
  checkCircle: green500Base,
  blueGreyColor: secondary10,
  clickIconColor: secondary800,
  graySecondary50: secondary50,
  gray600Dark,
  gray400Light,
  gray300Light,
  gray500Base,
  graySecondary75: secondary75,
  yellow400Light,
  navLinkTooltip: secondary500,
  gray450Base,
  palette: {
    primary: {
      light: palettePrimaryLight,
      main: primary800,
      dark: primary900,
      contrastText: blue500Base // <--- this is the button color.
    },
    secondary: {
      light: secondary800,
      main: secondary900,
      contrastText: primary800 // <--- this is the button color.
    },
    success: {
      main: green500Base,
      contrastText: green500Base // <--- this is the button color.
    },
    error: {
      main: red500Base,
      contrastText: red500Base // <--- this is the button color.
    },
    warning: {
      main: yellow600Dark,
      contrastText: yellow600Dark // <--- this is the button color.
    },
    background: {
      default: primary50,
      paper: colorWhite
    },
    text: {
      primary: primary800
    },
    common: {
      white: colorWhite
    }
  },
  typography: {
    h1: {
      fontFamily: "'Source Sans 3'",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "40.22px",
      letterSpacing: "-1.5px",
      color: primary800
    },
    h2: {
      fontFamily: "'Source Sans 3'",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "37.71px",
      letterSpacing: "-0.5px",
      color: primary800
    },
    h3: {
      fontFamily: "'Source Sans 3'",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "28px",
      lineHeight: "56px",
      color: primary800
    },
    h4: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "26px",
      lineHeight: "36px",
      color: primary800
    },
    h5: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "24px",
      letterSpacing: "0.18px",
      color: primary800
    },
    h6: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: primary800
    },
    h7: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "20px",
      letterSpacing: "0.2px",
      color: primary800
    },
    body1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: primary800
    },
    body2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16.41px",
      letterSpacing: "0.2px",
      color: primary800
    },
    body3: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: primary800
    },
    body4: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "12px",
      letterSpacing: "1px",
      color: primary800
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: primary800
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: primary800
    },
    button: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "16px",
      letterSpacing: "1.25px",
      color: primary800
    },
    caption: {
      fontFamily: "Roboto",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "16px",
      letterSpacing: "1.5px",
      color: primary800
    },
    overline: {
      fontFamily: "Roboto",
      fontSize: "10px",
      fontWeight: 500,
      lineHeight: "16px",
      letterSpacing: "1.5px",
      color: primary800
    }
  } as ExtendedTypographyOptions,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "45px",
          input: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.5px",
            color: primary800,
            "&:-webkit-autofill": {
              WebkitBoxShadow: `0 0 0 100px ${colorWhite} inset`,
              height: 0,
              padding: "13px"
            },
            "&::placeholder": {
              fontFamily: "Roboto",
              fontStyle: "Italic",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0.5px"
            }
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "unset"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          color: "red"
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          minWidth: "800px",
          "& .MuiDataGrid-cell": {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "12px",
            letterSpacing: "1px",
            color: primary800
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "12px",
            letterSpacing: "1px",
            color: primary800
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none"
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none"
          }
        }
      }
    }
  }

} as ThemeOptions);

export default theme;
