import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { selectIsAuthenticated } from "src/store/reducers/auth/authSlice";
const useAutoLogout = (startTime: number) => {
  const [timer, setTimer] = useState(startTime);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0 && isAuthenticated) {
        setTimer(timer - 1);
      }
    }, 1000);
    const resetTimeout = () => {
      if (timer > 60) {
        setTimer(startTime);
      }
    };
    const events = [
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
    for (const i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    return () => {
      clearInterval(myInterval);
      for (const i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });
  return {
    timer,
    setTimer
  };
};

export default useAutoLogout;
