import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserAssignments } from "src/models/UserAssignments";
import { RootState } from "src/store/rootStore";
import { getUser } from "src/store/thunks/user/get/getUser";
import { updateUser } from "src/store/thunks/user/update/updateUser";

import { EditUserState } from "./editUserSliceState";

export const initialState: EditUserState = {
  user: {
    id: 1,
    operator_id: 1,
    first_name: "",
    last_name: "",
    username: "",
    phone: "",
    email: "",
    title: "",
    is_active: 1,
    finished_registration: 1,
    has_to_do_registration: 0,
    cancelled_registration: 0,
    email_verified_at: "",
    created_at: "",
    updated_at: "",
    assignments: []
  },
  summaryTab1: true,
  summaryTab2: false,
  summaryTab3: false,
  error: "",
  loading: false,
  selectedUserId: 0,
  userLoaded: false
};

export const editUser = createSlice({
  name: "editUser",
  initialState,
  reducers: {
    editNewUserSlice: (state: EditUserState) => {
      Object.assign(state, initialState);
    },
    firstNameOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.first_name = action.payload;
    },
    lastNameOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.last_name = action.payload;
    },
    usernameOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.username = action.payload;
    },
    emailOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.email = action.payload;
    },
    phoneOnchange: (state, action: PayloadAction<string>) => {
      state!.user!.phone = action.payload;
    },
    isActive: (state, action: PayloadAction<number>) => {
      state!.user!.is_active = action.payload;
    },
    setUserAssignments: (state, action: PayloadAction<Array<UserAssignments>>) => {
      state!.user!.assignments = action.payload;
    },
    setSummaryTab1: (state, action: PayloadAction<boolean>) => {
      state.summaryTab1 = action.payload;
    },
    setSummaryTab2: (state, action: PayloadAction<boolean>) => {
      state.summaryTab2 = action.payload;
    },
    setSummaryTab3: (state, action: PayloadAction<boolean>) => {
      state.summaryTab3 = action.payload;
    },
    setSelectedUserId: (state, action: PayloadAction<number>) => {
      state.selectedUserId = action.payload;
    },
    setUserLoaded: (state, action: PayloadAction<boolean>) => {
      state.userLoaded = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectLoading = (state: RootState) => state.editUser?.loading;
export const selectSummaryTab1 = (state: RootState) => state.editUser.summaryTab1;
export const selectSummaryTab2 = (state: RootState) => state.editUser.summaryTab2;
export const selectSummaryTab3 = (state: RootState) => state.editUser.summaryTab3;
export const selectSelectedUserId = (state: RootState) => state.editUser.selectedUserId;
export const selectUserLoaded = (state: RootState) => state.editUser.userLoaded;
export const selectUserFirstName = (state: RootState) => state.editUser?.user?.first_name ?? "";
export const selectUserLastName = (state: RootState) => state.editUser?.user?.last_name ?? "";
export const selectUserUsername = (state: RootState) => state.editUser?.user?.username ?? "";
export const selectUserIsActive = (state: RootState) => state.editUser?.user?.is_active ?? 0;
export const selectUserEmail = (state: RootState) => state.editUser?.user?.email ?? "";
export const selectUserPhone = (state: RootState) => state.editUser?.user?.phone ?? "";
export const selectUserAssignments = (state: RootState) => state.editUser?.user?.assignments ?? [];
export const selectUser = (state: RootState) => state.editUser?.user ?? undefined;

export const {
  editNewUserSlice,
  firstNameOnchange,
  lastNameOnchange,
  usernameOnchange,
  emailOnchange,
  phoneOnchange,
  isActive,
  setUserAssignments,
  setSummaryTab1,
  setSummaryTab2,
  setSummaryTab3,
  setSelectedUserId,
  setUserLoaded,
  setLoading
} = editUser.actions;

export default editUser.reducer;
