import React, { ReactElement } from "react";
interface CustomImageProps {
  imageName: string;
  imageAlt: string;
  className: string;
}
const CustomImage: React.FC<CustomImageProps> = ({ imageName, imageAlt, className }): ReactElement => {
  let imagePath = "";
  try {
    imagePath = require(`../../../assets/app_icons/${imageName}.svg`);
  } catch (err) {
    imagePath = require("../../../assets/app_icons/default-icon.svg").default;
  }
  return <img src={imagePath} alt={imageAlt} className={className} />;
};

export default CustomImage;
