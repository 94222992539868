import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Role } from "src/models/Role";
import { APIListMetaData } from "src/models/responses/ListMetaData";
import { APIListResponse } from "src/models/responses/ListResponse";
import { RootState } from "src/store/rootStore";
import { getCustomRoles } from "src/store/thunks/roles/getCustomRoles";

import { CustomRolesSliceState } from "./CustomRolesSliceState";

export const initialState: CustomRolesSliceState = {
  loading: false,
  error: "",
  customRoles: {
    data: [],
    meta: {} as APIListMetaData,
    links: {
      first: null,
      last: null,
      prev: null,
      next: null
    }
  }
};

export const customRolesSlice = createSlice({
  name: "customRoles",
  initialState,
  reducers: {
    setDefaultRoles: (state, action: PayloadAction<Role[]>) => {
      state.customRoles.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomRoles.fulfilled, (state, action: PayloadAction<APIListResponse<Role[]>>) => {
        state.customRoles = {
          data: [],
          meta: {} as APIListMetaData,
          links: {
            first: null,
            last: null,
            prev: null,
            next: null
          }
        };
        state.customRoles.data = action.payload.data;
        state.customRoles.meta = action.payload.meta;
        state.loading = false;
      })
      .addCase(getCustomRoles.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  }
});

export const selectCustomRoles = (state: RootState) => state.customRoles?.customRoles;
export const selectLoading = (state: RootState) => state.customRoles?.loading;

export const {
  setDefaultRoles
} = customRolesSlice.actions;

export default customRolesSlice.reducer;
